import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useMoralis } from 'react-moralis';
import NavItems from '../Header/NavItems';

let host = window.location.hostname;
if ( window.location.port !== "" ) {
    host+= ':' + window.location.port;
}

const BASE_URL = `${window.location.protocol}//${host}/footer.json`;

const ModalMenu = () => {
    const { isAuthenticated, user, logout } = useMoralis();
    const [socialData, setSocialData] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const loadSocialdata = async () => {
            axios.get(`${BASE_URL}`)
                .then(res => {
                    setSocialData(res.data.socialData);
                });
        };

        loadSocialdata();
    }, []);

    useEffect(() => {
        if(isAuthenticated && user && user.get('is_admin')) {
            setIsAdmin(true);
        }
    }, [isAuthenticated]);

    return (
        <div id="menu" className="modal fade p-0">
            <div className="modal-dialog dialog-animated">
                <div className="modal-content h-100">
                    <div className="modal-header" data-dismiss="modal">
                        Menu 
                        
                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" className="icon-close">
                            <g id="cross">
                                <line x1="7" x2="25" y1="7" y2="25" fill="none" stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px" />
                                <line x1="7" x2="25" y1="25" y2="7" fill="none" stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px" />
                            </g>
                        </svg>
                    </div>
                    <div className="menu modal-body">
                        <div className="row w-100">
                            <div className="items p-0 col-12 text-center">
                                <NavItems isAuthenticated={isAuthenticated} isAdmin={isAdmin} logout={logout} />
                            </div>

                            <div className="social-icons-wrapper">
                                <span>Follow Us</span>
                                <div className="social-icons d-flex">
                                    {socialData.map((item, idx) => {
                                        return (
                                            <a key={`sd_${idx}`} className={item.link} href={item.url} target="_blank" rel="noreferrer">
                                                <i className={item.icon} />
                                                <i className={item.icon} />
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalMenu;