import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const ArtistCardBtn = styled(Link)`
display: block;
font-family: var(--secondary-font);
font-size: 12px !important;
font-style: normal;
font-weight: 500 !important;
line-height: 15px !important;
letter-spacing: 0;
text-align: center;
color: #6C6969 !important;

@media (min-width: 768px) {
    padding: 12px 40px;
    color: var(--black-color) !important;
    background-color: var(--white-color);
    border: 1px solid var(--black-color);
    border-radius: 100px;
    margin-top: 16px;
    cursor: pointer;
    transition: background-color ease 0.3s, color ease 0.3s;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 17px !important;

    &:hover {
        background-color: var(--black-color);
        color: var(--white-color) !important;
    }
}
`

export const ArtistCardName = styled(Link)`
font-family: var(--primary-font);
font-weight: var(--normal);
color: var(--black-color) !important;
font-size: 14px !important;
line-height: 17px !important;
text-align: center;
margin-top: 16px;
display: inline-block;
max-width: 100%;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
text-decoration: none;

@media (min-width: 768px) {
    font-size: 20px !important;
    line-height: 27px !important;
}
`

export const ArtistCardAvatar = styled.div`
width: 64px;
height: 64px;
border: 3px solid var(--white-color);
border-radius: 50%;
background-repeat: no-repeat;
background-position: center center;
background-size: cover;
position: relative;
margin: 0 auto;

@media (min-width: 768px) {
    width: 80px;
    height: 80px;
}
`

export const ArtistCardDetails = styled.div`
position: relative;
text-align: center;
padding: 24px 14px;

@media (min-width: 768px) {
    margin-top: -40px;
    padding-top: 0;
}
`

export const ArtistCardBanner = styled(Link)`
width: 100%;
height: 180px;
background-repeat: no-repeat;
background-size: cover;
background-position: center center;
display: none;

@media (min-width: 768px) {
    display: block;
}
`

export const ArtistCard = styled.div`
position: relative;
display: -ms-flexbox;
display: flex;
-ms-flex-direction: column;
flex-direction: column;
min-width: 0;
word-wrap: break-word;
background-clip: border-box;
border-radius: 0.25rem;
background-color: var(--white-color);
transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
transition-duration: 0.3s;
transition-property: opacity, transform;
overflow: hidden;
box-shadow: 0px 0px 2px rgb(0 0 0 / 15%), 0px 2px 2px rgb(0 0 0 / 5%);

&.mb-banner-show {
    @media (max-width: 767px) {
        ${ArtistCardBanner} {
            display: block;
        }

        ${ArtistCardDetails} {
            margin-top: -40px;
            padding-top: 0;
        }

        ${ArtistCardAvatar} {
            width: 80px;
            height: 80px;
        }

        ${ArtistCardName} {
            font-size: 20px !important;
            line-height: 27px !important;
        }

        ${ArtistCardBtn} {
            display: inline-block;
            padding: 12px 40px;
            color: var(--black-color) !important;
            background-color: var(--white-color);
            border: 1px solid var(--black-color);
            border-radius: 100px;
            margin-top: 16px;
            cursor: pointer;
            transition: background-color ease 0.3s, color ease 0.3s;
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 17px !important;
        }
    }
}
`