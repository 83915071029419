import ContentLoader from "react-content-loader"

const TitleLeftLoader = (props) => {
    return (
        <ContentLoader 
            speed={2}
            width={210}
            height={80}
            viewBox="0 0 210 80"
            backgroundColor="#d4d4d4"
            foregroundColor="#b0b0b0" 
            style={{maxWidth: '100%', height: 'auto'}}
            {...props}
        >
            <rect x="0" y="0" rx="4" ry="4" width="135" height="10" />
            <rect x="155" y="4" rx="4" ry="4" width="45" height="2" />
            <rect x="0" y="45" rx="4" ry="4" width="170" height="35" />
        </ContentLoader>
    )
};

export default TitleLeftLoader;