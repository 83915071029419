import ReactHtmlParser from 'react-html-parser'; 

/** @see https://www.gbmb.org/mb-to-bytes */
export const maxAvatarSize = 10485760;
export const maxBannerSize = 10485760;
export const avatarPlaceholder = "/img/default_avatar.jpg";
export const bannerPlaceholder = "/img/default_banner.jpg";

/**
 * min and max included
 * 
 * @param {Number} min 
 * @param {Number} max 
 * 
 * @returns {Number}
 */
const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export const getAvatarPlaceholder = () => {
    return `/img/avatars/avatar${randomIntFromInterval(1, 5)}.jpg`;
};

export const bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

export const isImage = (mineType) => {
    return ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(mineType);
};

export const isVideo = (mineType) => {
    return ['video/mp4', 'video/webm'].includes(mineType);
};

export const isAudio = (fileName) => {
    return fileName.endsWith('.mp3');
};

export const previewFile = (file, alt, defaultReturn = '') => {
    if(file instanceof FileList && file.length > 0) {
        if(isImage(file[0].type)) {
            const img = URL.createObjectURL(file[0]);
            return <img src={img} alt={alt} />;
        }

        if(isVideo(file[0].type)) {
            const video = URL.createObjectURL(file[0]);
            return (
                <video loop={true} playsInline={true} autoPlay={true} muted={true}>
                    <source src={video} type={file[0].type} />
                    Your browser does not support the video tag.
                </video>
            );
        }
    }

    if(defaultReturn !== '') {
        return ReactHtmlParser(defaultReturn)
    }

    return (defaultReturn);
}