import ContentLoader from "react-content-loader"
import getWindowDimensions  from '../../../../helpers/getWindowDimensions'

const AuthorInfoLoader = (props) => {
    const { width } = getWindowDimensions();

    if(width <= 500) {
        return (
            <ContentLoader 
                speed={2}
                width={467}
                height={245}
                viewBox="0 0 467 245"
                backgroundColor="#d4d4d4"
                foregroundColor="#b0b0b0" 
                style={{maxWidth: '100%', height: 'auto'}}
                {...props}
            >
                <rect x="165" y="8" rx="3" ry="3" width="40" height="13" /> 
                <rect x="165" y="36" rx="3" ry="3" width="162" height="26" />
                <rect x="165" y="87" rx="3" ry="3" width="30" height="30" />
                <rect x="205" y="87" rx="3" ry="3" width="30" height="30" />
                <rect x="245" y="87" rx="3" ry="3" width="30" height="30" />
                <rect x="285" y="87" rx="3" ry="3" width="30" height="30" />
                <rect x="0" y="157" rx="3" ry="3" width="467" height="80" /> 
                <circle cx="65" cy="65" r="65" />
            </ContentLoader>
        )
    }

    if(width < 992) {
        return (
            <ContentLoader 
                speed={2}
                width={800}
                height={227}
                viewBox="0 0 800 227"
                backgroundColor="#d4d4d4"
                foregroundColor="#b0b0b0" 
                style={{maxWidth: '100%', height: 'auto'}}
                {...props}
            >
                <rect x="216" y="8" rx="3" ry="3" width="40" height="13" /> 
                <rect x="216" y="36" rx="3" ry="3" width="162" height="26" />
                <rect x="216" y="87" rx="3" ry="3" width="496" height="80" /> 
                <rect x="216" y="197" rx="3" ry="3" width="30" height="30" />
                <rect x="256" y="197" rx="3" ry="3" width="30" height="30" />
                <rect x="296" y="197" rx="3" ry="3" width="30" height="30" />
                <rect x="336" y="197" rx="3" ry="3" width="30" height="30" />
                <circle cx="98" cy="98" r="98" />
            </ContentLoader>
        )
    }

    return (
        <ContentLoader 
            speed={2}
            width={800}
            height={227}
            viewBox="0 0 800 227"
            backgroundColor="#d4d4d4"
            foregroundColor="#b0b0b0" 
            style={{maxWidth: '100%', height: 'auto'}}
            {...props}
        >
            <rect x="304" y="8" rx="3" ry="3" width="40" height="13" /> 
            <rect x="304" y="36" rx="3" ry="3" width="162" height="26" />
            <rect x="304" y="87" rx="3" ry="3" width="496" height="80" /> 
            <rect x="304" y="197" rx="3" ry="3" width="30" height="30" />
            <rect x="344" y="197" rx="3" ry="3" width="30" height="30" />
            <rect x="384" y="197" rx="3" ry="3" width="30" height="30" />
            <rect x="424" y="197" rx="3" ry="3" width="30" height="30" />
            <circle cx="102" cy="102" r="102" />
        </ContentLoader>
    )
};

export default AuthorInfoLoader;