import React from "react"
import { createContext, useContext, useState } from 'react'
import { defaultCurrency, getCurrency } from "../helpers/currencies";

export const CurrencyContext = createContext({})

export const CurrencyProvider = ({ children }) => {
    const [selectedCurrency, setCurrency] = useState(getCurrency(defaultCurrency));

    const changeCurrency = (code) => {
        if(!code) {
            code = defaultCurrency;
        }

        let currency = getCurrency(code);
        if(currency === null) {
            currency = getCurrency(defaultCurrency);
        }
        
        setCurrency(currency);
    }

    return (
        <CurrencyContext.Provider value={{selectedCurrency, changeCurrency}}>
            {children}
        </CurrencyContext.Provider>
    )
}

export const useCurrency = () => useContext(CurrencyContext)
