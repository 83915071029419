import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

// importing all the themes
import ThemeOne from "../themes/theme-one";
import ExploreTwo from "../themes/explore-two";
import ItemDetailsNew from "../themes/item-details-new";
import Artists from "../themes/artists";
import Artist from "../themes/artist";
import User from "../themes/user";
import Balance from "../themes/balance";
import WalletConnect from "../themes/wallet-connect";
/* import CreateNotArtist from "../themes/create-not-artist"; */
import Create from "../themes/create";
/* import Contact from "../themes/contact"; */
import ProfileEdit from "../themes/profile-edit";
import Profile from "../themes/profile";
import PrivacyAndTerms from "../themes/privacy-and-terms";
import TermsAndConditions from "../themes/terms-and-conditions";
/* import Partners from "../themes/partners"; */
import HelpCenter from "../themes/help-center";
import HelpCenterGettingStarted from "../themes/help-center-getting-started";
//import HelpCenterCreating from "../themes/help-center-creating";
import HelpCenterBuying from "../themes/help-center-buying";
import HelpCenterSelling from "../themes/help-center-selling";
/* import Suggestions from "../themes/suggestions"; */
import HowItWorks from "../themes/how-it-works";

/** Admin Templates */
import AdminIndex from "../themes/Admin/Index";
import AdminCategoriesIndex from "../themes/Admin/Categories/Index";
import AdminArtistsIndex from "../themes/Admin/Artists/Index";
import AdminArtistsForm from "../themes/Admin/Artists/Form";

import SetupTheme from "../themes/setup";
/** End Admin Templates */

import NoRoute from "../themes/noroute";
import ScrollToTop from "../components/Scrollup/ScrollToTop";

function MyRouts({isAuthenticated, isArtist, canCreate, isAdmin}) {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={ThemeOne} />
        <Route exact path="/explore" component={ExploreTwo} />
        <Route exact path="/item/:token_address/:token_id" component={ItemDetailsNew} />
        <Route exact path="/item/:id" component={ItemDetailsNew} />
        <Route exact path="/artists" component={Artists} />
        <Route exact path="/artists/:slug" component={Artist} />
        <Route exact path="/user/:slug" component={User} />
        <Route exact path="/wallet-connect">
          { isAuthenticated ? <Redirect to="/profile/edit" /> : <WalletConnect /> }
        </Route>
        <Route exact path="/create">
          {
            !isAuthenticated 
            ? <NoRoute />  //<Redirect to={`/wallet-connect?ref=/create`} /> 
            : ( !isArtist ? <NoRoute /> : <Create /> )
          }
        </Route>
        <Route exact path="/profile/edit">
          { !isAuthenticated ? <Redirect to={`/wallet-connect?ref=/profile/edit`} /> : <ProfileEdit /> }
        </Route>
        <Route exact path="/profile">
          { !isAuthenticated ? <Redirect to={`/wallet-connect?ref=/profile`} /> : <Profile /> }
        </Route>
        <Route exact path="/profile/balance">
          { !isAuthenticated ? <Redirect to={`/wallet-connect?ref=/profile/balance`} /> : <Balance /> }
        </Route>
        {/* <Route exact path="/contact" component={Contact} /> */}
        {/* <Route exact path="/suggestions" component={Suggestions} /> */}
        <Route exact path="/privacy-and-terms" component={PrivacyAndTerms} />
        <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
        {/* <Route exact path="/partners" component={Partners} /> */}
        <Route exact path="/help-center" component={HelpCenter} />
        <Route exact path="/help-center/getting-started" component={HelpCenterGettingStarted} />
        {/* <Route exact path="/help-center/creating" component={HelpCenterCreating} /> */}
        <Route exact path="/help-center/buying" component={HelpCenterBuying} />
        <Route exact path="/help-center/selling" component={HelpCenterSelling} />
        <Route exact path="/how-it-works" component={HowItWorks} />

        <Route exact path="/admin">
          { !isAuthenticated || !isAdmin ? <NoRoute /> : <AdminIndex /> }
        </Route>
        <Route exact path="/admin/categories">
          { !isAuthenticated || !isAdmin ? <NoRoute /> : <AdminCategoriesIndex /> }
        </Route>
        <Route exact path="/admin/artists">
          { !isAuthenticated || !isAdmin ? <NoRoute /> : <AdminArtistsIndex /> }
        </Route>
        <Route exact path="/admin/artists/new">
          { !isAuthenticated || !isAdmin ? <NoRoute /> : <AdminArtistsForm /> }
        </Route>
        <Route exact path="/admin/artists/edit/:id">
          { !isAuthenticated || !isAdmin ? <NoRoute /> : <AdminArtistsForm /> }
        </Route>
        <Route exact path="/setup" >
          { !isAuthenticated || !isAdmin ? <NoRoute /> : <SetupTheme /> }
        </Route>

        <Route path="*">
          <NoRoute />
        </Route>
      </Switch>
    </Router>
  );
}
export default MyRouts;