import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from "react-hook-form";
import { useMoralis } from "react-moralis";
import { avatarPlaceholder, bytesToSize, maxAvatarSize, maxBannerSize, previewFile } from '../../helpers/fileUpload';
import { emailPattern, urlPattern, validateFileSize, validateFileType } from '../../helpers/validation';
import ReactTooltip from 'react-tooltip';
import urlSlug from 'url-slug'
import ProcessingModal from '../Loaders/ProcessingModal/ProcessingModal';
import { ContentLoader } from '../Elements/styles';

function AvatarWatched({ control, defaultValue }) {
    const avatar = useWatch({
        control, 
        name: "avatar", 
        defaultValue: defaultValue
    });

    return previewFile(avatar, 'Profile Avatar', `<img src=${defaultValue} alt="Profile Avatar" />`);
}

function BannerWatched({ control, defaultValue }) {
    const banner = useWatch({
        control, 
        name: "banner", 
        defaultValue: defaultValue
    });

    let defaultBlock = undefined;
    if(defaultValue) {
        defaultBlock = `<img src=${defaultValue} alt="Profile Banner" />`
    }
    
    return previewFile(banner, 'Profile Banner', defaultBlock);
}

const ProfileEdit = () => {
    document.title = 'Edit Profile | Block of Change';

    const { Moralis } = useMoralis();
    const { register, handleSubmit, watch, control, reset, formState: { errors } } = useForm({
        mode: "onBlur", 
        defaultValues: {
            username: '', 
            email: '', 
            bio: '', 
            twitterhandler: '', 
            instagramhandler: '', 
            facebookurl: '', 
            websiteurl: ''
        }
    });
    const [ loading, setLoading ] = useState(true);
    const [ formError, setFormError ] = useState(false);
    const [ formErrorMsg, setFormErrorMsg ] = useState('Unknown error, please try again later');
    const [ formSaving, setFormSaving ] = useState(false);
    const [ formSuccess, setFormSuccess ] = useState(false);
    const [ defaultAvatar, setDefaultAvatar ] = useState(avatarPlaceholder);
    const [ defaultBanner, setDefaultBanner ] = useState("");
    const avatarWatch = watch("avatar", {length: 0});
    const bannerWatch = watch("banner", {length: 0});

    useEffect(()=> {
        if (Moralis.User.current()) {
            const user = Moralis.User.current();
            const data = {
                username: '', 
                email: '', 
                bio: '', 
                twitterhandler: '', 
                instagramhandler: '', 
                facebookurl: '', 
                websiteurl: ''
            };

            data.username = user.get('username');

            const userEmail = user.get('email');
            if(userEmail) {
                data.email = userEmail;
            }

            try {
                const userAvatar = user.get('avatar').url();
                if(userAvatar) {
                    setDefaultAvatar(userAvatar);
                }
            } catch(error) {
                console.log(error);
            }

            try {
                const userBanner = user.get('banner').url();
                if(userBanner) {
                    setDefaultBanner(userBanner);
                }
            } catch(error) {
                console.log(error);
            }

            const userBio = user.get('bio');
            if(userBio) {
                data.bio = userBio;
            }

            const userTwitterHandler = user.get('twitterhandler');
            if(userTwitterHandler) {
                data.twitterhandler = userTwitterHandler;
            }

            const userInstagramHandler = user.get('instagramhandler');
            if(userInstagramHandler) {
                data.instagramhandler = userInstagramHandler;
            }

            const userFacebookURL = user.get('facebookurl');
            if(userFacebookURL) {
                data.facebookurl = userFacebookURL;
            }

            const userWebsiteURL = user.get('websiteurl');
            if(userWebsiteURL) {
                data.websiteurl = userWebsiteURL;
            }

            reset(data);
            setLoading(false);
        }
    }, [])

    const onSubmit = async(data) => {
        setFormError(false);
        setFormErrorMsg('Unknown error, please try again later');
        setFormSuccess(false);
        setFormSaving(true);

        const user = Moralis.User.current();

        user.set('username', data.username);
        user.set('slug', urlSlug(data.username));
        user.set('email', data.email);
        user.set('bio', data.bio);
        user.set('twitterhandler', data.twitterhandler);
        user.set('instagramhandler', data.instagramhandler);
        user.set('facebookurl', data.facebookurl);
        user.set('websiteurl', data.websiteurl);
        
        if(data.avatar.length > 0) {
            const avatar = new Moralis.File("avatar.jpg", data.avatar[0]);
            const avatarFile = await avatar.save();
            user.set('avatar', avatarFile);
        }
        
        if(data.banner.length > 0) {
            const banner = new Moralis.File("banner.jpg", data.banner[0]);
            const bannerFile = await banner.save();
            user.set('banner', bannerFile);
        }
        
        try {
            await user.save();
            setFormSaving(false); 
            setFormSuccess(true);

            setTimeout(() => {
                setFormSuccess(false);
            }, 2000);
        } catch(error) {
            setFormSaving(false); 
            setFormError(true);
            setFormErrorMsg(error.message);
        }
    }

    return (
        <>
            { formSaving && <ProcessingModal /> }

            <section className="author-area profile-area">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-4">
                        <div className="intro text-center mb-4">
                            <h3 className="mt-3 mb-0">Edit Profile</h3>
                            <p>You can set your unique username, create an identity and manage other personal settings here.</p>
                        </div>
                    </div>
                </div>

                {formError && (
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 col-xl-5 mt-3 mt-sm-4">
                            <div class="alert alert-danger text-center" role="alert">
                                {formErrorMsg}
                            </div>
                        </div>
                    </div>
                )}

                { loading ? <ContentLoader /> : (
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 col-xl-5">
                            <form className="item-form card no-hover row" onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <label className="form-label" htmlFor="username">Username</label>
                                            <input type="text" className={errors.username ? "form-control is-invalid" : "form-control"} name="username" id="username" placeholder="Username" maxLength={256} {...register("username", { required: true })} />
                                            { errors.username && <div className="invalid-feedback">Required Field.</div> }
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <label className="form-label" htmlFor="email">Email</label>
                                            <input type="email" className={errors.email ? "form-control is-invalid" : "form-control"} name="email" id="email" placeholder="Email" maxLength={256} {...register("email", { required: true, pattern: emailPattern })} />
                                            {errors.email && (
                                                <div className="invalid-feedback">
                                                    { errors.email?.type === "required" && <div>Required Field.</div> }
                                                    { errors.email?.type !== "required" && <div>Please enter a valid email.</div> }
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <label className="form-label" htmlFor="bio">Bio</label>
                                            <textarea className="form-control" maxLength={4000} name="bio" id="bio" rows={7} placeholder="Tell something about you" {...register("bio")} />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label mhauto">Avatar <span data-tip data-for="avatarTooltip"><i className="fas fa-info"></i></span>
                                                <ReactTooltip id="avatarTooltip">
                                                    <div>Recomended 310x310px</div>
                                                    <div>Max size: {bytesToSize(maxAvatarSize)}</div>
                                                </ReactTooltip>
                                            </label>
                                            <div className={`fileUploadPreviewWrapper${defaultAvatar === avatarPlaceholder && (!avatarWatch || avatarWatch.length === 0) ? ' empty' : ''}`}>
                                                <div className="fileUploadPreview">
                                                    <AvatarWatched control={control} defaultValue={defaultAvatar} />
                                                </div>
                                                <div className="fileUploadPreviewInput">
                                                    <input type="file" name="avatar" id="avatarFile" accept=".jpg, .jpeg, .png, .gif" {...register("avatar", {
                                                        fileSize: files => validateFileSize(files[0]?.size, maxAvatarSize) || 'Max ' + bytesToSize(maxAvatarSize),
                                                        acceptedFormats: files =>
                                                        validateFileType(files[0]?.type, ['image/jpeg', 'image/png', 'image/gif']) || 'Only PNG, JPEG e GIF'
                                                    })} style={{ display: "none" }} />
                                                    <label htmlFor="avatarFile" className="fileUploadPreviewOverlay">
                                                        <div className="fileUploadPreviewOverlayInner">
                                                            Upload file
                                                            <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M32 2.75C32.5967 2.75 33.169 2.98705 33.591 3.40901C34.0129 3.83097 34.25 4.40326 34.25 5V32C34.25 32.5967 34.0129 33.169 33.591 33.591C33.169 34.0129 32.5967 34.25 32 34.25H5C4.40326 34.25 3.83097 34.0129 3.40901 33.591C2.98705 33.169 2.75 32.5967 2.75 32V5C2.75 4.40326 2.98705 3.83097 3.40901 3.40901C3.83097 2.98705 4.40326 2.75 5 2.75H32ZM5 0.5C3.80653 0.5 2.66193 0.974106 1.81802 1.81802C0.974106 2.66193 0.5 3.80653 0.5 5L0.5 32C0.5 33.1935 0.974106 34.3381 1.81802 35.182C2.66193 36.0259 3.80653 36.5 5 36.5H32C33.1935 36.5 34.3381 36.0259 35.182 35.182C36.0259 34.3381 36.5 33.1935 36.5 32V5C36.5 3.80653 36.0259 2.66193 35.182 1.81802C34.3381 0.974106 33.1935 0.5 32 0.5L5 0.5Z" fill="#D8D8D8"/>
                                                                <path d="M18.5 9.5C18.7984 9.5 19.0845 9.61853 19.2955 9.82951C19.5065 10.0405 19.625 10.3266 19.625 10.625V17.375H26.375C26.6734 17.375 26.9595 17.4935 27.1705 17.7045C27.3815 17.9155 27.5 18.2016 27.5 18.5C27.5 18.7984 27.3815 19.0845 27.1705 19.2955C26.9595 19.5065 26.6734 19.625 26.375 19.625H19.625V26.375C19.625 26.6734 19.5065 26.9595 19.2955 27.1705C19.0845 27.3815 18.7984 27.5 18.5 27.5C18.2016 27.5 17.9155 27.3815 17.7045 27.1705C17.4935 26.9595 17.375 26.6734 17.375 26.375V19.625H10.625C10.3266 19.625 10.0405 19.5065 9.82951 19.2955C9.61853 19.0845 9.5 18.7984 9.5 18.5C9.5 18.2016 9.61853 17.9155 9.82951 17.7045C10.0405 17.4935 10.3266 17.375 10.625 17.375H17.375V10.625C17.375 10.3266 17.4935 10.0405 17.7045 9.82951C17.9155 9.61853 18.2016 9.5 18.5 9.5Z" fill="#D8D8D8"/>
                                                            </svg>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            {errors.avatar && (
                                                <div className="invalid-feedback mw-310 mhauto">
                                                    { errors.avatar?.type === "fileSize" && (
                                                        <div>Max {bytesToSize(maxAvatarSize)}</div>
                                                    )}
                                                    { errors.avatar?.type === "acceptedFormats" && <div>Only PNG, JPEG and GIF.</div> }
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label mhauto">Banner <span data-tip data-for="bannerTooltip"><i className="fas fa-info"></i></span>
                                                <ReactTooltip id="bannerTooltip">
                                                    <div>Recomended 1400x400px</div>
                                                    <div>Max size: {bytesToSize(maxBannerSize)}</div>
                                                </ReactTooltip>
                                            </label>

                                            <div className={`fileUploadPreviewWrapper${defaultBanner === "" && (!bannerWatch || bannerWatch.length === 0) ? ' empty' : ''}`}>
                                                <div className="fileUploadPreview --img-of-contain">
                                                    <BannerWatched control={control} defaultValue={defaultBanner} />
                                                </div>
                                                <div className="fileUploadPreviewInput">
                                                    <input type="file" name="banner" id="bannerFile" accept=".jpg, .jpeg, .png" {...register("banner", {
                                                        fileSize: files => validateFileSize(files[0]?.size, maxBannerSize) || 'Max ' + bytesToSize(maxBannerSize),
                                                        acceptedFormats: files => 
                                                        validateFileType(files[0]?.type, ['image/jpeg', 'image/png']) || 'Only PNG, JPEG e GIF'
                                                    })} style={{ display: "none" }} />
                                                    <label htmlFor="bannerFile" className="fileUploadPreviewOverlay">
                                                        <div className="fileUploadPreviewOverlayInner">
                                                            Upload file
                                                            <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M32 2.75C32.5967 2.75 33.169 2.98705 33.591 3.40901C34.0129 3.83097 34.25 4.40326 34.25 5V32C34.25 32.5967 34.0129 33.169 33.591 33.591C33.169 34.0129 32.5967 34.25 32 34.25H5C4.40326 34.25 3.83097 34.0129 3.40901 33.591C2.98705 33.169 2.75 32.5967 2.75 32V5C2.75 4.40326 2.98705 3.83097 3.40901 3.40901C3.83097 2.98705 4.40326 2.75 5 2.75H32ZM5 0.5C3.80653 0.5 2.66193 0.974106 1.81802 1.81802C0.974106 2.66193 0.5 3.80653 0.5 5L0.5 32C0.5 33.1935 0.974106 34.3381 1.81802 35.182C2.66193 36.0259 3.80653 36.5 5 36.5H32C33.1935 36.5 34.3381 36.0259 35.182 35.182C36.0259 34.3381 36.5 33.1935 36.5 32V5C36.5 3.80653 36.0259 2.66193 35.182 1.81802C34.3381 0.974106 33.1935 0.5 32 0.5L5 0.5Z" fill="#D8D8D8"/>
                                                                <path d="M18.5 9.5C18.7984 9.5 19.0845 9.61853 19.2955 9.82951C19.5065 10.0405 19.625 10.3266 19.625 10.625V17.375H26.375C26.6734 17.375 26.9595 17.4935 27.1705 17.7045C27.3815 17.9155 27.5 18.2016 27.5 18.5C27.5 18.7984 27.3815 19.0845 27.1705 19.2955C26.9595 19.5065 26.6734 19.625 26.375 19.625H19.625V26.375C19.625 26.6734 19.5065 26.9595 19.2955 27.1705C19.0845 27.3815 18.7984 27.5 18.5 27.5C18.2016 27.5 17.9155 27.3815 17.7045 27.1705C17.4935 26.9595 17.375 26.6734 17.375 26.375V19.625H10.625C10.3266 19.625 10.0405 19.5065 9.82951 19.2955C9.61853 19.0845 9.5 18.7984 9.5 18.5C9.5 18.2016 9.61853 17.9155 9.82951 17.7045C10.0405 17.4935 10.3266 17.375 10.625 17.375H17.375V10.625C17.375 10.3266 17.4935 10.0405 17.7045 9.82951C17.9155 9.61853 18.2016 9.5 18.5 9.5Z" fill="#D8D8D8"/>
                                                            </svg>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            {errors.banner && (
                                                <div className="invalid-feedback mw-310 mhauto">
                                                    { errors.banner?.type === "fileSize" && (
                                                        <div>Max {bytesToSize(maxBannerSize)}</div>
                                                    )}
                                                    { errors.banner?.type === "acceptedFormats" && <div>Only PNG and JPG.</div> }
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-12 mt-3 profile-social-links">
                                        <label className="form-label">Links</label>

                                        <div className="row" style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <div className="input-group flex-nowrap">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="addon-wrapping">
                                                                <i className="fab fa-twitter"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" name="twitterhandler" className="form-control" placeholder="Your Twitter Handle" aria-label="twitterhandler" {...register("twitterhandler")} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <div className="input-group flex-nowrap">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="addon-wrapping">
                                                                <i className="fab fa-instagram"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" name="instagramhandler" className="form-control" placeholder="Your Instagram Handle" aria-label="instagramhandler" {...register("instagramhandler")} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <div className="input-group flex-nowrap">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="addon-wrapping">
                                                                <i className="fab fa-facebook-f"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" name="facebookurl" className={errors.facebookurl ? "form-control is-invalid" : "form-control"} placeholder="Your Facebook" aria-label="facebookurl" {...register("facebookurl", { pattern: urlPattern })} />
                                                    </div>
                                                </div>
                                                {errors.facebookurl && <div className="invalid-feedback" style={{ display: 'block' }}>Please enter a valid URL.</div> }
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <div className="input-group flex-nowrap">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="addon-wrapping">
                                                                <i className="fas fa-tablet-alt"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" name="websiteurl" className={errors.websiteurl ? "form-control is-invalid" : "form-control"} placeholder="Your Website" aria-label="websiteurl" {...register("websiteurl", { pattern: urlPattern })} />
                                                    </div>
                                                    {errors.websiteurl && <div className="invalid-feedback" style={{ display: 'block' }}>Please enter a valid URL.</div> }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <button className="btn btn-block btn-bordered-black rounded mt-3 mt-sm-4" type="submit"{...formSaving ? ' disabled' : ''}>{
                                            formSaving 
                                            ? 'Saving...' 
                                            : (
                                                formSuccess 
                                                ? 'Your Profile has been updated!' 
                                                : 'Save'
                                            )
                                        }</button>
                                    </div>

                                    {formError && (
                                        <div className="col-12 mt-3 mt-sm-4">
                                            <div class="alert alert-danger text-center" role="alert">
                                                {formErrorMsg}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </section>
        </>
    );
}

export default ProfileEdit;