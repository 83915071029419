import { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import marketplaceContract from "../../contracts/marketplaceContract.json";
import { getAuthorInterface } from "../../helpers/moralisInterface";
import { ethToCurrency, formatWeiToEth, getCryptoRates, getCurrencyFormatter, showPriceInCurrency } from '../../helpers/nftSettings';
import AuthorBanner from '../AuthorProfile/Page/Banner/Banner';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { Container } from './styles';
import getWindowDimensions  from '../../helpers/getWindowDimensions';
import BannerLoader from "../AuthorProfile/Page/Banner/BannerLoader";
import BreadcrumbsLoader from "../Breadcrumb/BreadcrumbsLoader";
import BalanceLoader from "./Loaders/BalanceLoader";
import { AlertDanger } from "../Elements/Alerts";
import { useCurrency } from "../../providers/currencyContext";

const Balance = () => {
    const { selectedCurrency } = useCurrency();
    const { user, Moralis, web3, enableWeb3, isWeb3Enabled } = useMoralis();
    const { address, abi } = marketplaceContract;
    const { width } = getWindowDimensions()
    const [ loading, setLoading ] = useState(true);
    const [ balance, setBalance ] = useState(0);
    const [ currencyValue, setCurrencyValue ] = useState(0);
    const [ withdrawing, setWithdrawing ] = useState(false);
    const [ success, setSuccess ] = useState(false);
    const [ error, setError ] = useState({
        error: false, 
        message: 'An unknown error has occurred'
    });

    const userInt = getAuthorInterface(user);
    const formatter = getCurrencyFormatter('en-US', selectedCurrency.code);

    document.title = 'Balance - Account | Block of Change';

    const getCryptoRate = async () => {
        await getCryptoRates('ETH', selectedCurrency.code).then(function(response) {
            setCurrencyValue(response);
        });
    };

    const getBalance = async (tries = 0) => {
        const contract = new web3.eth.Contract(abi, address);
        await contract.methods.payments(user.attributes.ethAddress)
            .call()
            .then((response) => {
                setBalance(response);
                setLoading(false);
            })
            .catch((err) => {
                if(err.message === "Provider not set or invalid" && tries < 1) {
                    const options = {
                        provider: "metamask"
                    };
                
                    if(sessionStorage.getItem('provider') !== null) {
                        options.provider = sessionStorage.getItem('provider');
                    }
                      
                    enableWeb3(options);
                    getBalance(1);
                }
                console.log(err.message)
            });
    };

    const withdrawPayments = async () => {
        setWithdrawing(true);
        setError({
            error: false, 
            message: 'An unknown error has occurred'
        });
        const contract = new web3.eth.Contract(abi, address);
        await contract.methods.withdrawPayments(window.ethereum.selectedAddress)
            .send({from: window.ethereum.selectedAddress})
            .then(() => {
                getBalance();
                setWithdrawing(false);
                setSuccess(true)
            })
            .catch((err) => {
                setWithdrawing(false);
                setError({
                    error: true, 
                    message: err.message
                });
                console.log(err.message)
            });
    }

    useEffect(() => {
        if(showPriceInCurrency) {
            getCryptoRate();
        }
    
        if(isWeb3Enabled) {
            getBalance();
        }
    }, [isWeb3Enabled]);

    if(loading) {
        return (
            <>
                <BannerLoader />
                {width > 800 && <BreadcrumbsLoader />}
                <Container className="container">
                    <div className="col-12 col-md-6 offset-md-3 text-center">
                        <BalanceLoader />
                    </div>
                </Container>
            </>
        );
    }

    return (
        <>
            <AuthorBanner image={userInt.banner} name={userInt.username} />

            {width > 800 && <Breadcrumb title="Balance" subpage="Profile" subpagelink="/profile" page="Balance" />}
            
            <Container className="container" style={{ height: 'auto' }}>
                <div className="col-12 col-md-6 offset-md-3 text-center">
                    <p className="text" style={{fontWeight: 500}}>Your current balance is:</p>
                    
                    <div style={{display: 'flex', flexDirection: 'row',marginTop: 50, justifyContent: 'center', alignItems: 'center'}}>
                        <i className="fab fa-ethereum" style={{width: 21, height: 35, color: 'black', marginRight: 10}}></i>
                        <p className="text" style={{fontWeight: 'normal', fontSize: 40, color: '#000000'}}>{formatWeiToEth(Moralis.Units.FromWei(balance))}</p>
                    </div>
                    
                    {showPriceInCurrency && (
                        <p className="text" style={{marginTop: 10, fontWeight: 'normal'}}>{ethToCurrency(Moralis.Units.FromWei(balance), currencyValue, formatter, selectedCurrency.code)}</p>
                    )}

                    {balance > 0 && !success && (
                        <div className="text-center" style={{marginTop: 75}}>
                            <button onClick={withdrawPayments} className="btn btn-bordered-black button-width" type="button"{...withdrawing ? ' disabled' : ''}>{withdrawing ? 'Withdrawing...' : 'Withdraw' }</button>
                        </div>
                    )}
                    { success && <div className="success-message">Your balance has been withdrawn</div> }
                    { error.error && <AlertDanger>{error.message}</AlertDanger> }
                </div>                   
            </Container>
        </>
    );
};

export default Balance;