import { WrongNetworkPopUp } from './styles'
import Web3 from "web3";

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

const WrongNetwork = () => {
    const changeNetwork = async() => {
        const chainId = `0x${CHAIN_ID}`;
        const web3 = new Web3(window.ethereum)
        await web3.currentProvider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: chainId }]
        });
    }

    return (
        <WrongNetworkPopUp>
            <div className='content-wrapper'>
                <p className="title">Please switch to a wallet that supports Ethereum network</p>
                <p className='text'>In order to collect artwork, please change to the Ethereum Network. For more info on how to set up your wallet, click here. Please lock your current wallet and connect with a wallet that supports Etherium network.</p>
                <button onClick={() => changeNetwork()} className='button'>Change network</button>
            </div> 
        </WrongNetworkPopUp>
    )
}

export default WrongNetwork;