import styled, { css } from 'styled-components'

const UserBannerImgStyle = css`
width: 100%;
height: 100%;
object-fit: cover;
`

const UserBannerStyle = css`
position: relative;
height: 300px;
padding: 0;
`

export const UserBanner = styled.section`
${UserBannerStyle}
`

export const UserBannerOverlay = styled.section`
    ${UserBannerStyle}

    &:after {
        position: absolute;
        z-index: 0;
        content: '';
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: var(--primary-bg-color);
        opacity: 0.6;
    }
`

export const UserBannerImg = styled.img`
${UserBannerImgStyle}
`

export const UserBannerSvg = styled.svg`
${UserBannerImgStyle}
`

export const UserBannerOverlayImg = styled.img`
${UserBannerImgStyle}
position: relative;
z-index: -1;
`

export const UserBannerOverlaySvg = styled.svg`
${UserBannerImgStyle}
position: relative;
z-index: -1;
`