import ContentLoader from "react-content-loader"

const BalanceLoader = (props) => {
    return (
        <ContentLoader 
            speed={2}
            width={536}
            height={300}
            viewBox="0 0 536 300"
            backgroundColor="#d4d4d4"
            foregroundColor="#b0b0b0" 
            style={{width: '100%', height: 'auto'}}
            {...props}
        >
            <rect x="183" y="0" rx="3" ry="3" width="170" height="24" />
            <rect x="173" y="74" rx="3" ry="3" width="190" height="50" />
            <rect x="208" y="150" rx="3" ry="3" width="120" height="24" />
            <rect x="78" y="249" rx="25" ry="25" width="380" height="50" />
        </ContentLoader>
    )
};

export default BalanceLoader;