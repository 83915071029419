import { Link } from "react-router-dom";
import { userURL } from "../../../../helpers/entityURLs";
import { DateMeta, OwnerMeta } from "../../styles";

const Details = ({ item }) => {
    return (
        <>
            <OwnerMeta>
                <span>Owner</span>
                <Link to={userURL(item.owner.username, item.owner.slug)}>
                    <img src={item.owner.avatar} alt={item.owner.username} />
                    <h6 className="ml-2">{item.owner.username}</h6>
                </Link>
            </OwnerMeta>
            <DateMeta>Created: <span>{new Intl.DateTimeFormat('en-GB', { 
                month: 'long', 
                day: '2-digit',
                year: 'numeric', 
            }).format(new Date(item.createdAt))}</span></DateMeta>
        </>
    )
};

export default Details;