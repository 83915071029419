import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ContentLoader, Container, Section } from '../Elements/styles';
import { LegalTitle, LegalSubTitle, LegalDescription, LegalText } from './style';
import { markdownText } from '../../helpers/markdownHelper';

let host = window.location.hostname;
if ( window.location.port !== "" ) {
    host+= ':' + window.location.port;
}

const BASE_URL = `${window.location.protocol}//${host}/terms-and-conditions.json`;

const PrivacyAndTerms = () => {
    document.title = 'Loading | Block of Change';
    document.body.className = '';

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    
    useEffect(() => {
        axios.get(`${BASE_URL}`)
        .then(res => {
            setData(res.data);
            setLoading(false);
        })
        .catch(err => console.log(err))
    });

    if(loading) {
        return <ContentLoader>Loading...</ContentLoader>;
    }

    document.title = 'Terms and conditions | Block of Change';
    
    return (
        <Section>
            <Container>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-7">
                        <LegalTitle>{data.title}</LegalTitle>
                        <LegalSubTitle>{data.subtitle}</LegalSubTitle>
                        <LegalDescription>{markdownText(data.description)}</LegalDescription>
                        <LegalText>{markdownText(data.content)}</LegalText>
                    </div>
                </div>
            </Container>
        </Section>
    );
}

export default PrivacyAndTerms;