import axios from "axios";
import { bytesToSize, isAudio } from "./fileUpload";

export const auctionDisabled = true;
export const offersDisabled = true;
export const showPriceInCurrency = true;
export let serviceFee = 0.00;
export const maxRoyaltyFee = 10;
export const maxNftSize = 104857600;
export const maxCertificateSize = 1048576;
export const acceptedMimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4', 'video/webm'];
export const acceptedCertificateMimeTypes = ['application/pdf'];
export const acceptedExtentions = '.jpg, .jpeg, .png, .gif, .mp4, .webm';
export const acceptedCertificateExtentions = '.pdf';
export const acceptedExtentionErrorMsg = 'Only PNG, JPG, GIF, MP4 and WEBM';
export const acceptedCertificateExtentionErrorMsg = 'Only PDF';
export const nftFileSubtitle = 'PNG, JPG, GIF, MP4 and WEBM';
export const cerfificateFileSubtitle = 'PDF';

/**
 * 
 * @param {string} mime_type 
 * @param {string} file_name 
 * 
 * @returns {string}
 */
export const getFileType = (mime_type, file_name) => {
    if(['image/jpeg', 'image/png'].includes(mime_type)) {
        return 'image';
    }
    
    if(['image/gif'].includes(mime_type)) {
        return 'gif';
    }
    
    if(['video/mp4', 'video/webm'].includes(mime_type)) {
        return 'video';
    }

    if(isAudio(file_name)) {
        return 'audio';
    }

    return '';
}

export const getNftFileSubtitle = () => {
    return `${nftFileSubtitle}. Max ${bytesToSize(maxNftSize)}.`;
}

export const getCertificateFileSubtitle = () => {
    return `${cerfificateFileSubtitle}. Max ${bytesToSize(maxCertificateSize)}.`;
}

export const getRoaylityFeeAdvice = (maxRoyalty) => {
    const suggested = ['0%'];

    if(maxRoyalty === 10) {
        suggested.push('4%');
        suggested.push('8%');
    }

    if(maxRoyalty === 20) {
        suggested.push('8%');
        suggested.push('15%');
    }

    if(20 < maxRoyalty) {
        suggested.push('20%');
    }

    if(30 < maxRoyalty) {
        suggested.push('30%');
    }

    return `Suggested: ${suggested.join(', ')}. Maximum is ${maxRoyalty}%`
};

/**
 * 
 * @param {bool} withSpace 
 * 
 * @returns {string}
 */
export const getServiceFeeValueText = (withSpace = false, _serviceFee = 0) => {
    serviceFee = _serviceFee;
    const fee = _serviceFee * 100;
    if(withSpace) {
        return fee + ' %';
    }
    return fee + '%';
};

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 * 
 * @use formatter.format(value);
 */
export const getCurrencyFormatter = (locale = 'en-US', currencyCode = 'USD') => {
    return Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode,
    });
};

/**
 * 
 * @param {number} price 
 * @param {number} currencyValue 
 * @param formatter
 * @param {string} cryptoSymbol 
 * @param {number} decimalCases 
 * 
 * @returns {string}
 */
export const calculateValueToReceive = (price, currencyValue, cryptoSymbol = 'ETH', formatter, currencySymbol = '$', decimalCases = 3) => {
    if(isNaN(price)) {
        price = 0;
    }
    
    if(currencyValue) {
        if(isNaN(currencyValue)) {
            currencyValue = 0;
        }
    } else {
        currencyValue = 0;
    }

    let total = price - (price * serviceFee);
    let priceInCurrency = '';
    total = +parseFloat(total).toFixed( decimalCases )

    if(total > 0 && currencyValue > 0 && showPriceInCurrency) {
        if(formatter) {
            priceInCurrency = ' ' + formatter.format(total * currencyValue);
        } else {
            priceInCurrency = ' ' + currencySymbol + ((total * currencyValue).toFixed(0)).toString();
        }
    }

    return <div>You will receive <strong>{total} {cryptoSymbol}</strong>{priceInCurrency}</div>;
};

export const ethToCurrency = (value, currencyValue, formatter, currencySymbol = '$') => {
    if(value > 0 && currencyValue > 0 && showPriceInCurrency) {
        if(formatter) {
            return formatter.format(value * currencySymbol);
        }
        
        return currencySymbol + ((value * currencyValue).toFixed(0)).toString();
    }

    return '';
};

/**
 * 
 * @param {number} price 
 * @param {number} currencyValue 
 * @param formatter 
 * @param {string} cryptoSymbol 
 * @param {string} currencySymbol 
 * @param {number} decimalCases 
 * 
 * @returns {string}
 */
 export const calculateValueToReceiveWithoutRoyalties = (
    price, 
    royalties, 
    currencyValue, 
    formatter, 
    cryptoSymbol = 'ETH', 
    currencySymbol = '$', 
    decimalCases = 3
) => {
    if(isNaN(price)) {
        price = 0;
    }

    if(isNaN(royalties)) {
        royalties = 0;
    } else {
        console.log(royalties);
        royalties = royalties / 10000;
    }
    
    if(currencyValue) {
        if(isNaN(currencyValue)) {
            currencyValue = 0;
        }
    } else {
        currencyValue = 0;
    }

    let total = price - (price * serviceFee);
    if(royalties > 0) {
        total = total - (total * royalties);
    }
    let priceInCurrency = '';
    total = +parseFloat(total).toFixed( decimalCases )

    if(total > 0 && currencyValue > 0 && showPriceInCurrency) {
        if(formatter) {
            priceInCurrency = ' ' + formatter.format(total * currencyValue);
        } else {
            priceInCurrency = ' ' + currencySymbol + ((total * currencyValue).toFixed(0)).toString();
        }
    }

    return <div>You will receive <strong>{total} {cryptoSymbol}</strong>{priceInCurrency}</div>;
};

/**
 * Moralis.Units.FromWei(price)
 * 
 * @param {*} priceInEth 
 * @returns 
 */
export const formatWeiToEth = (priceInEth) => {
    return `${priceInEth} ETH`;
}

/**
 * 
 * @param {number} price 
 * @param {number} currencyValue 
 * @param formatter 
 * @param {string} cryptoSymbol 
 * @param {number} decimalCases 
 * 
 * @returns {string}
 */
 export const getPriceInCurrency = (priceInEth, currencyValue, formatter, currencySymbol = '$', decimalCases = 3) => {
    if(isNaN(priceInEth)) {
        priceInEth = 0;
    }
    
    if(currencyValue) {
        if(isNaN(currencyValue)) {
            currencyValue = 0;
        }
    } else {
        currencyValue = 0;
    }

    let priceInCurrency = '';
    let total = +parseFloat(priceInEth).toFixed( decimalCases )

    if(total > 0 && currencyValue > 0) {
        if(formatter) {
            priceInCurrency = formatter.format(total * currencyValue);
        } else {
            priceInCurrency = currencySymbol + ((total * currencyValue).toFixed(0)).toString();
        }
    }

    return priceInCurrency;
};

/**
 * 
 * @param {string} cryptoSymbol 
 * @param {string} currencySymbol 
 * 
 * @returns 
 */
export const getCryptoRates = async (cryptoSymbol = 'ETH', currencySymbol = 'USD') => {
    return axios.get(`https://min-api.cryptocompare.com/data/price?fsym=${cryptoSymbol}&tsyms=${currencySymbol}`)
        .then(response => {
            return response?.data[currencySymbol]
        })
    .catch(err => {
        console.log(err);
        return 0;
    });
};

export const genVoucher = async (contractAddress, price, royalties, collection, nftMetadataPath, vUUID) => {
    return JSON.stringify({
        domain: {
            name: 'BOCBL',
            version: '1.0',
            chainId: 5,
            verifyingContract: contractAddress,
        },
        message: {
            "price": price, 
            "royalties": royalties, 
            "collection": collection, 
            "uri": nftMetadataPath , 
            "guid": vUUID
        },
        types: {
            EIP712Domain: [
                { name: "name", type: "string" },
                { name: "version", type: "string" },
                { name: "chainId", type: "uint256" },
                { name: "verifyingContract", type: "address" },
            ],
            NFTVoucher: [
                { name: 'price', type: 'uint256' },
                { name: 'royalties', type: 'uint256' },
                { name: 'collection', type: 'string' },
                { name: 'uri', type: 'string' },
                { name: 'guid', type: 'string' },
            ]
        },
        primaryType: 'NFTVoucher',
    });
};
