import React, { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from "react-hook-form";
import Select from 'react-select'
import { bytesToSize, previewFile } from '../../helpers/fileUpload';
import { validateFileSize, validateFileType } from '../../helpers/validation';
import { useMoralis } from 'react-moralis';
import { useHistory } from 'react-router';
/* import { getAuthorInterface } from '../../helpers/moralisInterface';
import AuthorProfile from "../AuthorProfile/AuthorProfile"; */
import ProcessingModal from "../Loaders/ProcessingModal/ProcessingModal";
import marketplaceContract from "../../contracts/marketplaceContract.json";
import { 
    acceptedExtentionErrorMsg, 
    acceptedExtentions, 
    acceptedMimeTypes, 
    auctionDisabled, 
    offersDisabled, 
    calculateValueToReceive, 
    getCryptoRates, 
    getFileType, 
    getNftFileSubtitle, 
    getRoaylityFeeAdvice, 
    getServiceFeeValueText, 
    maxNftSize, 
    maxRoyaltyFee, 
    showPriceInCurrency,
    acceptedCertificateExtentionErrorMsg, 
    acceptedCertificateExtentions, 
    acceptedCertificateMimeTypes, 
    maxCertificateSize, 
    getCertificateFileSubtitle,
    genVoucher,
    getCurrencyFormatter
} from '../../helpers/nftSettings';
import ReactTooltip from 'react-tooltip';
import ToggleSwitch from '../FormElements/ToggleSwitch/ToggleSwitch';
import { v4 as uuidv4 } from 'uuid';
import { itemURL } from '../../helpers/entityURLs';
import { SelectCustomStyle } from '../Elements/SelectCustomStyle';
import urlSlug from 'url-slug'
import { useCurrency } from '../../providers/currencyContext';
import { ContentLoader } from '../Elements/styles';

const NFTfileWatched = ({ control }) => {
    const nftFile = useWatch({
        control, 
        name: "nftFile", 
        defaultValue: ''
    });

    return previewFile(nftFile, 'NFT File');
};

const NFTcertificateWatched = ({ control }) => {
    const nftCertificate = useWatch({
        control, 
        name: "nftCertificate", 
        defaultValue: ''
    });

    if(nftCertificate instanceof FileList && nftCertificate.length > 0) {
        return nftCertificate[0].name;
    }

    return 'Choose file';
};

const Create = () => {
    document.title = 'Create NFT | Block of Change';
    const defaultValues = {
        nftFile: undefined, 
        nftCertificate: undefined, 
        name: '', 
        description: '', 
        artist: '', 
        category: '', 
        royalties: '', 
        status: { value: 'not-for-sale', label: 'Not for sale' },
        price: 0, 
        explicit_content: false,
        lazy_mint: false
    };

    const { selectedCurrency } = useCurrency();
    const { user, Moralis, web3 } = useMoralis();
    const { contractName, address, abi } = marketplaceContract;
    const history = useHistory();
    const { register, handleSubmit, control, watch, reset, formState: { errors } } = useForm({
        mode: "onBlur", 
        defaultValues: defaultValues
    });
    const [loading, setLoading] = useState(true);
    const [formSaving, setFormSaving] = useState(false);
    const [formError, setFormError] = useState(false);
    const [formErrorMsg, setFormErrorMessage] = useState('');
    const [currencyValue, setCurrencyValue] = useState(0);
    const [categories, setCategories] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [artists, setArtists] = useState([]);
    const [artistOptions, setArtistOptions] = useState([]);
    const [maxRoyalty, setMaxRoyalty] = useState(maxRoyaltyFee);
    const [explicitContent, setExplicitContent] = useState(false);
    const [lazyMint, setLazyMint] = useState(false);
    const [maxFees, setMaxFees] = useState(0);
    const statusWatch = watch("status");
    const priceWatch = watch("price", 0);
    const nftFileWatch = watch("nftFile", {length: 0});
    const formatter = getCurrencyFormatter('en-US', selectedCurrency.code);
    const nftCertificateSmallStyle = { 'fontSize': '80%' };

    const statusOptions = [
        { value: 'not-for-sale', label: 'Not for sale' },
        { value: 'for-sale', label: 'Fixed sale' }
    ];

    if(!auctionDisabled) {
        statusOptions.push({ value: 'timed-auction', label: 'Timed auction' });
    }

    if(!offersDisabled) {
        statusOptions.push({ value: 'open-for-offers', label: 'Open for offers' });
    }

    useEffect(() => {
        document.body.className = '';
        
        const loadCategories = async () => {
            const data = await Moralis.Cloud.run('getNFTCategories');
            if(data.length > 0) {
                const options = [];
                data.map((category, idx) => options.push({value: idx, label: category.name}));
                setCategoryOptions(options);
                setCategories(data);
            }
        };
        
        const loadArtists = async () => {
            const data = await Moralis.Cloud.run('getAllArtists');
            if(data.length > 0) {
                const options = [];
                data.map((artist, idx) => options.push({value: idx, label: artist.name}));
                setArtistOptions(options);
                setArtists(data);
            }
        };

        const getMaxRoyalties = async () => {
            const contract = new web3.eth.Contract(abi, address);
            await contract.methods.getMaxRoyalties()
                .call()
                .then((response) => setMaxRoyalty(response / 100))
                .catch((err) => console.log(err.message));
        };
        
        const getMaxFees = async () => {
            const contract = new web3.eth.Contract(abi, address);
            await contract.methods.getComissionShare()
                .call()
                .then((response) => setMaxFees((response/100)/100))
                .catch((err) => setMaxFees(0));
        };

        const loadData = async () => {
            if(showPriceInCurrency) {
                await getCryptoRates('ETH', selectedCurrency.code).then(function(response) {
                    setCurrencyValue(response);
                });
            }

            await loadCategories();
            await loadArtists();
            await getMaxFees()
            await getMaxRoyalties()

            setLoading(false);
        }

        loadData();
    }, [])

    const triggerSuccessForm = () => {
        reset(defaultValues, {
            keepErrors: false, 
            keepDirty: false,
            keepIsSubmitted: false,
            keepTouched: false,
            keepIsValid: false,
            keepSubmitCount: false
        });
        setFormError(false);
        setFormErrorMessage('');
        setFormSaving(false);
    }

    const triggerErrorForm = (message) => {
        setFormSaving(false);
        setFormError(true);
        setFormErrorMessage(message);
    }

    const mint = async (royalties, metadataPath, collection, nft) => {
        const contract = new web3.eth.Contract(abi, address);

        await contract.methods
            .create(royalties, metadataPath, collection)
            .send({from: user.get('ethAddress')})
            .then(() => {
                triggerSuccessForm();
                history.push(itemURL(nft.id));
            })
            .catch((err) => {
                nft.destroy()
                if(err.code === 4001) {
                    setFormSaving(false);
                } else {
                    triggerErrorForm(err.message)
                }
            });
    }

    const mintAndSale = async (price, royalties, metadataPath, collection, nft) => {
        const contract = new web3.eth.Contract(abi, address);
        await contract.methods
            .createAndSale(price, royalties, metadataPath, collection)
            .send({from: user.get('ethAddress')})
            .then(() => {
                triggerSuccessForm();
                history.push(itemURL(nft.id));
            })
            .catch((err) => {
                nft.destroy()
                if(err.code === 4001) {
                    setFormSaving(false);
                } else {
                    triggerErrorForm(err.message)
                }
            });
    }

    const onSubmit = async data => {
        setFormSaving(true);
        
        if(typeof data.status === 'object') {
            data.status = data.status.value;
        }

        const user = Moralis.User.current();

        const file = data.nftFile[0];
        const fileName = urlSlug(file.name);
        const fileType = getFileType(file.type, fileName);

        const royalty = data.royalties * 100;
        const explicit_content = explicitContent;
        const lazy_mint = lazyMint;

        const nftFile = new Moralis.File(fileName, file);
        await nftFile.saveIPFS();
        const nftFilePath = nftFile.ipfs();

        const metadata = {
            title: data.name,
            name: data.name,
            description: data.description,
            type: "object",
            attributes: [
                {
                    trait_type: 'Creator',
                    value: user.attributes.username,
                }
            ],
            properties: {
                name: {
                    type: 'string',
                    description: data.name,
                },
                description: {
                    type: 'string', 
                    description: data.description
                },
                media: {
                    type: 'string', 
                    description: nftFilePath
                },
                media_type: {
                    type: 'string', 
                    description: fileType
                },
                media_mime: {
                    type: 'string', 
                    description: file.type
                },
                nsfw: {
                    type: 'string', 
                    description: (explicit_content ? 'Yes' : 'No' )
                }
            },
            media: nftFilePath,
            royalties: royalty,
            collection: ''
        };

        let category = {};
        let categoryObj;
        if(data.category) {
            if(typeof data.category === 'object') {
                data.category = data.category.value;
            }
            category = categories[data.category];
            const NFTCategoryObj = Moralis.Object.extend("NFTCategory");
            categoryObj = NFTCategoryObj.createWithoutData(category.objectId);

            metadata.properties.category = {
                type: 'string', 
                description: category.name
            };
        }

        let artist = {};
        let artistObj;
        if(data.artist) {
            if(typeof data.artist === 'object') {
                data.artist = data.artist.value;
            }
            artist = artists[data.artist];
            const NFTArtistObj = Moralis.Object.extend("Artist");
            artistObj = NFTArtistObj.createWithoutData(artist.id);

            metadata.properties.artist = {
                type: 'string', 
                description: artist.name
            };
        }

        /*** Certificate ***/
        let nftCertificateFilePath = '';
        if ( data.nftCertificate[0] ) {
            const fileCT = data.nftCertificate[0];
            const fileNameCT = fileCT.name;
            //const fileTypeCT = getFileType(fileCT.type, fileNameCT);
            const nftFileCT = new Moralis.File(fileNameCT, fileCT);
            await nftFileCT.saveIPFS();
            nftCertificateFilePath = nftFileCT.ipfs();

            metadata.properties.certificate = {
                type: 'string', 
                description: nftCertificateFilePath
            };
        }

        if(data.status === "timed-auction" && auctionDisabled) {
            data.status = "not-for-sale";
        }

        if(data.status === "open-for-offers" && offersDisabled) {
            data.status = "not-for-sale";
        }

        if(data.status !== "for-sale") {
            data.price = 0;
        }

        const nftMetadataFile = new Moralis.File("nftmetadata.json", {base64 : btoa(JSON.stringify(metadata))});
        await nftMetadataFile.saveIPFS();
        const nftMetadataPath = nftMetadataFile.ipfs();

        const NftObject = Moralis.Object.extend("NFTs");
        const nft = new NftObject();

        nft.set('token_id', '');
        nft.set('token_uri', nftMetadataPath);
        nft.set('token_file', nftFilePath);
        nft.set('contract_name', contractName);
        nft.set('contract_type', 'ERC721');
        nft.set('creator', user);
        nft.set('owner', user);
        nft.set('name', data.name);
        nft.set('description', data.description);
        nft.set('explicit_content', explicit_content);
        nft.set('file_type', fileType);
        nft.set('mime_type', file.type);
        nft.set('category', categoryObj);
        nft.set('artist', artistObj);
        nft.set('royalties', metadata.royalties);
        nft.set('collection', metadata.collection);
        nft.set('put_on_sale_processing', false);
        nft.set('sale_processing', false);
        nft.set('cancel_sale_processing', false);
        nft.set('burn_processing', false);
        nft.set('burned', false);
        nft.set('isFeatured', false);
        nft.set('nft_certificate', nftCertificateFilePath);

        if ( lazy_mint ) {
            let signature;
            //let web3 = await Moralis.Web3.enableWeb3();
            /***** Lazymint Signature ******/
            const vUUID = uuidv4();
            const msgParams = await genVoucher(
                address, 
                Moralis.Units.ETH(data.price), 
                metadata.royalties, 
                metadata.collection, 
                nftMetadataPath, 
                vUUID
            );
            const account = user.attributes.ethAddress;
            const params = [account, msgParams];
            const method = 'eth_signTypedData_v4';
            await web3.currentProvider.sendAsync(
                { method, params, account }, 
                async function (err, result) {
                    let hasError = false;  
                    if (err) { triggerErrorForm("An error occurred. Please try again."); hasError = true; }
                    if (result.error) { triggerErrorForm("An error occurred. Please try again."); hasError = true; }
                    if (result.error) { triggerErrorForm("An error occurred. Please try again."); hasError = true; }
                    if ( ! hasError ) {
                        signature = result.result;
                        nft.set('guid', vUUID);
                        nft.set('signature', signature);
                        nft.set('lazy_mint', true);
                        nft.set('lazy_mint_price', Moralis.Units.ETH(data.price));
                        nft.set('mint_processing', false);
                        const response = await nft.save();

                        history.push(itemURL(response.id));

                        triggerSuccessForm();
                    }
                }
            );
            return;
        } else {
            nft.set('guid', '');
            nft.set('signature', '');
            nft.set('lazy_mint', false);    
            nft.set('lazy_mint_price', '');
            nft.set('mint_processing', true);

            if(data.status === 'for-sale') {
                nft.set('put_on_sale_processing', true);
            }
        }
        
        let response;
        try {
            response = await nft.save();
        } catch(error) {
            triggerErrorForm(error.message);
            return;
        }

        try {
            if ( ! lazy_mint ) {
                if(data.status === "not-for-sale") {
                    await mint(metadata.royalties, nftMetadataPath, metadata.collection, response);
                } else if(data.status === 'for-sale') {
                    await mintAndSale(Moralis.Units.ETH(data.price), metadata.royalties, nftMetadataPath, metadata.collection, response);
                }
            }
        } catch(error) {
            nft.destroy().then((nft) => {
                triggerErrorForm(error.message);
            }, (err) => {
                triggerErrorForm(error.message);
            })
        }
    }

    if(loading) {
        return <ContentLoader>Loading...</ContentLoader>
    }

    return (
        <>
            {formSaving && (
                <ProcessingModal />
            )}
            <section className="author-area">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-12 col-md-8 offset-md-2">
                            <div className="intro mt-5 mt-lg-0 mb-4 mb-lg-5">
                                <div className="intro-content">
                                    <span>Get Started</span>
                                    <h3 className="mt-3 mb-0">Create an Item</h3>
                                </div>
                            </div>

                            <form className="item-form card no-hover" onSubmit={handleSubmit(onSubmit)}>
                                {formError && (
                                    <div className="alert alert-danger" role="alert">{formErrorMsg}</div>
                                )}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="control-label mw-310 mhauto required">Upload File</label>
                                            <small id="nftFileHelp" className="form-text text-muted mw-310 mhauto mb-3">{getNftFileSubtitle()}</small>
                                            <div className={`fileUploadPreviewWrapper mw-310${!nftFileWatch || nftFileWatch.length === 0 ? ' empty' : ''}`}>
                                                <div className="fileUploadPreview">
                                                    <NFTfileWatched control={control} />
                                                </div>
                                                <div className="fileUploadPreviewInput">
                                                    <input type="file" name="nftFile" id="nftFile" accept={acceptedExtentions} {...register("nftFile", {
                                                        required: true, 
                                                        fileSize: files => validateFileSize(files[0]?.size, maxNftSize) || 'Max ' + bytesToSize(maxNftSize),
                                                        acceptedFormats: files =>
                                                        validateFileType(files[0]?.type, acceptedMimeTypes) || acceptedExtentionErrorMsg
                                                    })} style={{ display: "none" }} />
                                                    <label htmlFor="nftFile" className="fileUploadPreviewOverlay">
                                                        <div className="fileUploadPreviewOverlayInner">
                                                            Upload file
                                                            <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M32 2.75C32.5967 2.75 33.169 2.98705 33.591 3.40901C34.0129 3.83097 34.25 4.40326 34.25 5V32C34.25 32.5967 34.0129 33.169 33.591 33.591C33.169 34.0129 32.5967 34.25 32 34.25H5C4.40326 34.25 3.83097 34.0129 3.40901 33.591C2.98705 33.169 2.75 32.5967 2.75 32V5C2.75 4.40326 2.98705 3.83097 3.40901 3.40901C3.83097 2.98705 4.40326 2.75 5 2.75H32ZM5 0.5C3.80653 0.5 2.66193 0.974106 1.81802 1.81802C0.974106 2.66193 0.5 3.80653 0.5 5L0.5 32C0.5 33.1935 0.974106 34.3381 1.81802 35.182C2.66193 36.0259 3.80653 36.5 5 36.5H32C33.1935 36.5 34.3381 36.0259 35.182 35.182C36.0259 34.3381 36.5 33.1935 36.5 32V5C36.5 3.80653 36.0259 2.66193 35.182 1.81802C34.3381 0.974106 33.1935 0.5 32 0.5L5 0.5Z" fill="#D8D8D8"/>
                                                                <path d="M18.5 9.5C18.7984 9.5 19.0845 9.61853 19.2955 9.82951C19.5065 10.0405 19.625 10.3266 19.625 10.625V17.375H26.375C26.6734 17.375 26.9595 17.4935 27.1705 17.7045C27.3815 17.9155 27.5 18.2016 27.5 18.5C27.5 18.7984 27.3815 19.0845 27.1705 19.2955C26.9595 19.5065 26.6734 19.625 26.375 19.625H19.625V26.375C19.625 26.6734 19.5065 26.9595 19.2955 27.1705C19.0845 27.3815 18.7984 27.5 18.5 27.5C18.2016 27.5 17.9155 27.3815 17.7045 27.1705C17.4935 26.9595 17.375 26.6734 17.375 26.375V19.625H10.625C10.3266 19.625 10.0405 19.5065 9.82951 19.2955C9.61853 19.0845 9.5 18.7984 9.5 18.5C9.5 18.2016 9.61853 17.9155 9.82951 17.7045C10.0405 17.4935 10.3266 17.375 10.625 17.375H17.375V10.625C17.375 10.3266 17.4935 10.0405 17.7045 9.82951C17.9155 9.61853 18.2016 9.5 18.5 9.5Z" fill="#D8D8D8"/>
                                                            </svg>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            {errors.nftFile ? (
                                                <div className="invalid-feedback mw-310 mhauto">
                                                    { errors.nftFile?.type === "fileSize" && (
                                                        <div>Max {bytesToSize(maxNftSize)}</div>
                                                    )}
                                                    { errors.nftFile?.type === "acceptedFormats" && <div>{acceptedExtentionErrorMsg}</div> }
                                                    { errors.nftFile?.type === "required" && <div>Required Field.</div> }
                                                </div>
                                            ) : ('')}                                
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <label className="control-label required">Name</label>
                                            <input type="text" className={errors.name ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Item Name" {...register("name", { required: true })} />
                                            { errors.name && <div className="invalid-feedback">Required Field.</div> }
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-group">
                                            <small id="descriptionHelp" className="form-text text-muted">The description will be included on the item's detail page. <a href="https://www.markdownguide.org/cheat-sheet/" rel="nofollow noopener noreferrer" target="_blank">Markdown</a> syntax is supported.</small>
                                            <textarea className="form-control" name="description" id="description" placeholder="Description" cols={30} rows={3} defaultValue={""} {...register("description")}/>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="control-label">NFT Certificate <small style={nftCertificateSmallStyle} className="text-muted">({getCertificateFileSubtitle()})</small></label>

                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="file" 
                                                    className="custom-file-input" 
                                                    name="nftCertificate" 
                                                    id="nftCertificate" 
                                                    accept={acceptedCertificateExtentions} 
                                                    {...register("nftCertificate", {
                                                        required: false, 
                                                        fileSize: files => validateFileSize(files[1]?.size, maxCertificateSize) || 'Max ' + bytesToSize(maxCertificateSize),
                                                        acceptedFormats: files => validateFileType(files[1]?.type, acceptedCertificateMimeTypes) || acceptedCertificateExtentionErrorMsg
                                                    })}
                                                    />
                                                    <label className="custom-file-label" htmlFor="nftCertificate"><NFTcertificateWatched control={control} /></label>
                                                </div>
                                            </div>
                                            {errors.nftCertificate ? (
                                                <div className="invalid-feedback mw-310 mhauto">
                                                    { errors.nftCertificate?.type === "fileSize" && (
                                                        <div>Max {bytesToSize(maxCertificateSize)}</div>
                                                    )}
                                                    { errors.nftCertificate?.type === "acceptedFormats" && <div>{acceptedCertificateExtentionErrorMsg}</div> }
                                                </div>
                                            ) : ('')}                                
                                        </div>
                                    </div>

                                    {artists.length > 0 && (
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label className="control-label required">Artist</label>
                                                <Controller 
                                                    control={control}
                                                    name="artist" 
                                                    rules={{ required: true }}
                                                    render={({ field }) => <Select 
                                                        {...field} 
                                                        styles={SelectCustomStyle}
                                                        className={errors.artist ? "is-invalid" : ""}
                                                        options={artistOptions} 
                                                        menuPortalTarget={document.body}
                                                    />}
                                                />
                                                { errors.artist && <div className="invalid-feedback d-block">Required Field.</div> }
                                            </div>
                                        </div>
                                    )}
                                    
                                    {categories.length > 0 && (
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label className="control-label required">Category</label>
                                                <Controller 
                                                    control={control}
                                                    name="category" 
                                                    rules={{ required: true }}
                                                    render={({ field }) => <Select 
                                                        {...field} 
                                                        styles={SelectCustomStyle}
                                                        className={errors.category ? "is-invalid" : ""}
                                                        options={categoryOptions} 
                                                        menuPortalTarget={document.body}
                                                    />}
                                                />
                                                { errors.category && <div className="invalid-feedback d-block">Required Field.</div> }
                                            </div>
                                        </div>
                                    )}

                                    <div className="col-12">
                                        <div className="bv py-3 mb-3">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-grow-1 label-like">Explicit &amp; Sensitive Content <span data-tip data-for="explicitContentTooltip"><i className="fas fa-info"></i></span></div>
                                                <ToggleSwitch
                                                    name="explicit_content"
                                                    id="explicit_content"
                                                    checked={explicitContent}
                                                    small={true}
                                                    onChange={() => {
                                                        setExplicitContent(!explicitContent)
                                                    }}
                                                />
                                            </div>
                                            <ReactTooltip id="explicitContentTooltip">
                                                <div>Setting your asset as explicit and sensitive content, like pornography and other not safe for work (NSFW) content, will protect users with safe search while browsing Block of Change.</div>
                                            </ReactTooltip>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="control-label required">Royalties</label>
                                            <div className="input-group flex-nowrap">
                                                <input type="number" className={errors.royalties ? "form-control is-invalid" : "form-control"} name="royalties" id="royalties" min="0" max={maxRoyalty} placeholder="royalties" {...register("royalties", { required: true, min: 0, max: maxRoyalty })} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text" id="addon-wrapping">%</span>
                                                </div>
                                            </div>
                                            <small id="royaltiesHelp" className="form-text text-muted">{getRoaylityFeeAdvice(maxRoyalty)}</small>
                                            {errors.royalties ? (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    { errors.royalties?.type === "required" && <div>Required Field.</div> }
                                                    { errors.royalties?.type === "min" && <div>Min value is 0.</div> }
                                                    { errors.royalties?.type === "max" && <div>Max value is {maxRoyalty}.</div> }
                                                </div>
                                            ) : ('')}
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label className="control-label required">Status</label>
                                            <Controller 
                                                control={control}
                                                name="status" 
                                                rules={{ required: true }}
                                                render={({ field }) => <Select 
                                                    {...field} 
                                                    styles={SelectCustomStyle}
                                                    className={errors.status ? "is-invalid" : ""}
                                                    options={statusOptions} 
                                                    menuPortalTarget={document.body}
                                                />}
                                            />

                                            { errors.status && <div className="invalid-feedback d-block">Required Field.</div> }
                                        </div>
                                    </div>
                                    
                                    {statusWatch.value === "for-sale" && (
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label className="control-label required">Price</label>
                                                <div className="input-group flex-nowrap">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="addon-wrapping">
                                                            <i className="fab fa-ethereum"></i> ETH
                                                        </span>
                                                    </div>
                                                    <input type="number" className={errors.price ? "form-control is-invalid" : "form-control"} name="price" id="price" placeholder="Item Price" inputMode="decimal" min={0.00000001} step={0.00000001} {...register("price", { required: true, min: 0.00000001 })} defaultValue={0} />
                                                </div>
                                                <small id="priceHelp" className="form-text text-muted">Service Fee <strong>{getServiceFeeValueText(false,maxFees)}</strong>
                                                    {calculateValueToReceive(priceWatch, currencyValue, 'ETH', formatter, selectedCurrency.code)}
                                                </small>
                                                {errors.price ? (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        { errors.price?.type === "required" && <div>Required Field.</div> }
                                                        { errors.price?.type === "min" && <div>Min value is 0.00000001.</div> }
                                                    </div>
                                                ) : ('')}
                                            </div>
                                        </div>
                                    )}

                                    <div className="col-12">
                                        <div className="bvt py-3 mb-3">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-grow-1 label-like">Free minting&nbsp;<span data-tip data-for="lazyMintTooltip"><i className="fas fa-info"></i></span></div>
                                                <ToggleSwitch
                                                    name="lazy_mint"
                                                    id="lazy_mint"
                                                    checked={lazyMint} 
                                                    small={true}
                                                    onChange={() => setLazyMint(!lazyMint)}
                                                />
                                            </div>
                                            <ReactTooltip id="lazyMintTooltip">
                                                <div>Your item won't be minted in blockchain. Your NFT will be stored for further minting by buyer</div>
                                            </ReactTooltip>
                                        </div>
                                    </div>
                                    
                                    <div className="col-12">
                                        <button className="btn btn-block btn-bordered-black rounded mt-3 mt-sm-4" type="submit"{...formSaving ? ' disabled' : ''}>{formSaving ? 'Creating Item...' : 'Create Item' }</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Create;