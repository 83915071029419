import React from 'react';
import { ImageWrapper } from './styles'

const NoRoute = () => {
    document.title = 'Not Found | Block of Change';

    return (
        <section className="not-found-area">
            <div className="container">
                <div className="intro text-center">
                <ImageWrapper className="intro text-center">
                    <img className="image-404" src="/img/404.svg" alt="Error 404" />
                    <p className="text-404">Page lost in the Ark, we can't find what you're looking for.</p>
                </ImageWrapper>
                </div>
            </div>
        </section>
    )
}

export default NoRoute;