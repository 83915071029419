import styled, { css } from "styled-components";

export const MailchimpForm = styled.form`
position: relative;
display: flex;
align-items: center;
`

export const MailchimpInputField = styled.input`
display: block;
width: 100%;
padding: 0.375rem 0.75rem;
font-weight: 400;
line-height: 1.5;
background-clip: padding-box;
background: no-repeat;
box-shadow: none;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
font-size: 14px;
text-indent: 3%;
height: 50px;
border: none;
outline: none;
padding-left: 20px;
border-radius: 2px;
background-color: #ededed;
color: #495057;

&::placeholder {
    color: var(--primary-p-color);
}

&:focus {
    border: none;
    outline: none;
    box-shadow: none;
    background-color: #ededed;
    color: #495057;
}

@media (max-width: 575px) {
    text-indent: 0;
}
`

export const MailchimpSubmitBtn = styled.button`
position: absolute;
right: 0;
top: 0;
bottom: 0;
padding: 10px 20px;
border-top-right-radius: 6px;
border-bottom-right-radius: 6px;
border: none;

&:focus {
    outline: 0;
}
`

const MailchimpFormStatusStyle = css`
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
border-radius: 2px;
font-size: 12px;
line-height: 14px;
padding: 5px;
display: flex;
align-items: center;
justify-content: center;

a {
    margin-left: 5px;
    font-size: 12px !important;
    line-height: 14px !important;
    text-decoration: underline !important;
}
`

export const MailchimpFormStatus = styled.div`
${MailchimpFormStatusStyle}
background: #000;
color: #FFF;

a {
    color: #FFF !important;
}
`

export const MailchimpFormStatusError = styled.div`
${MailchimpFormStatusStyle}
background: var(--fire-red);
color: #FFF;

a {
    color: #FFF !important;
}
`

export const MailchimpFormStatusSuccess = styled.div`
${MailchimpFormStatusStyle}
background: var(--valid-color);
color: #FFF;

a {
    color: #FFF !important;
}
`