//import React from 'react';
import { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
// importing MyRouts where we located all of our theme
import MyRouts from './routers/routes'
import { useInfo } from '../src/providers/InfoContext'
import Web3 from "web3";
import WrongNetwork from "./components/WrongNetwork/wrongNetwork";
import { PageLoader } from "./sharedComponents/PageLoader";

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

function App() {
  const [ loading, setLoading ] = useState(true);
  const [ isArtist, setIsArtist ] = useState(false);
  const [ canCreate, setCanCreate ] = useState(false);
  const [ isAdmin, setIsAdmin ] = useState(false);
  const { changeWrongNetwork, wrongNetwork } = useInfo()
  const { user, isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading, Moralis } = useMoralis();

  const getChainId = async() => {
    const web3 = new Web3(window.ethereum)
    if(web3) {
      try {
        const chainId = await web3.eth.net.getId();

        if (`${chainId}` !== `${CHAIN_ID}`) {
          changeWrongNetwork(true)
        } else {
          changeWrongNetwork(false)
        }
      } catch(error) {
        console.log(error.message);
      }
    }
  }

  useEffect(() => {
    Moralis.onChainChanged(function () {
      getChainId()
    });
  }, []);

  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const script = document.createElement("script");
    script.src = "/assets/js/main.js";
    document.body.appendChild(script);

    if (document.readyState === "complete") {
      return () => {
        document.body.removeChild(script);
      };
    } else {
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  useEffect(() => {
    function handleViewPortHeight() {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    window.addEventListener("resize", handleViewPortHeight);
    return () => window.removeEventListener("resize", handleViewPortHeight);
  }, []);

  useEffect(() => {
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) {
      const options = {
        provider: "metamask"
      };

      if(sessionStorage.getItem('provider') !== null) {
        options.provider = sessionStorage.getItem('provider');
      }
      
      enableWeb3(options);
    }

    if(isAuthenticated && user) {
      const is_artist = user.get('is_artist');
      const can_create = user.get('can_create');
      const is_admin = user.get('is_admin');
      
      if(is_artist && typeof is_artist == "boolean") {
        setIsArtist(is_artist);
      }
      if(can_create && typeof can_create == "boolean") {
        setCanCreate(can_create);
      }
      if(is_admin && typeof is_admin == "boolean") {
        setIsAdmin(is_admin);
      }
    }
    
    getChainId()

    setLoading(false);
  }, [isAuthenticated, enableWeb3, isWeb3Enabled, isWeb3EnableLoading, user]);

  if(loading) {
    return (
      <PageLoader>
        <svg xmlns="http://www.w3.org/2000/svg" width="55" height="80" viewBox="0 0 55 80" fill="#FFF">
          <g transform="matrix(1 0 0 -1 0 80)">
            <rect width="10" height="20" rx="3">
              <animate 
                attributeName="height" 
                begin="0s" 
                dur="4.3s" 
                values="20;45;57;80;64;32;66;45;64;23;66;13;64;56;34;34;2;23;76;79;20" 
                calcMode="linear" 
                repeatCount="indefinite" 
              />
            </rect>
            <rect x="15" width="10" height="80" rx="3">
              <animate 
                attributeName="height" 
                begin="0s" 
                dur="2s" 
                values="80;55;33;5;75;23;73;33;12;14;60;80" 
                calcMode="linear" 
                repeatCount="indefinite"
              />
            </rect>
            <rect x="30" width="10" height="50" rx="3">
              <animate 
                attributeName="height" 
                begin="0s" 
                dur="1.4s" 
                values="50;34;78;23;56;23;34;76;80;54;21;50" 
                calcMode="linear" 
                repeatCount="indefinite"
              />
            </rect>
            <rect x="45" width="10" height="30" rx="3">
              <animate 
                attributeName="height" 
                begin="0s" 
                dur="2s" 
                values="30;45;13;80;56;72;45;76;34;23;67;30" 
                calcMode="linear" 
                repeatCount="indefinite"
              />
            </rect>
          </g>
        </svg>
        Loading...
      </PageLoader>
    )
  }

  return (
    <>
      { wrongNetwork && <WrongNetwork /> }
      <MyRouts isAuthenticated={isAuthenticated} isArtist={isArtist} canCreate={canCreate} isAdmin={isAdmin} />
    </>
  )
}

export default App;