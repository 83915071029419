import React, { useEffect, useState } from 'react';
import { useMoralis } from "react-moralis";
import AuthorBanner from '../AuthorProfile/Page/Banner/Banner';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import {Container, Divider} from '../Artist/styles'
import NFT from '../NFT/NFT'
import { getAuthorInterface } from '../../helpers/moralisInterface';
import getWindowDimensions  from '../../helpers/getWindowDimensions';
import { ProfileNoItems } from './styles';
import NFTLoader from '../NFT/NFTLoader';
import BannerLoader from '../AuthorProfile/Page/Banner/BannerLoader';
import AuthorInfo from '../AuthorProfile/Page/Info/Info';
import AuthorInfoLoader from '../AuthorProfile/Page/Info/InfoLoader';
import BreadcrumbsLoader from '../Breadcrumb/BreadcrumbsLoader';
import TitleLeftLoader from '../Loaders/TitleLeftLoader';
import { Link } from 'react-router-dom';

const Profile = () => {
    const [ loading, setLoading ] = useState(true);
    const { user, Moralis } = useMoralis();
    const [ artist, setArtist ] = useState({});
    const [ createdNFTs, setCreatedNFTs] = useState([]);
    const [ ownedNFTs, setOwnedNFTs] = useState([]);
    const { width } = getWindowDimensions();

    document.title = 'Loading | Block of Change';

    useEffect(()=> {
        setArtist(getAuthorInterface(user));

        const loadCreatedNFTs = async (load = true) => {
            const response = await Moralis.Cloud.run('getMyCreatedItems');
            setCreatedNFTs(response);
            setLoading(load);
        };
        
        const loadOwnedNFTs = async (load = true) => {
            const response = await Moralis.Cloud.run('getMyOwnedItems');
            setOwnedNFTs(response);
            setLoading(load);
        };

        if(user.get('is_artist')) {
            loadCreatedNFTs(false);
        } else {
            loadOwnedNFTs(false);
        }
    }, [user]);
    
    if(loading) {
        return (
            <>
                <BannerLoader />
                {width > 800 && <BreadcrumbsLoader />}
                <Container className="container">
                    <AuthorInfoLoader />

                    <Divider />

                    <div className="col-12 col-md-8 col-lg-7" style={{marginTop: 48, paddingLeft: 0}}>
                        <TitleLeftLoader />
                    </div>

                    <div style={{marginLeft: width > 575 ? -15 : 0}}>
                        <div className="row items">
                            {[0, 1, 2, 4].map((idx) => (
                                <div className="col-12 col-sm-6 col-lg-3" key={`nft-${idx}`} style={{marginTop: 30}}>
                                    <NFTLoader style={{boxShadow: '0 0 2px rgba(0, 0, 0, .15), 0 2px 2px rgba(0, 0, 0, .05)', width: '100%', height: 'auto'}} />
                                </div>
                            ))}
                        </div>
                    </div>
                </Container>
            </>
        );
    }

    document.title = 'Profile | Block of Change';

    return (
        <>
            <AuthorBanner image={artist.banner} name={artist.username} />
            {width > 800 && <Breadcrumb title="Profile" page="Profile" />}
            <Container className="container">
                <AuthorInfo artist={artist} />

                <Divider />

                <div className="col-12 col-md-8 col-lg-7" style={{marginTop: 48, paddingLeft: 0}}>
                    <div className="intro mb-4">
                        <span>{ artist.is_artist ? 'Collectibles' : 'Owned' }</span>
                        <h3 className="mt-3 mb-0">{ artist.is_artist ? 'Artworks' : 'Collectibles' }</h3>
                    </div>
                </div>

                {createdNFTs.length === 0 && ownedNFTs.length === 0 ? (
                    <ProfileNoItems>
                        <h4>No items on your display</h4>
                        {artist.is_artist ? (
                            <>
                                <p>Maybe it's time to start creating. Create your masterwork.</p>
                                <Link to="/create" className="btn btn-bordered-black">Create</Link>
                            </>
                        ) : (
                            <>
                                <p>Maybe it's time to start collecting. Explore our masterworks.</p>
                                <Link to="/explore" className="btn btn-bordered-black">Explore</Link>
                            </>
                        )}
                    </ProfileNoItems>
                ) : (
                    <div className="row items" style={{ marginLeft: -15, marginRight: -15 }}>
                        {artist.is_artist 
                            ? createdNFTs.map((item, idx) => <NFT gridNumber={4} key={`c-${idx}`} item={item} author={true} /> )
                            : ownedNFTs.map((item, idx) => <NFT gridNumber={4} key={`o-${idx}`} item={item} author={true} /> )
                        }
                    </div>
                )}
            </Container>
        </>
    )
};

export default Profile;