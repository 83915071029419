import styled from 'styled-components'

export const FiltersWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: row;
@media (max-width: 600px) {
    overflow: auto;
}
`
export const FilterWrapper = styled.div`
display: flex;
flex-direction: column;
width: auto;
align-items: left;
margin-right: 20px;
`
export const Divider = styled.hr`
border-top: 0.5px solid #e4e4e4;
width: 100%;
padding: 0;
margin: 0;
margin-left: auto;
margin-right: auto;
`
export const FilterButton = styled.span`
padding: 10px 40px 10px 15px;
border-radius: 4px;
width: auto;
border: 1px solid #e4e4e4;
text-align: start;
color: white;
background-color: #F7F7F7;
cursor: pointer;
font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;
color: #000000;
position: relative;

&::after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='black' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 12px;
    height: 12px;
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 55%;
    transform: translateY(-55%);
}
`
export const Option = styled.span`
padding-top: 15px;
padding-bottom: 15px;
font-size: 14px;
cursor: pointer;
`
export const OptionsWrapper = styled.div`
display: flex;
margin-top: 5px;
min-width: 160px;
flex-direction: column;
padding: 0px 10px;
border: 1px solid #e4e4e4;
background-color: #f7f7f7;
color: #000;
position: absolute;
z-index:9;
top: 45px;
box-shadow: 0 4px 8px rgba(0,0,0,0.15)
`
export const OptionWrapper = styled.div`
display: flex;
flex-direction: row;
align-items: center;
width: 170px;
&:hover {
    color: gray;
}
`