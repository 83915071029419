import React from 'react';
import Header from '../components/Header/Header';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Buying from '../components/HelpCenter/Buying';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import { StickyFooterBody, StickyFooterMain } from '../sharedComponents/StickyFooter';

const HelpCenteBuying = () => {
    document.body.className = '';
    
    return (
        <StickyFooterMain>
            <StickyFooterBody>
                <Header />
                <Breadcrumb title="Buying" subpagelink="/help-center" subpage="Help Center" page="Buying" />
                <Buying />
            </StickyFooterBody>
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
        </StickyFooterMain>
    );
}

export default HelpCenteBuying;