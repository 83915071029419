
import ContentLoader from "react-content-loader"

const DetailsLoader = (props) => {
    return (
        <ContentLoader 
            speed={2}
            width={555}
            height={441}
            viewBox="0 0 555 441"
            backgroundColor="#d4d4d4"
            foregroundColor="#b0b0b0" 
            style={{width: '100%', height: 'auto'}}
            {...props}
        >
            <rect x="0" y="0" rx="2" ry="2" width="440" height="25" />
            <rect x="0" y="60" rx="2" ry="2" width="555" height="10" />
            <rect x="0" y="75" rx="2" ry="2" width="535" height="10" />
            <rect x="0" y="90" rx="2" ry="2" width="555" height="10" />
            <rect x="0" y="105" rx="2" ry="2" width="555" height="10" />
            <rect x="0" y="120" rx="2" ry="2" width="135" height="10" />
            <rect x="0" y="165" rx="2" ry="2" width="555" height="1" />
            <circle cx="25" cy="220" r="25" />
            <rect x="65" y="208" rx="2" ry="2" width="45" height="10" />
            <rect x="65" y="222" rx="2" ry="2" width="100" height="10" />
            <circle cx="300" cy="220" r="25" />
            <rect x="340" y="208" rx="2" ry="2" width="45" height="10" />
            <rect x="340" y="222" rx="2" ry="2" width="100" height="10" />
            <rect x="0" y="308" rx="2" ry="2" width="45" height="10" />
            <rect x="0" y="330" rx="2" ry="2" width="90" height="20" />
            <rect x="0" y="365" rx="2" ry="2" width="45" height="10" />
            <rect x="340" y="308" rx="2" ry="2" width="45" height="10" />
            <rect x="340" y="330" rx="2" ry="2" width="25" height="20" />
            <rect x="0" y="390" rx="25" ry="25" width="555" height="50" />
        </ContentLoader>
    )
};

export default DetailsLoader;