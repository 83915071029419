import { useCallback } from "react";
import { Link } from "react-router-dom";
import { RenderItemMedia } from "../ItemMedia";
import { TxBackdrop, TxModal } from "./styles";

const ConfirmationModal = ({item, txType, txStatus, txHash, setTxInfoCallback}) => {
    const hideModal = useCallback(() => {
        setTxInfoCallback({});
    }, [setTxInfoCallback]);

    return (
        <>
            <TxBackdrop />
            
            <TxModal>
                <div className="tx-header">
                    <div className="tx-close" onClick={hideModal}>
                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <g id="cross">
                                <line x1="7" x2="25" y1="7" y2="25" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px" />
                                <line x1="7" x2="25" y1="25" y2="7" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px"/>
                            </g>
                        </svg>
                    </div>
                </div>

                <div className="tx-body">
                    <div className="tx-media">
                        <RenderItemMedia fileURL={item.file.path} mimeType={item.file.mime_type} alt={item.name} />
                    </div>

                    <div className="tx-content">
                        <div className="tx-msg">{txType === 'purchase' ? (
                            <>
                                You purchased <strong>{item.name}</strong> from <strong>{item.owner.username}</strong> successfully.
                            </>
                        ) : txType === 'cancel-sale' ? (
                            <>
                                <strong>{item.name}</strong> sale has been cancelled successfully.
                            </>
                        ) : (
                            <>
                                <strong>{item.name}</strong> was put on sale successfully.
                            </>
                        )}</div>

                        { txStatus && txHash && (
                            <>
                                <div className="tx-status">The transaction may take a while.</div>
                                <div className="tx-status-box">
                                    <div className="tx-status-box-row">
                                        <div className="tx-status-box-col">
                                            <div className="tx-status-label">Status</div>
                                            <div className="tx-status-value">Processing</div>
                                        </div>
                                        
                                        <div className="tx-status-box-col">
                                            <div className="tx-status-label">Transaction Hash</div>
                                            <div className="tx-status-value d-flex align-items-center">
                                                <div className="txt-ellipsis">{txHash}</div>
                                                <button type="button" onClick={() => {navigator.clipboard.writeText(txHash)}}>
                                                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.5 4.0625V11.375H3.75V4.0625H10.5ZM10.5 3.25H3.75C3.55109 3.25 3.36032 3.3356 3.21967 3.48798C3.07902 3.64035 3 3.84701 3 4.0625V11.375C3 11.5905 3.07902 11.7972 3.21967 11.9495C3.36032 12.1019 3.55109 12.1875 3.75 12.1875H10.5C10.6989 12.1875 10.8897 12.1019 11.0303 11.9495C11.171 11.7972 11.25 11.5905 11.25 11.375V4.0625C11.25 3.84701 11.171 3.64035 11.0303 3.48798C10.8897 3.3356 10.6989 3.25 10.5 3.25Z" fill="black"/>
                                                        <path d="M1.5 7.3125H0.75V1.625C0.75 1.40951 0.829018 1.20285 0.96967 1.05048C1.11032 0.898102 1.30109 0.8125 1.5 0.8125H6.75V1.625H1.5V7.3125Z" fill="black"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) }

                        <button type="button" className="btn btn-block btn-bordered-black" onClick={hideModal}>Continue</button>

                        <div className="tx-profile-link">
                            <Link to="/profile">View my profile</Link>
                        </div>
                    </div>
                </div>
            </TxModal>
        </>
    )
};

export default ConfirmationModal;