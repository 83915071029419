import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BreadcrumbsLoader from '../Breadcrumb/BreadcrumbsLoader';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import TitleCenterLoader from '../Loaders/TitleCenterLoader';
import WorkElLoader from './WorkElLoader';
import TitleLeftLoader from '../Loaders/TitleLeftLoader';

let host = window.location.hostname;
if ( window.location.port !== "" ) {
    host+= ':' + window.location.port;
}

const BASE_URL = `${window.location.protocol}//${host}/work.json`;

const Work = ({showBreadcrumbs = true}) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [workData, setWorkData] = useState([]);

    useEffect(() => {
        axios.get(`${BASE_URL}`)
        .then(res => {
            setData(res.data);
            setWorkData(res.data.workData);
            setLoading(false);
        })
        .catch(err => console.log(err))
    }, []);

    if(showBreadcrumbs) {
        document.title = 'How it works | Block of Change';
    }
    
    return (
        <>
            {showBreadcrumbs && (
                loading 
                    ? <BreadcrumbsLoader /> 
                    : <Breadcrumb title="How it works" page="How it works" />
            )}
            <section className="work-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="intro mb-0">
                                <div className="intro-content">
                                    {loading ? (
                                        showBreadcrumbs 
                                            ? <TitleCenterLoader /> 
                                            : <TitleLeftLoader />
                                    ) : (
                                        <>
                                            <span>{data.preHeading}</span>
                                            <h3 className="mt-3 mb-0">{data.heading}</h3>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row items">
                        {loading ? (
                            [0,1,2,3].map((idx) => (
                            <div key={`wel-${idx}`} className="col-12 col-sm-6 col-lg-3 item">
                                    <WorkElLoader />
                                </div>
                            ))
                        ) : workData.map((item, idx) => {
                            return (
                                <div key={`wd_${idx}`} className="col-12 col-sm-6 col-lg-3 item">
                                    <div className="single-work">
                                        <i className={item.icon} style={{width: 50, height: 40}} />
                                        <h4>{item.title}</h4>
                                        <p>{item.text}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Work;