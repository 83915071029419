import styled from "styled-components";

export const ScrollToTop = styled.div`
position: fixed;
width: 40px;
height: 40px;
line-height: 40px;
text-align: center;
background-color: var(--card-bg-color);
box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
border-radius: 4px;
right: 25px;
bottom: 25px;
opacity: 0.85;
visibility: visible;
z-index: 5;
transition-timing-function: ease;
transition-duration: .4s;
transition-property: opacity, transform, visibility;

&.sthidden {
    opacity: 0;
    visibility: hidden;
}

a {
    display: block;
    height: 100%;
    width: 100%;
    line-height: 40px !important;

    svg {
        font-size: 22px;
        font-weight: 600;
        color: var(--white-color);
    }
}

&:hover {
    opacity: 1;
}

&:hover, &:focus {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
`