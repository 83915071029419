import styled from 'styled-components'

export const WrongNetworkPopUp = styled.div`
position: fixed;
z-index: 1000;
top: 0;
bottom: 0;
right: 0;
left: 0;
height: 100%;
overflow: auto;
outline: 0;
background-color: rgba(255,255,255,0.45);
display: flex;
justify-content: center;
align-items: center;
padding: 40px;
.content-wrapper {
   background-color: white;
   padding: 60px 50px 70px;
   max-width: 721px;
   height: auto;
   background: #FFFFFF;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.15);
   border-radius: 4px;
   display: flex;
   align-items: center;
   flex-direction: column;
}
.title {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 42px;
    text-align: center;
    color: #000000;
    margin: 0;
}

.text {
    padding: 0;
    margin: 0;
    margin-top: 40px;
    margin-bottom: 60px;
    font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 28px;
display: flex;
align-items: center;
text-align: center;

color: #000000;
}
.buttons-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}
.button {
    max-width: 259px;
    width: 100%;
    height: 51px;
    background: #000000;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 50px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
}
@media (max-width: 950px) {
    .content-wrapper {
        max-width: 621px;
    }
}
@media (max-width: 850px) {
    .content-wrapper {
        padding: 50px 40px 60px;
    }
    .title {
        font-size: 24px;
        line-height: 32px;
    }
    .text {
        font-size: 14px;
        line-height: 26px;
    }
    .button {
        height: 45px;
        font-size: 15px;
    }
}
@media (max-width: 700px) {
    .content-wrapper {
        max-width: 521px;
        padding: 40px 30px 50px;
    }
    .text {
        margin-top: 20px;
        margin-bottom: 40px;
    }
}
@media (max-width: 600px) {
    .title {
        font-size: 22px;
        line-height: 30px;
    }
    .text {
        font-size: 14px;
        line-height: 26px;
    }
    .button {
        font-size: 14px;
    }
}
@media (max-width: 500px) {
    .content-wrapper {
        padding: 30px 20px 40px;
    }
    .title {
        font-size: 20px;
        line-height: 28px;
    }
    .text {
        margin-top: 15px;
        margin-bottom: 20px;
        font-size: 13px;
        line-height: 24px;
    }
    .button {
        font-size: 14px;
    }
}
@media (max-width: 500px) {
    .title {
        font-size: 18px;
        line-height: 26px;
    }
    .text {
        font-size: 13px;
        line-height: 22px;
    }
    .button {
        font-size: 13px;
    }
}
@media (max-width: 500px) {
    .title {
        font-size: 16px;
        line-height: 24px;
    }
}
`
export const Divider = styled.hr`
border-top: 2px solid #bbb;
width: 110%;
margin: 40px;
@media (max-width: 500px) {
    margin: 30px;
}
@media (max-width: 450px) {
    margin: 25px;
}
@media (max-width: 320px) {
    margin: 20px;
    width: 100%;
}
`