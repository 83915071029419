import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from "react-hook-form";
import { useMoralis } from "react-moralis";
import { avatarPlaceholder, bytesToSize, maxAvatarSize, maxBannerSize, previewFile } from '../../../helpers/fileUpload';
import { validateFileSize, validateFileType } from '../../../helpers/validation';
import ReactTooltip from 'react-tooltip';
import urlSlug from 'url-slug'
import ProcessingModal from '../../Loaders/ProcessingModal/ProcessingModal';
import { useHistory, useParams } from 'react-router';
import NoRoute from '../../NoRoute/NoRoute';
import { Container, ContentLoader, Section } from '../../Elements/styles';
import { AdminBtnLink } from '../styles';
import getWindowDimensions  from '../../../helpers/getWindowDimensions';
import ToggleSwitch from '../../FormElements/ToggleSwitch/ToggleSwitch';
import { Link } from 'react-router-dom';

function AvatarWatched({ control, defaultValue }) {
    const avatar = useWatch({
        control, 
        name: "avatar", 
        defaultValue: defaultValue
    });

    return previewFile(avatar, 'Profile Avatar', `<img src=${defaultValue} alt="Profile Avatar" />`);
}

function BannerWatched({ control, defaultValue }) {
    const banner = useWatch({
        control, 
        name: "banner", 
        defaultValue: defaultValue
    });

    let defaultBlock = undefined;
    if(defaultValue) {
        defaultBlock = `<img src=${defaultValue} alt="Profile Banner" />`
    }
    
    return previewFile(banner, 'Profile Banner', defaultBlock);
}

const Form = () => {
    const { Moralis } = useMoralis();
    const params = useParams();
    const { width } = getWindowDimensions();

    document.title = params.id ? 'Edit Artist - Admin | Block of Change' : 'New Artist - Admin | Block of Change';

    const { register, handleSubmit, watch, control, reset, formState: { errors } } = useForm({
        mode: "onBlur", 
        defaultValues: {
            name: '', 
            dates: '', 
            bio: ''
        }
    });
    const history = useHistory();
    const [ isNewRecord, setIsNewRecord ] = useState();
    const [ errorLoading, setErrorLoading ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ formSuccess, setFormSuccess ] = useState(false);
    const [ formError, setFormError ] = useState(false);
    const [ formErrorMsg, setFormErrorMsg ] = useState('Unknown error, please try again later');
    const [ formSaving, setFormSaving ] = useState(false);
    const [ defaultAvatar, setDefaultAvatar ] = useState(avatarPlaceholder);
    const [ defaultBanner, setDefaultBanner ] = useState("");
    const [ isFeatured, setIsFeatured ] = useState(false);
    const avatarWatch = watch("avatar", {length: 0});
    const bannerWatch = watch("banner", {length: 0});

    const loadArtist = async (id) => {
        const response = await Moralis.Cloud.run('getArtistById', {id: `${id}`});

        if(response) {
            const data = {
                name: response.name, 
                bio: response.bio, 
                dates: response.dates
            };

            if(response.avatar !== null) {
                setDefaultAvatar(response.avatar)
            }

            if(response.banner !== null) {
                setDefaultBanner(response.banner)
            }

            setIsFeatured(response.is_featured);

            reset(data);
            setLoading(false);
        } else {
            console.log('Error Loading');
            setLoading(false);
            setErrorLoading(true);
        }
    };

    useEffect(() => {
        let newRecord = true;
        if(params.id) {
            newRecord = false;
        }
        setIsNewRecord(newRecord);

        if(newRecord) {
            setLoading(false)
        } else {
            loadArtist(params.id);
        }
    }, [params]);

    const onSubmit = async(data) => {
        setFormError(false);
        setFormErrorMsg('Unknown error, please try again later');
        setFormSaving(true);

        const artistObj = Moralis.Object.extend("Artist");
        const artist = isNewRecord 
            ? new artistObj() 
            : artistObj.createWithoutData(params.id);

        artist.set('name', data.name);
        artist.set('slug', urlSlug(data.name));
        artist.set('dates', data.dates);
        artist.set('bio', data.bio);
        artist.set('is_featured', isFeatured);
        artist.set('is_active', true);

        if(data.avatar.length > 0) {
            const avatar = new Moralis.File("avatar.jpg", data.avatar[0]);
            const avatarFile = await avatar.save();
            artist.set('avatar', avatarFile);
        }
        
        if(data.banner.length > 0) {
            const banner = new Moralis.File("banner.jpg", data.banner[0]);
            const bannerFile = await banner.save();
            artist.set('banner', bannerFile);
        }

        try {
            await artist.save();
            setFormSaving(false); 
            setFormSuccess(true);

            setTimeout(() => {
                history.push(`/admin/artists`)
            }, 2000);
        } catch(error) {
            setFormSaving(false); 
            setFormError(true);
            setFormErrorMsg(error.message);
        }
    };

    if(loading) {
        return <ContentLoader />
    }

    if(errorLoading) {
        return <NoRoute />
    }

    return (
        <>
            {width < 768 ? '' : (
                <section className="breadcrumb-area d-flex align-items-center">
                    <div className="container" style={{paddingTop: 0 }}>
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb-content text-center">
                                    <ol className="breadcrumb d-flex justify-content-start">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/admin">Admin</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/admin/artists">Artists</Link>
                                        </li>
                                        <li className="breadcrumb-item active txt-ellipsis">{ params.id ? 'Edit' : 'Add' }</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}

            {formSaving && (
                <ProcessingModal />
            )}
            <Section className="author-area profile-area">
                <Container>
                    <div className="row align-items-center">
                        <div className="col-12 col-md-7 offset-md-1 col-lg-4 offset-lg-2 col-xl-4 offset-xl-2">
                            <div className="intro mb-4">
                                <h3 className="mt-3 mb-0">{ params.id ? 'Edit Artist' : 'Add Artist' }</h3>
                            </div>
                        </div>

                        <div className="col-12 col-md-3 col-lg-4 col-xl-4 text-md-right">
                            <AdminBtnLink to="/admin/artists" className="btn-xs-block mb-4">Return</AdminBtnLink>
                        </div>
                    </div>
                </Container>

                {formError && (
                    <div className="row justify-content-xl-center">
                        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-5 offset-xl-0">
                            <div class="alert alert-danger text-center" role="alert">
                                {formErrorMsg}
                            </div>
                        </div>
                    </div>
                )}

                <div className="row justify-content-xl-center">
                    <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-5 offset-xl-0">
                        <form className="item-form card no-hover row" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                            <div className="col-12">
                                    <div className="bv py-3 mb-3">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1 label-like">Is Featured <span data-tip data-for="isFeaturedTooltip"><i className="fas fa-info"></i></span></div>
                                            <ToggleSwitch
                                                name="is_featured"
                                                id="is_featured"
                                                checked={isFeatured}
                                                small={true}
                                                onChange={() => {
                                                    setIsFeatured(!isFeatured)
                                                }}
                                            />
                                        </div>
                                        <ReactTooltip id="isFeaturedTooltip">
                                            <div>Setting the artist as Featured to show up in homepage.</div>
                                        </ReactTooltip>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="form-group mt-3">
                                        <label className="control-label required" htmlFor="name">Name</label>
                                        <input type="text" className={errors.name ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Name" maxLength={256} {...register("name", { required: true })} />
                                        {errors.name ? (
                                            <div className="invalid-feedback">Required Field.</div>
                                        ) : ('')}
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="form-group mt-3">
                                        <label className="control-label" htmlFor="name">Dates</label>
                                        <input type="text" className="form-control" name="dates" id="dates" placeholder="Dates" maxLength={11} {...register("dates", { maxLength: 11 })} />
                                        {errors.dates ? (
                                            <div className="invalid-feedback">Max Length is 11 characters. Ex.: 1904-1989</div>
                                        ) : ('')}
                                    </div>
                                </div>
                                
                                <div className="col-12">
                                    <div className="form-group mt-3">
                                        <label className="control-label required" htmlFor="bio">Bio</label>
                                        <textarea className={errors.bio ? "form-control is-invalid" : "form-control"} maxLength={4000} name="bio" id="bio" rows={7} placeholder="Tell something about the artist" {...register("bio", { required: true })} />
                                        {errors.bio ? (
                                            <div className="invalid-feedback">Required Field.</div>
                                        ) : ('')}
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label mhauto">Avatar <span data-tip data-for="avatarTooltip"><i className="fas fa-info"></i></span>
                                            <ReactTooltip id="avatarTooltip">
                                                <div>Recomended 310x310px</div>
                                                <div>Max size: {bytesToSize(maxAvatarSize)}</div>
                                            </ReactTooltip>
                                        </label>
                                        <div className={`fileUploadPreviewWrapper${defaultAvatar === avatarPlaceholder && (!avatarWatch || avatarWatch.length === 0) ? ' empty' : ''}`}>
                                            <div className="fileUploadPreview">
                                                <AvatarWatched control={control} defaultValue={defaultAvatar} />
                                            </div>
                                            <div className="fileUploadPreviewInput">
                                                <input type="file" name="avatar" id="avatarFile" accept=".jpg, .jpeg, .png, .gif" {...register("avatar", {
                                                    required: isNewRecord,
                                                    fileSize: files => validateFileSize(files[0]?.size, maxAvatarSize) || 'Max ' + bytesToSize(maxAvatarSize),
                                                    acceptedFormats: files =>
                                                    validateFileType(files[0]?.type, ['image/jpeg', 'image/png', 'image/gif']) || 'Only PNG, JPEG e GIF'
                                                })} style={{ display: "none" }} />
                                                <label htmlFor="avatarFile" className="fileUploadPreviewOverlay">
                                                    <div className="fileUploadPreviewOverlayInner">
                                                        Upload file
                                                        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M32 2.75C32.5967 2.75 33.169 2.98705 33.591 3.40901C34.0129 3.83097 34.25 4.40326 34.25 5V32C34.25 32.5967 34.0129 33.169 33.591 33.591C33.169 34.0129 32.5967 34.25 32 34.25H5C4.40326 34.25 3.83097 34.0129 3.40901 33.591C2.98705 33.169 2.75 32.5967 2.75 32V5C2.75 4.40326 2.98705 3.83097 3.40901 3.40901C3.83097 2.98705 4.40326 2.75 5 2.75H32ZM5 0.5C3.80653 0.5 2.66193 0.974106 1.81802 1.81802C0.974106 2.66193 0.5 3.80653 0.5 5L0.5 32C0.5 33.1935 0.974106 34.3381 1.81802 35.182C2.66193 36.0259 3.80653 36.5 5 36.5H32C33.1935 36.5 34.3381 36.0259 35.182 35.182C36.0259 34.3381 36.5 33.1935 36.5 32V5C36.5 3.80653 36.0259 2.66193 35.182 1.81802C34.3381 0.974106 33.1935 0.5 32 0.5L5 0.5Z" fill="#D8D8D8"/>
                                                            <path d="M18.5 9.5C18.7984 9.5 19.0845 9.61853 19.2955 9.82951C19.5065 10.0405 19.625 10.3266 19.625 10.625V17.375H26.375C26.6734 17.375 26.9595 17.4935 27.1705 17.7045C27.3815 17.9155 27.5 18.2016 27.5 18.5C27.5 18.7984 27.3815 19.0845 27.1705 19.2955C26.9595 19.5065 26.6734 19.625 26.375 19.625H19.625V26.375C19.625 26.6734 19.5065 26.9595 19.2955 27.1705C19.0845 27.3815 18.7984 27.5 18.5 27.5C18.2016 27.5 17.9155 27.3815 17.7045 27.1705C17.4935 26.9595 17.375 26.6734 17.375 26.375V19.625H10.625C10.3266 19.625 10.0405 19.5065 9.82951 19.2955C9.61853 19.0845 9.5 18.7984 9.5 18.5C9.5 18.2016 9.61853 17.9155 9.82951 17.7045C10.0405 17.4935 10.3266 17.375 10.625 17.375H17.375V10.625C17.375 10.3266 17.4935 10.0405 17.7045 9.82951C17.9155 9.61853 18.2016 9.5 18.5 9.5Z" fill="#D8D8D8"/>
                                                        </svg>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        {errors.avatar ? (
                                            <div className="invalid-feedback mw-310 mhauto">
                                                { errors.avatar?.type === "required" && <div>Required Field.</div> }
                                                { errors.avatar?.type === "fileSize" && (
                                                    <div>Max {bytesToSize(maxAvatarSize)}</div>
                                                )}
                                                { errors.avatar?.type === "acceptedFormats" && <div>Only PNG, JPEG and GIF.</div> }
                                            </div>
                                        ) : ('')}                                
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label mhauto">Banner <span data-tip data-for="bannerTooltip"><i className="fas fa-info"></i></span>
                                            <ReactTooltip id="bannerTooltip">
                                                <div>Recomended 1400x400px</div>
                                                <div>Max size: {bytesToSize(maxBannerSize)}</div>
                                            </ReactTooltip>
                                        </label>

                                        <div className={`fileUploadPreviewWrapper${defaultBanner === "" && (!bannerWatch || bannerWatch.length === 0) ? ' empty' : ''}`}>
                                            <div className="fileUploadPreview --img-of-contain">
                                                <BannerWatched control={control} defaultValue={defaultBanner} />
                                            </div>
                                            <div className="fileUploadPreviewInput">
                                                <input type="file" name="banner" id="bannerFile" accept=".jpg, .jpeg, .png" {...register("banner", {
                                                    required: isNewRecord,
                                                    fileSize: files => validateFileSize(files[0]?.size, maxBannerSize) || 'Max ' + bytesToSize(maxBannerSize),
                                                    acceptedFormats: files => 
                                                    validateFileType(files[0]?.type, ['image/jpeg', 'image/png']) || 'Only PNG, JPEG e GIF'
                                                })} style={{ display: "none" }} />
                                                <label htmlFor="bannerFile" className="fileUploadPreviewOverlay">
                                                    <div className="fileUploadPreviewOverlayInner">
                                                        Upload file
                                                        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M32 2.75C32.5967 2.75 33.169 2.98705 33.591 3.40901C34.0129 3.83097 34.25 4.40326 34.25 5V32C34.25 32.5967 34.0129 33.169 33.591 33.591C33.169 34.0129 32.5967 34.25 32 34.25H5C4.40326 34.25 3.83097 34.0129 3.40901 33.591C2.98705 33.169 2.75 32.5967 2.75 32V5C2.75 4.40326 2.98705 3.83097 3.40901 3.40901C3.83097 2.98705 4.40326 2.75 5 2.75H32ZM5 0.5C3.80653 0.5 2.66193 0.974106 1.81802 1.81802C0.974106 2.66193 0.5 3.80653 0.5 5L0.5 32C0.5 33.1935 0.974106 34.3381 1.81802 35.182C2.66193 36.0259 3.80653 36.5 5 36.5H32C33.1935 36.5 34.3381 36.0259 35.182 35.182C36.0259 34.3381 36.5 33.1935 36.5 32V5C36.5 3.80653 36.0259 2.66193 35.182 1.81802C34.3381 0.974106 33.1935 0.5 32 0.5L5 0.5Z" fill="#D8D8D8"/>
                                                            <path d="M18.5 9.5C18.7984 9.5 19.0845 9.61853 19.2955 9.82951C19.5065 10.0405 19.625 10.3266 19.625 10.625V17.375H26.375C26.6734 17.375 26.9595 17.4935 27.1705 17.7045C27.3815 17.9155 27.5 18.2016 27.5 18.5C27.5 18.7984 27.3815 19.0845 27.1705 19.2955C26.9595 19.5065 26.6734 19.625 26.375 19.625H19.625V26.375C19.625 26.6734 19.5065 26.9595 19.2955 27.1705C19.0845 27.3815 18.7984 27.5 18.5 27.5C18.2016 27.5 17.9155 27.3815 17.7045 27.1705C17.4935 26.9595 17.375 26.6734 17.375 26.375V19.625H10.625C10.3266 19.625 10.0405 19.5065 9.82951 19.2955C9.61853 19.0845 9.5 18.7984 9.5 18.5C9.5 18.2016 9.61853 17.9155 9.82951 17.7045C10.0405 17.4935 10.3266 17.375 10.625 17.375H17.375V10.625C17.375 10.3266 17.4935 10.0405 17.7045 9.82951C17.9155 9.61853 18.2016 9.5 18.5 9.5Z" fill="#D8D8D8"/>
                                                        </svg>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        {errors.banner ? (
                                            <div className="invalid-feedback mw-310 mhauto">
                                                { errors.banner?.type === "required" && <div>Required Field.</div> }
                                                { errors.banner?.type === "fileSize" && (
                                                    <div>Max {bytesToSize(maxBannerSize)}</div>
                                                )}
                                                { errors.banner?.type === "acceptedFormats" && <div>Only PNG and JPG.</div> }
                                            </div>
                                        ) : ('')}
                                    </div>
                                </div>

                                <div className="col-12">
                                    <button className="btn btn-block btn-bordered-black rounded mt-3 mt-sm-4" type="submit"{...formSaving ? ' disabled' : ''}>{
                                        formSaving 
                                        ? 'Saving...' 
                                        : (
                                            formSuccess 
                                            ? (
                                                isNewRecord 
                                                ? 'The Artist has been created! Redirecting...' 
                                                : 'The Artist has been updated! Redirecting...' 
                                            ) : 'Save'
                                        )
                                    }</button>
                                </div>

                                {formError && (
                                    <div className="col-12 mt-3 mt-sm-4">
                                        <div class="alert alert-danger text-center" role="alert">
                                            {formErrorMsg}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </Section>
        </>
    );
}

export default Form;