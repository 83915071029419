import React from "react"
import { createContext, useContext, useState } from 'react'

export const InfoContext = createContext({})

export const InfoProvider = ({ children }) => {
    const [wrongNetwork, setWrongNetwork] = useState(false)

    const changeWrongNetwork = (value) => {
        setWrongNetwork(value)
    }

    return (
        <InfoContext.Provider value={{wrongNetwork, changeWrongNetwork}}>
            {children}
        </InfoContext.Provider>
    )
}

export const useInfo = () => useContext(InfoContext)
