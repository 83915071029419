import styled from 'styled-components'

export const PageLoader = styled.div`
background: black;
width: 100%;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
min-height: 100vh;
min-height: calc(var(--vh,1vh) * 100);
align-items: center;
justify-content: center;

img, svg {
    max-width: 100px;
    height: auto;
    margin-bottom: 15px;
}
`