import React from "react";
import {ArtistInfoWrapper, TextWrapper, SocialNetworks, ProfileEthAddressWrapper, ProfileEthAddress, ProfileEthAddressBtn } from '../../../Artist/styles'
import { markdownText } from "../../../../helpers/markdownHelper";
import getWindowDimensions  from '../../../../helpers/getWindowDimensions'

const AuthorInfo = ({artist}) => {
    const showEthAddress = false;
    const { width } = getWindowDimensions()

    return (
        <>
           <ArtistInfoWrapper style={{justifyContent: width <= 350 ? 'center' : ''}}>
                <div className="avatar">
                    <img src={artist.avatar} alt={artist.username} />
                </div>
                {width > 350 && (
                    <TextWrapper>
                        {artist.dates && <p className="date">{artist.dates}</p>}
                        {/* {<p className="date">{artist.dates ? artist.dates : (artist.isArtist ? 'Artist' : 'Collector')}</p>} */}
                        <p className="name">{artist.username}</p>

                        { showEthAddress && document.location.pathname === '/profile' && artist.ethAddress && (
                            <ProfileEthAddressWrapper>
                                <ProfileEthAddress>{artist.ethAddress}</ProfileEthAddress>
                                <ProfileEthAddressBtn type="button" onClick={() => {
                                    navigator.clipboard.writeText(artist.ethAddress);
                                    alert('Copied')
                                }}>
                                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.5 4.0625V11.375H3.75V4.0625H10.5ZM10.5 3.25H3.75C3.55109 3.25 3.36032 3.3356 3.21967 3.48798C3.07902 3.64035 3 3.84701 3 4.0625V11.375C3 11.5905 3.07902 11.7972 3.21967 11.9495C3.36032 12.1019 3.55109 12.1875 3.75 12.1875H10.5C10.6989 12.1875 10.8897 12.1019 11.0303 11.9495C11.171 11.7972 11.25 11.5905 11.25 11.375V4.0625C11.25 3.84701 11.171 3.64035 11.0303 3.48798C10.8897 3.3356 10.6989 3.25 10.5 3.25Z" fill="black"/>
                                        <path d="M1.5 7.3125H0.75V1.625C0.75 1.40951 0.829018 1.20285 0.96967 1.05048C1.11032 0.898102 1.30109 0.8125 1.5 0.8125H6.75V1.625H1.5V7.3125Z" fill="black"/>
                                    </svg>
                                </ProfileEthAddressBtn>
                            </ProfileEthAddressWrapper>
                        ) }

                        {width <= 550 ? ( 
                            <SocialNetworks>
                                {artist.socialData.length > 0 && (
                                    <div>
                                        {artist.socialData.map((item, idx) => {
                                            return (
                                                <a key={`asd_${idx}`} href={item.link} target="_blank" rel="nofollow noreferrer">
                                                    {item.network != 'website' ? (
                                                        <i className={`fab fa-${item.network}`} style={{width: 34, height: 28,  color: 'black', marginRight: 5 }}></i>
                                                    ) : (
                                                        <img src="/img/website.svg" alt="website" style={{width: 34, height: 28, color: 'black', marginRight: 5 }} />
                                                    )}
                                                </a>
                                            )
                                        })}
                                    
                                    </div>
                                )}
                            </SocialNetworks>
                        ) : ( 
                            <>
                                { artist.bio && <div className="bio">{markdownText(artist.bio)}</div> }
                                        
                                <SocialNetworks>
                                    {artist.socialData.length > 0 && (
                                        <div>
                                            {artist.socialData.map((item, idx) => {
                                                return (
                                                    <a key={`asd_${idx}`} href={item.link} target="_blank" rel="nofollow noreferrer">
                                                        {item.network === 'website' ? (
                                                            <img src="/img/website.svg" alt="website" style={{width: 34, height: 28, color: 'black', marginRight: 5}} />
                                                        ) : (
                                                            <i className={`fab fa-${item.network}`} style={{width: 34, height: 28, color: 'black', marginRight: 5}}></i>
                                                        )}
                                                    </a>
                                                );
                                            })}
                                        </div>
                                    )}
                                </SocialNetworks>
                            </>
                        )}
                    </TextWrapper>
                )}
            </ArtistInfoWrapper>
            
            { width > 350 && width <= 550 && artist.bio && <div className="bio">{markdownText(artist.bio)}</div> }
            
            {width <= 350 && (
                <>
                    <ArtistInfoWrapper style={{marginTop: 30}}>
                        <TextWrapper style={{marginLeft: 0}}>
                            {artist.dates && <p className="date">{artist.dates}</p>}
                            {/* {<p className="date">{artist.dates ? artist.dates : (artist.isArtist ? 'Artist' : 'Collector')}</p>} */}
                            <p className="name">{artist.username}</p>
                            <SocialNetworks>
                                {artist.socialData.length > 0 && (
                                    <div>
                                        {artist.socialData.map((item, idx) => {
                                            return (
                                                <a key={`asd_${idx}`} href={item.link} target="_blank" rel="nofollow noreferrer">
                                                    {item.network === 'website' ? (
                                                        <img src="/img/website.svg" alt="website" style={{width: 21, height: 21, color: 'black', marginRight: 17}} />
                                                    ) : (
                                                        <i className={`fab fa-${item.network}`} style={{width: 21, height: 21, color: 'black', marginRight: 17}}></i>
                                                    )}
                                                </a>
                                            );
                                        })}
                                    </div>
                                )}
                            </SocialNetworks>                                   
                        </TextWrapper>
                    </ArtistInfoWrapper>
                    {artist.bio && <div className="bio">{markdownText(artist.bio)}</div>}
                </>
            )}
        </>
               
    )
};

export default AuthorInfo;