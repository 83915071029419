import { getAvatarPlaceholder, bannerPlaceholder } from "./fileUpload";

export const getAuthorInterface = (user) => {
    const author = {
        ethAddress: user.get('ethAddress'),
        username: user.attributes.username, 
        email: '', 
        bio: '', 
        avatar: getAvatarPlaceholder(), 
        banner: bannerPlaceholder,
        twitterhandler: '', 
        instagramhandler: '', 
        facebookurl: '', 
        websiteurl: '', 
        socialData: [], 
        is_artist: false,
        isArtist: false
    };

    const userEmail = user.get('email');
    if(userEmail) {
        author.email = userEmail;
    }

    try {
        const userAvatar = user.get('avatar').url();
        if(userAvatar) {
            author.avatar = userAvatar;
        }
    } catch(error) {
        console.log(error);
    }

    try {
        const userBanner = user.get('banner').url();
        if(userBanner) {
            author.banner = userBanner;
        }
    } catch(error) {
        console.log(error);
    }

    const userBio = user.get('bio');
    if(userBio) {
        author.bio = userBio;
    }
    
    const isArtist = user.get('is_artist');
    if(isArtist) {
        author.is_artist = isArtist;
        author.isArtist = isArtist;
    }

    const userTwitterHandler = user.get('twitterhandler');
    if(userTwitterHandler) {
        author.twitterhandler = userTwitterHandler;
        const newTwitterHandler = userTwitterHandler.replace('@', '');
        author.socialData.push({
            network: 'twitter',
            link: 'https://twitter.com/' + newTwitterHandler, 
            icon: 'fab fa-twitter'
        });
    }

    const userInstagramHandler = user.get('instagramhandler');
    if(userInstagramHandler) {
        author.instagramhandler = userInstagramHandler;
        const newInstagramHandler = userTwitterHandler.replace('@', '');
        author.socialData.push({
            network: 'instagram',
            link: 'https://www.instagram.com/' + newInstagramHandler + '/?hl=en', 
            icon: 'fab fa-instagram'
        });
    }

    const userFacebookURL = user.get('facebookurl');
    if(userFacebookURL) {
        author.facebookurl = userFacebookURL;
        author.socialData.push({
            network: 'facebook',
            link: userFacebookURL, 
            icon: 'fab fa-facebook-f'
        });
    }

    const userWebsiteURL = user.get('websiteurl');
    if(userWebsiteURL) {
        author.websiteurl = userWebsiteURL;
        author.socialData.push({
            network: 'website',
            link: userWebsiteURL, 
            icon: 'fas fa-tablet-alt'
        });
    }

    return author;
};