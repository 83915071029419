import styled from "styled-components";

export const Pagination = styled.ul`
display: -ms-flexbox;
display: flex;
padding-left: 0;
list-style: none;
border-radius: 0.25rem;

li {
    margin: 0.5rem 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;

    button, a {
        position: relative;
        display: block;
        padding: 0.5rem 0.75rem;
        margin-left: -1px;
        line-height: 1.25;
        color: #000000;
        background-color: #fff;
        border: 1px solid #dee2e6;

        &:focus {
            outline: 0;
        }

        &:hover {
            background-color: #dee2e6;
        }
    }

    &:first-child {
        button, a {
            margin-left: 0;
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
        }
    }

    &:last-child {
        button, a {
            border-top-right-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
        }
    }

    &.active {
        button, a {
            z-index: 1;
            color: #fff;
            background-color: var(--fire-red);
            border-color: var(--fire-red);
        }
    }

    &.disabled {
        button, a {
            color: #6c757d;
            pointer-events: none;
            cursor: auto;
            background-color: #fff;
            border-color: #dee2e6;
        }
    }
}
`