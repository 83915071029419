import styled, { css } from 'styled-components'

export const ContentLoader = styled.div` 
    padding: 300px 15px 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    font-size: 20px;
    font-weight: 700;
    color: black;
`

export const Section = styled.section`
    width: 100%;
    padding: 0;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
`

export const Container = styled.div`
    width: 100%;
    padding: 0;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 576px) {
        max-width: 540px;
    }

    @media (min-width: 768px) {
        max-width: 720px;
    }

    @media (min-width: 992px) {
        max-width: 960px;
    }

    @media (min-width: 1200px) {
        max-width: 1170px;
    }
`

const RowStyle = css`
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0;
`

export const Row = styled.div`
    ${RowStyle}
`

export const RowJustifyContentCenter = styled.div`
    ${RowStyle}
    -ms-flex-pack: center!important;
    justify-content: center!important;
`

export const RowJustifyContentBetween = styled.div`
    ${RowStyle}
    -ms-flex-pack: space-between!important;
    justify-content: space-between!important;
`

export const Intro = styled.div`
    margin-bottom: 60px;
    text-align: center;
`

export const IntroRed = styled.span`
    color: var(--fire-red);
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    font-weight: var(--normal);
    font-size: 14px;
    line-height: 10px;
    letter-spacing: 0.2em;

    @media (max-width: 767px) {
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 1px;
    }
`

export const IntroTitle = styled.h1`
    margin: 1.5rem 0;
    font-weight: var(--bold);
    font-size: 40px;
    line-height: 53px;
    color: var(--black-color);
    -ms-word-wrap: break-word;
    word-wrap: break-word;

    @media (max-width: 767px) {
        margin: 8px 0;
        font-weight: 600;
        font-size: 30px;
        line-height: 40px;
    }
`

export const IntroSubTitle = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #3A3A3A;

    a {
        font-size: 14px !important;
        line-height: 22px !important;
        color: #3A3A3A !important;
        text-decoration: underline !important;
    }

    a:hover {
        color: var(--fire-red) !important;
    }
`

export const WalletItemCol = styled.div`
    position: relative;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 30px;

    @media (min-width: 768px) {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    @media (min-width: 992px) {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
`

const WalletItemCardStyle = css`
position: relative;
display: -ms-flexbox;
display: flex;
-ms-flex-direction: column;
flex-direction: column;
min-width: 0;
word-wrap: break-word;
border: none;
background-color: var(--white-color);
transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
transition-duration: 0.3s;
transition-property: opacity, transform;
overflow: hidden;
box-shadow: 0px 0px 2px rgb(0 0 0 / 15%), 0px 2px 2px rgb(0 0 0 / 5%);
cursor: pointer;

&:hover {
    transform: translateY(-5px);
}
`

export const WalletItemCardDiv = styled.div`
${WalletItemCardStyle}
`

export const WalletItemCardLink = styled.a`
${WalletItemCardStyle}
`

export const WalletItemCardInner = styled.div`
display: block;
text-align: center;
padding: 40px 24px;
`

export const WalletItemCardLogo = styled.img`
max-width: 100%;
height: 40px;
width: 40px;
vertical-align: middle;
`

export const WalletItemCardName = styled.h2`
    font-family: var(--primary-font);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0;
    color: #000;
`

export const WalletItemCardDescription = styled.p`
margin-top: 16px;
margin-bottom: 0;
font-family: var(--seconday-font);
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px;
letter-spacing: 0;
color: #3A3A3A;
`

export const ListUnstyled = styled.ul`
padding-left: 0;
list-style: none;
`