import { Pagination } from "./styles";
import { getPaginationGroup } from './helper';

const PaginationAjax = ({ currentPage, pages, pageLimit, parentMethod }) => {
    const callbackFunction = (page) => {
        parentMethod(page);
    }

    if(pages < 2) {
        return ('');
    }

    return (
        <Pagination>
            <li className={`${currentPage === 1 ? 'disabled' : ''}`}>
                <button type="button" aria-label="Previous" onClick={() => callbackFunction((currentPage - 1))}>
                    <span aria-hidden="true">&laquo;</span>
                </button>
            </li>
            
            {getPaginationGroup(currentPage, pages, pageLimit).map((item, index) => (
                item === "ellipsis" ? (
                    <li key={`pagination-${index}`}>
                        <button type="button">...</button>
                    </li>
                ) : (
                    <li className={`${currentPage === item ? 'active' : ''}`} key={`pagination-${index}`}>
                        <button type="button" onClick={() => callbackFunction(item)}>{item}</button>
                    </li>
                )
            ))}

            <li className={`${currentPage === pages ? 'disabled' : ''}`}>
                <button type="button" aria-label="Next" onClick={() => callbackFunction(pages)}>
                    <span aria-hidden="true">&raquo;</span>
                </button>
            </li>
        </Pagination>
    )
};

export default PaginationAjax;