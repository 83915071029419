export const SelectCustomStyle = {
    constainer: (provided) => ({
        ...provided, 
    }),

    control: (provided) => ({
        ...provided, 
        borderRadius: 0, 
        borderColor: '#8080806e', 
        boxShadow:  'none', 
        '&:hover': {
            borderColor: '#8080806e', 
            boxShadow:  'none', 
        }
    }),

    valueContainer: (provided) => ({
        ...provided,
        padding: '10px 15px'
    }),

    indicatorSeparator: () => ({}),

    placeholder: (provided) => ({
        ...provided, 
        color: "rgba(0, 0, 0, 0.5)", 
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '22px'
    }),

    singleValue: (provided) => ({
        ...provided, 
        color: "#000", 
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '22px'
    }),

    input: (provided) => ({
        ...provided, 
        color: "#000", 
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '22px'
    }),

    option: (provided) => ({
        ...provided, 
        cursor: "pointer"
    }), 

    menuPortal: (provided) => ({
        ...provided, 
        zIndex: 9999
    })
};