import React, { useEffect, useState } from 'react';
import { useMoralis } from "react-moralis";
import { useParams } from 'react-router';
import { artistURL, itemURL, userURL } from '../../helpers/entityURLs';
import { getAvatarPlaceholder } from '../../helpers/fileUpload';
import { markdownText } from '../../helpers/markdownHelper';
import { 
    calculateValueToReceive, 
    calculateValueToReceiveWithoutRoyalties, 
    formatWeiToEth, 
    genVoucher, 
    getCryptoRates, 
    getCurrencyFormatter, 
    getPriceInCurrency, 
    getServiceFeeValueText, 
    showPriceInCurrency
} from '../../helpers/nftSettings';
import NoRoute from '../NoRoute/NoRoute';
import { RenderItemMedia } from './ItemMedia';
import marketplaceContract from "../../contracts/marketplaceContract.json";
import { useForm } from 'react-hook-form';
import ProcessingModal from '../Loaders/ProcessingModal/ProcessingModal';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import BreadcrumbsLoader from '../Breadcrumb/BreadcrumbsLoader';
import Explore from '../Explore/ExploreOne';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import { v4 as uuidv4 } from 'uuid';
import { Section, Container, RowJustifyContentBetween, ListUnstyled } from '../Elements/styles'
import { 
    ListItemLabel, 
    ListItemPrice, 
    ListItemPriceAndRoyalties, 
    ListItemPriceAndRoyaltiesCol, 
    ListItemValue, 
    ProductArtist, 
    ProductColLeft, 
    ProductColRight, 
    ProductContent, 
    ProductDescription, 
    ProductInfoList, 
    ProductName, 
    ProductNFTCerticate, 
    ProductNFTSeller, 
    ProductNFTSellerAvatar, 
    ProductNFTSellerAvatarImg, 
    ProductNFTSellerInfo, 
    ProductNFTSellerInfoName, 
    ProductNFTSellerInfoTitle, 
    ProductNFTSellers, 
    ProductNFTSellersCard, 
    ProductNFTSellersCol, 
    ProductNFTSellersSplitter, 
    ProductNFTSellersSplitterLine, 
    ProductPaddingH, 
    ProductThumb, 
    PutOnSaleControlLabelRequired, 
    PutOnSaleForm,
    PutOnSaleFormBox
} from './styles';
import MediaLoader from './Loaders/MediaLoader';
import DetailsLoader from './Loaders/DetailsLoader';
import { AlertDanger, AlertWarning } from '../Elements/Alerts';
import { BtnBlackButtonBlock, BtnBlackLinkBlock, BtnWhiteButtonBlock } from '../Elements/Buttons';
import { FormGroup } from '../Elements/Forms';
import TabsLoader from './Loaders/TabsLoader';
import getWindowDimensions  from '../../helpers/getWindowDimensions';
import ItemTabs from './Tabs/ItemTabs';
import { useCurrency } from '../../providers/currencyContext';
/* import { Helmet } from "react-helmet";
import reactImageSize from 'react-image-size'; */

const ItemDetailsNew = () => {
    const { selectedCurrency } = useCurrency();

    const { width } = getWindowDimensions();
    const { user, isAuthenticated, Moralis, web3 } = useMoralis();
    const { address, abi } = marketplaceContract;
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState(params);
    const [item, setItem] = useState();
    const [lazyMintItem, setLazyMintNFT] = useState();
    const [currencyValue, setCurrencyValue] = useState(0);
    const [formSaving, setFormSaving] = useState(false);
    const [txError, setTxError] = useState({error: false, msg: ''});
    const [processing, setProcessing] = useState(false);
    const [txInfo, setTxInfo] = useState({});
    const [maxFees, setMaxFees] = useState(0);
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm({
        mode: "onBlur", 
        defaultValues: {
            price: 0
        }
    });
    const priceWatch = watch("price", 0);
    const formatter = getCurrencyFormatter('en-US', selectedCurrency.code);

    const throwError = (msg, code) => {
        if(code == 4001) {
            setProcessing(false);
        }

        setTxError({
            error: true, 
            msg: msg
        });
    };

    document.title = `Loading | Block of Change`;

    const resetTxError = () => setTxError({});

    const getMaxFees = async () => {
        const contract = new web3.eth.Contract(abi, address);
        await contract.methods.getComissionShare()
            .call()
            .then((response) => setMaxFees((response/100)/100))
            .catch((err) => setMaxFees(0));
    };

    const getItemHistory = async (tokenId, tokenAddress) => {
        const result = await Moralis.Cloud.run('getNFTTransactionHistory', {
            network: 'Eth',
            token_id: tokenId, 
            token_address: tokenAddress
        });

        /* const result2 = await Moralis.Cloud.run('getNFTTransactionHistory2', {
            token_id: tokenId, 
            token_address: tokenAddress
        });

        if(result2) {
            const result3 = result2.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.createdAt) - new Date(a.createdAt);
            });
            
            return result3;
        } */

        if(result) {
            return result;
        }

        return {};
    };

    const getItem = async () => {
        const requestParams = {};        
        if(query.id) {
            requestParams.uid = `${query.id}`
        } else if(query.token_address && query.token_id) {
            requestParams.tokenAddress = `${query.token_address}`
            requestParams.tokenId = `${query.token_id}`
        }
                    
        const result = await Moralis.Cloud.run('getItem', requestParams);
        if(result && Object.keys(result).length > 0) {
            if(typeof result.owner.avatar === "undefined" || result?.owner?.avatar === null) {
                result.owner.avatar = getAvatarPlaceholder();
            }
            
            if(typeof result.creator.avatar === "undefined" || result?.creator?.avatar === null) {
                result.creator.avatar = getAvatarPlaceholder();
            }

            /* try {
                const { width, height } = await reactImageSize(result.file.path);
                
                result.file.width = width;
                result.file.height = height;
            } catch {
                result.file.width = null;
                result.file.height = null;
            } */

            result.process = {
                processing: false,
                msg: ''
            }

            const txHistory = await getItemHistory(result.token_id, result.token.token_address);
            const history = [{
                type: "created",
                createdAt: result.createdAt, 
                from_address: result.owner.ethAddress, 
                receiver: {
                    avatar: result.creator.avatar, 
                    slug: result.creator.slug, 
                    username: result.creator.username
                }
            }];

            
            result.history = txHistory.concat(history);

            result.creatorIsUser = false;
            result.ownerIsUser = false;
            if (user && user.attributes.accounts.includes(result.owner.address)) {
                result.ownerIsUser = true;
            }

            if (user && user.attributes.accounts.includes(result.creator.address)) {
                result.creatorIsUser = true;
            }

            if(result.mint_processing) {
                result.process.processing = true;
                result.process.msg = 'Minting...';
            }
            
            if(result.sale_processing) {
                result.process.processing = true;
                result.process.msg = 'Sale Pending...';
            }

            if(result.put_on_sale_processing) {
                result.process.processing = true;
                result.process.msg = 'Put On Sale Pending...';
            }

            if(result.cancel_sale_processing) {
                result.process.processing = true;
                result.process.msg = 'Sale Canceling Pending...';
            }

            if(result.mint_processing) {
                result.process.processing = true;
                result.process.msg = 'Minting...';
            }
            
            if(result.sale_processing) {
                result.process.processing = true;
                result.process.msg = 'Sale Pending...';
            }

            if(result.burn_processing) {
                result.process.processing = true;
                result.process.msg = 'Burn Pending...';
            }

            document.body.classList = 'item-page';
            setItem(result);
        }
        
        setLoading(false);
    };

    const cancelSale = async () => {
        resetTxError();
        setProcessing(true);
        const contract = new web3.eth.Contract(abi, address);
        await contract.methods
            .cancelSale(item.token_id)
            .send({from: window.ethereum.selectedAddress})
            .then((transaction) => {
                const NFTObjExt = Moralis.Object.extend("NFTs");
                const NFTObj = NFTObjExt.createWithoutData(item.id);
                NFTObj.set('cancel_sale_processing', true);
                NFTObj.save()
                    .then(() => {
                        setProcessing(false);

                        setTxInfo({
                            item: item, 
                            txType: "cancel-sale", 
                            txStatus: "Processing", 
                            txHash: transaction.transactionHash, 
                            setTxInfoCallback: setTxInfo
                        });

                        getItem()
                    }, (error) => {
                        getItem()
                        setProcessing(false);
                        console.log(error.message);
                    });
            })
            .catch((err) => {
                setProcessing(false);
                throwError(err.message, err.code);
                console.log(err.message)
            }); 
    };

    const cancelLazySale = async () => {
        resetTxError();
        setProcessing(true);
        const NFTObjExt = Moralis.Object.extend("NFTs");
        const NFTObj = NFTObjExt.createWithoutData(item.id);
        NFTObj.set('lazy_mint_price', "0");
        NFTObj.save()
            .then(() => {
                setProcessing(false);

                setTxInfo({
                    item: item, 
                    txType: "cancel-sale", 
                    setTxInfoCallback: setTxInfo
                });

                getItem()
            }, (error) => {
                getItem()
                setProcessing(false);
                throwError(error.message);
                console.log(error.message);
            });
    };

    const putOnSale = async data => {
        resetTxError();
        setProcessing(true);
        setFormSaving(true);

        if(item.token_id == -1) {
            const vUUID = uuidv4();
            const msgParams = await genVoucher(
                address, 
                Moralis.Units.ETH(data.price), 
                item.royalties, 
                item.collection ? item.collection : '', 
                item.token_uri, 
                vUUID
            );
            const account = user.attributes.ethAddress;
            const params = [account, msgParams];
            const method = 'eth_signTypedData_v4';
            await web3.currentProvider.sendAsync({ method, params, account },
                function (err, result) { 
                    if (err) {
                        setFormSaving(false);
                        setProcessing(false);
                        console.log(err);
                    } else if (result.error) { 
                        setFormSaving(false);
                        setProcessing(false);
                        console.log(result.error);
                    } else {
                        const signature = result.result;
                        
                        const NFTObjExt = Moralis.Object.extend("NFTs");
                        const NFTObj = NFTObjExt.createWithoutData(item.id);
                        NFTObj.set('guid', vUUID);
                        NFTObj.set('signature', signature);
                        NFTObj.set('lazy_mint', true);
                        NFTObj.set('lazy_mint_price', Moralis.Units.ETH(data.price));
                        NFTObj.save()
                            .then(() => {
                                setProcessing(false);

                                setTxInfo({
                                    item: item, 
                                    txType: "put-on-sale", 
                                    setTxInfoCallback: setTxInfo
                                });

                                getItem()
                            }, (error) => {
                                getItem()
                                setProcessing(false);
                                console.log(error.message);
                            });
                    }
                });
        } else {
            const contract = new web3.eth.Contract(abi, address);

            await contract.methods
                .putOnSale(item.token_id, Moralis.Units.ETH(data.price))
                .send({from: window.ethereum.selectedAddress})
                .then((transaction) => {
                    reset({
                        price: 0
                    }, {
                        keepErrors: false, 
                        keepDirty: false,
                        keepIsSubmitted: false,
                        keepTouched: false,
                        keepIsValid: false,
                        keepSubmitCount: false
                    });
    
                    const NFTObjExt = Moralis.Object.extend("NFTs");
                    const NFTObj = NFTObjExt.createWithoutData(item.id);
                    NFTObj.set('put_on_sale_processing', true);
                    NFTObj.save()
                        .then(() => {
                            setProcessing(false);
                            setFormSaving(false);
                            getItem()
    
                            setTxInfo({
                                item: item, 
                                txType: "put-on-sale", 
                                txStatus: "Processing", 
                                txHash: transaction.transactionHash, 
                                setTxInfoCallback: setTxInfo 
                            });
                        }, (error) => {
                            getItem()
                            setFormSaving(false);
                            setProcessing(false);
                            console.log(error.message);
                        });
    
                })
                .catch((err) => {
                    setProcessing(false);
                    throwError(err.message, err.code);
                    console.log(err.message)
                });
        }
    };

    const buyNFT = async () => {
        resetTxError();
        setProcessing(true);
        const contract = new web3.eth.Contract(abi, address);
        await contract.methods
            .buyNFT(item.token_id)
            .send({
                from: user.get('ethAddress'), 
                value: item.sale.price
            })
            .then((transaction) => {
                const NFTObjExt = Moralis.Object.extend("NFTs");
                const NFTObj = NFTObjExt.createWithoutData(item.id);
                NFTObj.set('sale_processing', true);
                NFTObj.save()
                    .then(() => {
                        setProcessing(false);
                        getItem()

                        setTxInfo({
                            item: item, 
                            txType: "purchase", 
                            txStatus: "Processing", 
                            txHash: transaction.transactionHash, 
                            setTxInfoCallback: setTxInfo 
                        });
                    }, (error) => {
                        getItem()
                        setProcessing(false);
                        console.log(error.message);
                    });
            })
            .catch((err) => {
                setProcessing(false);
                throwError(err.message, err.code);
                console.log(err.message)
            });
    };

    const getLazyMintNFT = async () => {
        const result = await Moralis.Cloud.run('getLazyMintNFT', {uid: `${query.id}`});
        if(result && Object.keys(result).length > 0) {            
            setLazyMintNFT(result);
        }
    };

    const redeemNFT = async () => {
        resetTxError();
        setProcessing(true);
        const contract = new web3.eth.Contract(abi, address);        
        const signer = lazyMintItem.attributes.creator.attributes.ethAddress;
        const voucher = {
			price: lazyMintItem.attributes.lazy_mint_price,
			royalties: lazyMintItem.attributes.royalties,
			collection: lazyMintItem.attributes.collection,
			uri: lazyMintItem.attributes.token_uri,
			guid: lazyMintItem.attributes.guid,
			signature: lazyMintItem.attributes.signature,
		};
        await contract.methods
            .redeem(signer,voucher)
            .send({from: user.get('ethAddress'), value: item.sale.price })
            .then((transaction) => {
                const NFTObjExt = Moralis.Object.extend("NFTs");
                const NFTObj = NFTObjExt.createWithoutData(item.id);
                NFTObj.set('sale_processing', true);
                NFTObj.save()
                    .then(() => {
                        setTxInfo({
                            item: item, 
                            txType: "purchase", 
                            txStatus: "Processing", 
                            txHash: transaction.transactionHash, 
                            setTxInfoCallback: setTxInfo 
                        });
                        setProcessing(false);
                        getItem()
                    }, (error) => {
                        getItem()
                        setProcessing(false);
                        console.log(error.message);
                    });
            })
            .catch((err) => {
                setProcessing(false);
                throwError(err.message, err.code);
                console.log(err.message)
            });
    };

    useEffect(() => {
        const loadData = async () => {
            if(showPriceInCurrency) {
                await getCryptoRates('ETH', selectedCurrency.code).then(function(response) {
                    setCurrencyValue(response);
                });
            }
            
            await getItem();
            await getLazyMintNFT();
            await getMaxFees();
        };

        loadData();
    }, [query]);

    useEffect(() => {
        setQuery(params);
    }, [params]);

    if(!loading && !item) {
        return <NoRoute />
    }

    //let pageTitle = '';
    if(!loading) {
        if(item.artist) {
            document.title = `${item.name} by ${item.artist.name} - Explore | Block of Change`;
            //pageTitle = `${item.name} by ${item.artist.name} - Explore | Block of Change`;
        } else {
            document.title = `${item.name} - Explore | Block of Change`;
            // pageTitle = `${item.name} - Explore | Block of Change`;
        }
    }
    
    return (
        <>
            {/* { !loading && (
                <Helmet>
                    <title>{pageTitle}</title>
                    <meta name="description" content={item.description ? item.description : 'Block of Change - Your Blockchain Art Broker'} />
                    <meta name="DC.title" content={pageTitle} />
                    <meta name="DC.description" content={item.description ? item.description : 'Block of Change - Your Blockchain Art Broker'} />
                    <meta name="pinterest" content="nopin" />
                    <meta name="rights" content="2021 © Block of Change, All Rights Reserved." />
                    <meta name="dcterms.rights" content="2021 © Block of Change, All Rights Reserved." />
                    <meta name="dcterms.dateCopyrighted" content="2021" />
                    <meta name="og:site_name" content="Block of Change" />
                    <meta name="og:title" content={pageTitle} />
                    <meta name="og:type" content="website" />
                    <meta name="og:description" content={item.description ? item.description : 'Block of Change - Your Blockchain Art Broker'} />
                    <meta name="og:url" content={`${window.location.href}`} />
                    <link rel="canonical" content={`${window.location.href}`} />
                    <meta name="og:image" content={`${item.file.path}`} />
                    { item.file.width && <meta name="og:image:width" content={item.file.width} /> }
                    { item.file.height && <meta name="og:image:height" content={item.file.height} /> }
                    <meta name="og:image:type" content={item.file.mime_type} />
                </Helmet>
            )} */}

            { Object.keys(txInfo).length > 0 && <ConfirmationModal {...txInfo} /> }

            { loading ? <BreadcrumbsLoader /> : <Breadcrumb subpagelink="/explore" subpage="Explore" page={item.name} /> }
        
            { processing && <ProcessingModal /> }
            
            <Section>
                <Container>
                    <RowJustifyContentBetween>
                        <ProductColLeft>
                            <ProductThumb>
                                {loading 
                                ? <MediaLoader /> 
                                : <RenderItemMedia fileURL={item.file.path} mimeType={item.file.mime_type} alt={item.name} lightbox={true} /> }
                            </ProductThumb>

                            {width > 767 && (
                                loading 
                                ? <TabsLoader style={{ marginTop: 130 }} />
                                : <ItemTabs item={item} marginTop={130} />
                            )}
                        </ProductColLeft>
                        
                        <ProductColRight>
                            <ProductContent>
                                { loading 
                                ? <DetailsLoader /> 
                                : (
                                    <>
                                        <ProductName>{item.name}</ProductName>
                                        { item.artist && <ProductArtist to={artistURL(item.artist.name, item.artist.slug)}>by <strong>{item.artist.name}</strong></ProductArtist> }
                                        { item.description && <ProductDescription>{markdownText(item.description)}</ProductDescription> }
                                
                                        <ProductNFTSellers>
                                            {width > 767 && (
                                                <ProductNFTSellersSplitter>
                                                    <ProductNFTSellersSplitterLine></ProductNFTSellersSplitterLine>
                                                </ProductNFTSellersSplitter>
                                            )}

                                            <ProductNFTSellersCol className="fsm">
                                                <ProductNFTSellersCard>
                                                    <ProductNFTSeller>
                                                        <ProductNFTSellerAvatar to={userURL(item.owner.username, item.owner.slug)}>
                                                            <ProductNFTSellerAvatarImg src={item.owner.avatar} alt={item.owner.username} />
                                                        </ProductNFTSellerAvatar>

                                                        <ProductNFTSellerInfo>
                                                            <ProductNFTSellerInfoTitle>Owner</ProductNFTSellerInfoTitle>
                                                            <ProductNFTSellerInfoName className="txt-ellipsis" to={userURL(item.owner.username, item.owner.slug)}>{item.owner.username}</ProductNFTSellerInfoName>
                                                        </ProductNFTSellerInfo>
                                                    </ProductNFTSeller>
                                                </ProductNFTSellersCard>
                                            </ProductNFTSellersCol>

                                            <ProductNFTSellersCol className="lsm">
                                                <ProductNFTSellersCard>
                                                    <ProductNFTSeller>
                                                        <ProductNFTSellerAvatar to={userURL(item.creator.username, item.creator.slug)}>
                                                            <ProductNFTSellerAvatarImg src={item.creator.avatar} alt={item.creator.username} />
                                                        </ProductNFTSellerAvatar>

                                                        <ProductNFTSellerInfo>
                                                            <ProductNFTSellerInfoTitle>Creator</ProductNFTSellerInfoTitle>
                                                            <ProductNFTSellerInfoName className="txt-ellipsis" to={userURL(item.creator.username, item.creator.slug)}>{item.creator.username}</ProductNFTSellerInfoName>
                                                        </ProductNFTSellerInfo>
                                                    </ProductNFTSeller>
                                                </ProductNFTSellersCard>
                                            </ProductNFTSellersCol>

                                            {width < 768 && (
                                                <ProductNFTSellersSplitter>
                                                    <ProductNFTSellersSplitterLine></ProductNFTSellersSplitterLine>
                                                </ProductNFTSellersSplitter>
                                            )}
                                        </ProductNFTSellers>

                                        <ProductInfoList>
                                            <ListUnstyled>
                                                { !item.process.processing && item.isInSale && (
                                                    <ListItemPriceAndRoyalties>
                                                        <ListItemPriceAndRoyaltiesCol>
                                                            <ListItemLabel>Price</ListItemLabel>
                                                            <ListItemValue>{formatWeiToEth(Moralis.Units.FromWei(item.sale.price))}</ListItemValue>
                                                            {showPriceInCurrency && (
                                                                <ListItemPrice>({getPriceInCurrency(Moralis.Units.FromWei(item.sale.price), currencyValue, formatter, selectedCurrency.symbol)})</ListItemPrice>
                                                            )}
                                                        </ListItemPriceAndRoyaltiesCol>
                                                        
                                                        {item.royalties > 0 && (
                                                            <ListItemPriceAndRoyaltiesCol>
                                                                <ListItemLabel>Royalties</ListItemLabel>
                                                                <ListItemValue>{item.royalties / 100}%</ListItemValue>
                                                            </ListItemPriceAndRoyaltiesCol>
                                                        )}
                                                    </ListItemPriceAndRoyalties>
                                                ) }
                                            </ListUnstyled>
                                        </ProductInfoList>

                                        {txError.error && (
                                            <ProductPaddingH style={{marginTop: 25}}>
                                                <AlertDanger>{txError.msg}</AlertDanger>
                                            </ProductPaddingH>
                                        )}

                                        { item.process.processing ? (
                                            item.ownerIsUser && (
                                                <ProductPaddingH>
                                                    <AlertWarning style={{marginTop: 20}} role="alert">{item.process.msg}</AlertWarning>
                                                </ProductPaddingH>
                                            )
                                        ) : (
                                            !isAuthenticated ? (
                                                item.isInSale && (
                                                    <ProductPaddingH>
                                                        <BtnBlackLinkBlock to={`/wallet-connect?ref=${itemURL(item.id, item.token.token_address, item.token_id)}`} className="mt-4">Buy</BtnBlackLinkBlock>
                                                    </ProductPaddingH>
                                                )
                                            ) : (
                                                item.ownerIsUser && item.isInSale ? (
                                                    <ProductPaddingH>
                                                        <BtnWhiteButtonBlock 
                                                            type="button" 
                                                            className="mt-4" 
                                                            onClick={() => {
                                                                if(window.confirm('Are you sure you wish to cancel this sale?')) {
                                                                    if(item.token_id == -1) {
                                                                        cancelLazySale();
                                                                    } else {
                                                                        cancelSale();
                                                                    }
                                                                }
                                                            } }
                                                            disabled={processing}
                                                        >{processing ? 'Canceling sale...' : 'Cancel Sale' }</BtnWhiteButtonBlock>
                                                    </ProductPaddingH>
                                                ) : (
                                                    item.ownerIsUser ? (
                                                        <ProductPaddingH>
                                                            <PutOnSaleForm onSubmit={handleSubmit(putOnSale)}>
                                                                <PutOnSaleFormBox>
                                                                    <FormGroup>
                                                                        <PutOnSaleControlLabelRequired>Price</PutOnSaleControlLabelRequired>
                                                                        <div className="input-group flex-nowrap">
                                                                            <div className="input-group-prepend">
                                                                                <span className="input-group-text" id="addon-wrapping">
                                                                                    <i className="fab fa-ethereum"></i> ETH
                                                                                </span>
                                                                            </div>
                                                                            <input type="number" 
                                                                            className={errors.price ? "form-control is-invalid" : "form-control"} 
                                                                            name="price" id="price" placeholder="Item Price" 
                                                                            inputMode="decimal" min={0.00000001} step={0.00000001}
                                                                            {...register("price", { required: true, min: 0.00000001 })} 
                                                                            defaultValue={0} 
                                                                            {...formSaving ? ' readonly' : ''}
                                                                            />
                                                                        </div>
                                                                        <small id="priceHelp" className="form-text text-muted">Service Fee <strong>{getServiceFeeValueText(false,maxFees)}</strong>
                                                                            {item.royalties > 0 && !item.creatorIsUser && (<div>Royalties <strong>{item.royalties/100}%</strong></div>)}
                                                                            {item.royalties == 0 || item.creatorIsUser ? calculateValueToReceive(priceWatch, currencyValue, 'ETH', formatter, selectedCurrency.symbol) : calculateValueToReceiveWithoutRoyalties(priceWatch, item.royalties, currencyValue, formatter, 'ETH', selectedCurrency.symbol)}
                                                                        </small>
                                                                        {errors.price ? (
                                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                                { errors.price?.type === "required" && <div>Required Field.</div> }
                                                                                { errors.price?.type === "min" && <div>Min value is 0.00000001.</div> }
                                                                            </div>
                                                                        ) : ('')}
                                                                    </FormGroup>

                                                                    <BtnBlackButtonBlock
                                                                        type="submit"
                                                                        className="mt-4"
                                                                        {...formSaving ? ' disabled' : ''}
                                                                    >{formSaving ? 'Putting On Sale...' : 'Put On Sale' }</BtnBlackButtonBlock>
                                                                </PutOnSaleFormBox>
                                                            </PutOnSaleForm>
                                                        </ProductPaddingH>
                                                    ) : (
                                                        item.isInSale && item.token_id >= 0 ? (
                                                            <ProductPaddingH>
                                                                <BtnBlackButtonBlock 
                                                                    type="button" 
                                                                    className="mt-4" 
                                                                    onClick={buyNFT}
                                                                    disabled={processing}
                                                                >{ processing ? 'Buying...' : 'Buy' }</BtnBlackButtonBlock>
                                                            </ProductPaddingH>
                                                        ) : 
                                                        (item.owner.address !== user.get('ethAddress')) && item.isInSale && item.token_id == -1 ? (
                                                            <ProductPaddingH>
                                                                <BtnBlackButtonBlock 
                                                                    type="button" 
                                                                    className="mt-4" 
                                                                    onClick={redeemNFT}
                                                                    disabled={processing}
                                                                >{ processing ? 'Buying...' : 'Buy' }</BtnBlackButtonBlock>
                                                            </ProductPaddingH>
                                                        ) :                                         
                                                        ('')
                                                    )
                                                )
                                            )
                                        )}

                                        {item.nft_certificate && item.nft_certificate !== "" && (
                                            <ProductNFTCerticate>
                                                <a target="_blank" href={item.nft_certificate} rel="noreferrer">
                                                    <img src="/img/certificate.svg" alt="Certificate" />
                                                    <span>View certificate</span>
                                                </a>
                                            </ProductNFTCerticate>
                                        ) }
                                    </>
                                ) }
                                
                            </ProductContent>

                            {width < 768 && (
                                loading 
                                ? <TabsLoader style={{ marginTop: 114 }} />
                                : <ItemTabs item={item} marginTop={114} />
                            )}
                        </ProductColRight>
                    </RowJustifyContentBetween>
                </Container>
            </Section>

            { !loading && <Explore except={item.id} /> }
        </>
    );
}

export default ItemDetailsNew;
