import ContentLoader from "react-content-loader"

const CardLoader = (props) => {
    return (
        <ContentLoader 
            speed={2}
            width={263}
            height={359}
            viewBox="0 0 263 359"
            backgroundColor="#d4d4d4"
            foregroundColor="#b0b0b0" 
            style={{width: '100%', height: 'auto'}}
            {...props}
        >
            <rect x="0" y="0" rx="2" ry="2" width="263" height="180" /> 
            <circle cx="134" cy="180" r="40" /> 
            <rect x="53" y="242" rx="2" ry="2" width="160" height="20" /> 
            <rect x="14" y="286" rx="22" ry="22" width="235" height="50" />
        </ContentLoader>
    )
};

export default CardLoader;