import styled from 'styled-components'

export const TxBackdrop = styled.div`
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);

    @media (min-width: 768px) {
        background: rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(15px);
    }
`
export const TxModal = styled.div`
    position: fixed;
    z-index: 10000;
    width: 100%;
    max-width: 1000px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    .tx-header {
        text-align: right;
        padding: 24px 9px 19px;
    }
    
    .tx-close {
        cursor: pointer;
        width: 40px;
        height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 20px;
            height: auto;
        }
    }

    .tx-body {
        padding: 0 100px 105px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .tx-media {
        width: 100%;
        height: auto;
        -ms-flex: 0 0 30%;
        flex: 0 0 40%;
        max-width: 40%;
    }

    .tx-content {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        margin-left: 10%;
    }

    .tx-msg {
        margin-top: 40px;
        font-family: var(--secondary-font);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0;
        text-align: left;
        color: var(--black-color);
    }

    .tx-status {
        margin-top: 18px;
        font-family: var(--secondary-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0;
        text-align: left;
        color: #848484;
    }

    .tx-status-box {
        margin-top: 50px;
        border: 1px solid var(--black-color);
        border-radius: 2px;
        padding: 18px 23px;

        .tx-status-box-row {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            margin-right: -15px;
            margin-left: -15px;
        }

        .tx-status-box-col {
            position: relative;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }

        .tx-status-label {
            font-family: var(--secondary-font);
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #848484;
        }

        .tx-status-value {
            margin-top: 9px;
            font-family: var(--secondary-font);
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: var(--black-color);

            button {
                border: 0;
                background: transparent;
                padding: 4px 4px 3px;
                display: inline-flex;
                outline: 0;

                svg {
                    * {
                        transition: 0.3s all;
                    }
                }

                &:hover {
                    svg {
                        * {
                            fill: var(--fire-red);
                        }
                    }
                }
            }
        }
    }

    .btn.btn-block {
        margin-top: 45px;
    }

    .tx-profile-link {
        text-align: center;
        margin-top: 20px;

        a {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0;
            color: #000;

            &:hover {
                color: var(--fire-red);
            }
        }
    }

    @media (max-width: 1100px) {
        max-width: 800px;
    
        .tx-header {
            padding: 11px 9px 19px;
        }
    
        .tx-body {
            padding: 0 70px 70px;
        }
    }

    @media (max-width: 850px) {
        max-width: 650px;
    
        .tx-header {
            padding: 11px 9px 19px;
        }
    
        .tx-body {
            padding: 0 50px 50px;
        }
    
        .tx-msg {
            margin-top: 10px;
        }
    }

    @media (max-width: 850px) {
        max-width: 600px;

        .tx-header {
            padding: 5px 9px 19px;
        }
    
        .tx-body {
            padding: 0 30px 30px;
        }
    
        .tx-msg {
            margin-top: 0px;
            font-size: 14px;
        }
    
        .tx-status-box {
            margin-top: 30px;
            padding: 10px 15px;
        }
    }

    @media (max-width: 767px) {
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        -webkit-transform: unset;
        -ms-transform: unset;
        transform: unset;
        box-shadow: unset;
        border-radius: 16px 16px 0 0;

        .tx-header, .tx-media {
            display: none;
        }

        .tx-content {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            margin-left: 0;
        }

        .tx-body {
            padding: 40px 20px 40px;
        }

        .tx-status-box {
            padding: 16px 8px;
        }
    }
`