import React from 'react';
import { RenderItemMedia } from '../ItemDetails/ItemMedia';
import { formatWeiToEth } from '../../helpers/nftSettings';
import { artistURL, itemURL, userURL } from '../../helpers/entityURLs';
import { useMoralis } from "react-moralis";
import { Link } from 'react-router-dom';
import { CardCaption, NFTCard, NFTCardExplore, ImageHover, NFTCardBody, NFTCardOwner, NFTCardName, NFTCardBottom, NFTCardBottomCol, NFTCardBtnWhiteLink, NFTCardProcessing, NFTCardNameLink } from './style';

const NFTBody = (Moralis, item, token_address, itemProcessing) => {
    return (
        <>
            <ImageHover>
                <Link to={itemURL(item.id, token_address, item.token_id)}>
                    <RenderItemMedia fileURL={item.file.path} alt={item.name} mimeType={item.file.mime_type} />
                </Link>
            </ImageHover>
            <CardCaption>
                <NFTCardBody>
                    {item.artist ? (
                        <Link to={artistURL(item.artist.name, item.artist.slug)}>
                            <NFTCardOwner>{item.artist.name}</NFTCardOwner>
                        </Link>
                    ) : (
                        <Link to={userURL(item.creator.username, item.creator.slug)} >
                            <NFTCardOwner>{item.creator.username}</NFTCardOwner>
                        </Link>
                    )}

                    <NFTCardNameLink to={itemURL(item.id, token_address, item.token_id)}>
                        <NFTCardName>{item.name}</NFTCardName>
                    </NFTCardNameLink>

                    <NFTCardBottom>
                        <NFTCardBottomCol>
                            Owner
                            <Link to={userURL(item.owner.username, item.owner.slug)}>{item.owner.username}</Link>
                        </NFTCardBottomCol>

                        {item.isInSale && (
                            <NFTCardBottomCol>
                                Price
                                <span>{formatWeiToEth(Moralis.Units.FromWei(item.sale.price))}</span>
                            </NFTCardBottomCol>
                        )}
                    </NFTCardBottom>

                    <NFTCardBtnWhiteLink to={itemURL(item.id, token_address, item.token_id)}>{item.isInSale ? 'Purchase Now' : 'View item' }</NFTCardBtnWhiteLink>
                </NFTCardBody>
            </CardCaption>

            {itemProcessing && (
                <NFTCardProcessing>
                    <img src="/img/clock.svg" alt="Clock" />
                    Processing Transaction
                </NFTCardProcessing>
            )}
        </>
    )
};

const NFT = ({item, gridNumber = 3}) => {
    const { Moralis } = useMoralis();

    const itemProcessing = item.mint_processing 
        || item.sale_processing 
        || item.put_on_sale_processing 
        || item.cancel_sale_processing 
        || item.burn_processing;

    const token_address = typeof item?.token?.token_address !== "undefined" ? item.token.token_address : item.token_address;

    let classValue = "";
    if(gridNumber > 0) {
        classValue = `col-12 col-sm-6 ${gridNumber === 4 ? 'col-lg-4 col-xl-3' : 'col-lg-4'} `;
    }

    return (
        <div className={`${classValue}item explore-item`}>
            {gridNumber === 4 ? (
                <NFTCardExplore>{NFTBody(Moralis, item, token_address, itemProcessing)}</NFTCardExplore>
            ) : (
                <NFTCard>{NFTBody(Moralis, item, token_address, itemProcessing)}</NFTCard>
            )}
        </div>
    )
}

export default NFT 