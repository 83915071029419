const _range = function ( len, start ) {
    var out = [];
    var end;

    if ( start === undefined ) {
        start = 0;
        end = len;
    }
    else {
        end = start;
        start = len;
    }

    for ( var i=start ; i<end ; i++ ) {
        out.push( i );
    }

    return out;
};

export const getPaginationGroup = (page, pages, pageLimit) => {
    let numbers = [], 
        half = Math.floor( pageLimit / 2 );

    if ( pages <= pageLimit ) {
        numbers = _range( 0, pages );
    }
    else if ( page <= half ) {
        numbers = _range( 0, pageLimit-2 );
        numbers.push( 'ellipsis' );
        numbers.push( pages-1 );
    }
    else if ( page >= pages - 1 - half ) {
        numbers = _range( pages-(pageLimit-2), pages );
        numbers.splice( 0, 0, 'ellipsis' ); // no unshift in ie6
        numbers.splice( 0, 0, 0 );
    }
    else {
        numbers = _range( page-half+2, page+half-1 );
        numbers.push( 'ellipsis' );
        numbers.push( pages-1 );
        numbers.splice( 0, 0, 'ellipsis' );
        numbers.splice( 0, 0, 0 );
    }

    numbers.map((item, index) => {
        if(item !== 'ellipsis') {
            numbers[index]++;
        }
    });

    return numbers;
};

export const paginationInfo = (page, itemsPerPage, total) => {
    if(total === 0) {
        return '';
    }

    let start = 1;
    let end = itemsPerPage;

    if(page > 1) {
        start = (itemsPerPage * (page - 1)) + 1;
        end = start + itemsPerPage;
    }

    if(end > total) {
        end = total;
    }

    if(start > total) {
        return `Showing 0 of ${total} entries`;
    }

    return `Showing ${start} to ${end} of ${total} entries`;
};