import React, { useEffect, useState } from 'react';
import { ScrollToTop } from './style';

const Scrollup = () => {
    const [y, setY] = useState(window.scrollY);

    useEffect(() => {
        window.addEventListener('scroll', toggleScrollToTop);

        return () => {
            window.removeEventListener('scroll', toggleScrollToTop);
        };
    });

    const toggleScrollToTop = (e) => {
        const sth = document.getElementById('sth');
        const scrollTop = window.scrollY;
        if (scrollTop > y) {
            sth.classList.add('sthidden');
        } else {
            if (y > 1023) {
                if (window.screen.width >= 767) {
                    sth.classList.remove('sthidden');
                }
            } else {
                sth.classList.add('sthidden');
            }
        }

        setY(scrollTop);
    };

    return (
        <ScrollToTop id="sth" className="sthidden">
            <a href="#header" className="smooth-anchor">
                <i className="fas fa-arrow-up" />
            </a>
        </ScrollToTop>
    );
};

export default Scrollup;