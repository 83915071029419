import React from 'react';
import Header from '../../../components/Header/Header';
import ArtistsAdminIndex from '../../../components/Admin/Artists/Index';
import Footer from '../../../components/Footer/Footer';
import ModalSearch from '../../../components/Modal/ModalSearch';
import ModalMenu from '../../../components/Modal/ModalMenu';
import Scrollup from '../../../components/Scrollup/Scrollup';
import { StickyFooterBody, StickyFooterMain } from '../../../sharedComponents/StickyFooter';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';

const ArtistsAdmin = () => {
    document.body.className = '';
    
    return (
        <StickyFooterMain>
            <StickyFooterBody>
                <Header />
                <Breadcrumb title="Artists" subpagelink="/admin" subpage="Admin" page="Artists" />
                <ArtistsAdminIndex />
            </StickyFooterBody>
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
        </StickyFooterMain>
    );
}

export default ArtistsAdmin;