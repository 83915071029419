import React, { useEffect } from 'react';
import Header from '../components/Header/Header';
import HowItWorksSection from '../components/Work/Work';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import { StickyFooterBody, StickyFooterMain } from '../sharedComponents/StickyFooter';

const Partners = () => {
    useEffect(() => {
        document.body.classList = 'how-it-works';
    }, []);

    return (
        <StickyFooterMain>
            <StickyFooterBody>
                <Header />
                <HowItWorksSection />
            </StickyFooterBody>
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
        </StickyFooterMain>
    );
}

export default Partners;