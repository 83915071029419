import React, {useEffect} from 'react';
import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
/* import Auctions from '../components/Auctions/AuctionsOne'; */
import FeaturedArtists from '../components/Artists/Featured';
/* import TopSeller from '../components/TopSeller/TopSellerOne'; */
/* import Collections from '../components/Collections/Collections'; */
import Explore from '../components/Explore/ExploreOne';
import Work from '../components/Work/Work';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import { StickyFooterBody, StickyFooterMain } from '../sharedComponents/StickyFooter';
/* import { Helmet } from "react-helmet"; */

const ThemeOne = () => {
    useEffect(() => {
        document.body.className = '';
        document.body.classList.add(...['has-banner', 'home'])
    }, []);

    return (
        <StickyFooterMain>
            {/* <Helmet>
                <title>Block of Change</title>
                <meta name="description" content="Block of Change - Your Blockchain Art Broker" />
                <meta name="DC.title" content="Block of Change" />
                <meta name="DC.description" content="Block of Change - Your Blockchain Art Broker" />
                <meta name="pinterest" content="nopin" />
                <meta name="rights" content="2021 © Block of Change, All Rights Reserved." />
                <meta name="dcterms.rights" content="2021 © Block of Change, All Rights Reserved." />
                <meta name="dcterms.dateCopyrighted" content="2021" />
                <meta name="og:site_name" content="Block of Change" />
                <meta name="og:title" content="Block of Change" />
                <meta name="og:type" content="website" />
                <meta name="og:description" content="Block of Change - Your Blockchain Art Broker" />
                <meta name="og:url" content={`${window.location.origin}`} />
                <link rel="canonical" content={`${window.location.origin}`} />
                <meta name="og:image" content={`${window.location.origin}/img/meta-img.jpg`} />
                <meta name="og:image:width" content={1200} />
                <meta name="og:image:height" content={628} />
                <meta name="og:image:type" content="image/jpeg" />
            </Helmet> */}
            <StickyFooterBody>
                <Header hasBanner={true} />
                <Hero />
                {/* <Auctions /> */}
                <FeaturedArtists />
                {/* <TopSeller /> */}
                {/* <Collections /> */}
                <Explore />
                <Work showBreadcrumbs={false} />
            </StickyFooterBody>
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
        </StickyFooterMain>
    );
}

export default ThemeOne;