import React from "react";
import PropTypes from "prop-types";
import { UserBannerOverlay, UserBannerOverlayImg } from "./style";

const AuthorBanner = ({ image, name }) => {
    return (
        <UserBannerOverlay>
            <UserBannerOverlayImg src={image} alt={name} />
        </UserBannerOverlay>
    )
};

AuthorBanner.defaultProps = {
    image: '/img/inner_bg.jpg', 
    name: ''
};

AuthorBanner.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
};

export default AuthorBanner;