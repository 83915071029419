import React from 'react';
import Header from '../components/Header/Header';
import ItemDetailNew from '../components/ItemDetails/ItemDetailsNew';
/* import Work from '../components/Work/Work'; */
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import { StickyFooterBody, StickyFooterMain } from '../sharedComponents/StickyFooter';

const ItemDetailsNew = () => {
    return (
        <StickyFooterMain>
            <StickyFooterBody>
                <Header />
                <ItemDetailNew />
                {/* <Work showBreadcrumbs={false} /> */}
            </StickyFooterBody>
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
        </StickyFooterMain>
    );
}

export default ItemDetailsNew;