import styled from 'styled-components'

export const Section = styled.section`
.img-background:before {
    background-image: url('./img/hero1920.png');
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    
}
@media (min-width: 842px) {
.img-background:before {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    background-color: var(--black-color);
}
}
.img-background {
    position: relative;
}
.img-background .row {
    position: relative;
    z-index: 0;
}
@media (max-width: 1000px) {
    .title {
        font-size: 70px;
        filter: brightness(1);
    }
    .subtitle {
        font-size: 22px;
        filter: brightness(1);
    }
    .button-text {
        filter: brightness(1);
        font-size: 22px;
        line-height: 15px;
    }
}
@media (max-width: 841px) {
    .img-background:before {
        background-image: url('./img/home-art.png');
        background-size: contain;
        background-position: right;
        background-repeat: no-repeat;
    }
}
@media (max-width: 800px) {
    .title {
        font-size: 60px;
    }
    .button-text {
        font-size: 17px;
        line-height: 13px;
    }
}
@media (max-width: 700px) {
    .img-background:before {
        opacity: 0.5;
    }
}
`