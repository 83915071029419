import { useEffect, useState } from "react";
import { AiOutlineSend } from 'react-icons/ai';
import { MailchimpForm, MailchimpFormStatus, MailchimpFormStatusError, MailchimpFormStatusSuccess, MailchimpInputField, MailchimpSubmitBtn } from "./style";

const CustomForm = ({ status, message, onValidated }) => {
    const [email, setEmail] = useState('');

    useEffect(() => {
        if(status === "success") clearFields();
    }, [status])
    
    const clearFields = () => {
        setEmail('');
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        email &&
        email.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email
        });
    }

    return (
        <MailchimpForm onSubmit={(e) => handleSubmit(e)}>
            <MailchimpInputField 
                type="email" 
                placeholder="info@yourmail.com" 
                value={email} 
                onChange={e => setEmail(e.target.value)}
                required={true}
            />
            <MailchimpSubmitBtn type="submit">
                <AiOutlineSend fontSize="24px"/>
            </MailchimpSubmitBtn>

            { status === "sending" && <MailchimpFormStatus>Sending...</MailchimpFormStatus> }
            { status === "error" && <MailchimpFormStatusError dangerouslySetInnerHTML={{ __html: message }} /> }
            { status === "success" && <MailchimpFormStatusSuccess dangerouslySetInnerHTML={{ __html: message }} /> }
        </MailchimpForm>
    );
};

export default CustomForm;