import ContentLoader from "react-content-loader";
import getWindowDimensions  from '../../helpers/getWindowDimensions';

const BreadcrumbsLoader = (props) => {
    const { width } = getWindowDimensions();

    if(width < 768) {
        return ('');
    }

    return (
        <section className="breadcrumb-area d-flex align-items-center">
            <div className="container" style={{paddingTop: props.page ? 0 : ''}}>
                <ContentLoader 
                    speed={2}
                    width={210}
                    height={10}
                    viewBox="0 0 210 10"
                    backgroundColor="#d4d4d4"
                    foregroundColor="#b0b0b0" 
                    style={{maxWidth: '100%', height: 'auto'}}
                    {...props}
                >
                    <rect x="15" y="0" rx="4" ry="4" width="40" height="10" />
                    <rect x="62" y="0" rx="4" ry="4" width="5" height="10" />
                    <rect x="74" y="0" rx="4" ry="4" width="40" height="10" />
                    <rect x="121" y="0" rx="4" ry="4" width="5" height="10" />
                    <rect x="133" y="0" rx="4" ry="4" width="40" height="10" />
                </ContentLoader>
            </div>
        </section>
    )
};

export default BreadcrumbsLoader;