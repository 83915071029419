import styled from 'styled-components'

export const StickyFooterMain = styled.div` 
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    min-height: calc(var(--vh,1vh) * 100);
    z-index: 1;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
`

export const StickyFooterBody = styled.div` 
    position: relative;
    z-index: 2;
    flex: 1;
    width: 100%;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
    padding-bottom: 97px;
`