import { useState } from "react";
import { isImage, isVideo } from "../../helpers/fileUpload";
import { ProductLightbox, ProductLightboxClose, ProductLightboxInner, ProductLightboxMediaImg, ProductLightboxMediaVideo, ProductLightboxOuter } from "./styles";

export const RenderItemMedia = ({fileURL, alt, mimeType, lightbox = false}) => {
    const [show, setShow] = useState(false);

    const closeLightBox = (e) => {
        if(e.target.tagName !== 'IMG' 
        && e.target.tagName !== 'VIDEO') {
            setShow(false);
        }
    };

    const style = {};
    if(lightbox) {
        style.cursor = "pointer";
    }

    if(isImage(mimeType)) {
        return (
            <>
                <img src={fileURL} alt={alt} style={style} onClick={() => setShow(lightbox)}/>

                {lightbox && (
                    <ProductLightbox className={`${show ? 'show' : ''}`} onClick={closeLightBox}>
                        <ProductLightboxClose>
                            <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                <g id="cross">
                                    <line x1="7" x2="25" y1="7" y2="25" fill="none" stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px" />
                                    <line x1="7" x2="25" y1="25" y2="7" fill="none" stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px"/>
                                </g>
                            </svg>
                        </ProductLightboxClose>
                        <ProductLightboxOuter>
                            <ProductLightboxInner>
                                <ProductLightboxMediaImg src={fileURL} alt={alt} />
                            </ProductLightboxInner>
                        </ProductLightboxOuter>
                    </ProductLightbox>
                )}
            </>
        )
    }

    if(isVideo(mimeType)) {
        return (
            <>
                <video loop={true} playsInline={true} autoPlay={true} muted={true} style={style} onClick={() => setShow(lightbox)}>
                    <source src={fileURL} type={mimeType} />
                    Your browser does not support the video tag.
                </video>

                {lightbox && (
                    <ProductLightbox className={`${show ? 'show' : ''}`} onClick={closeLightBox}>
                        <ProductLightboxClose>
                            <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                <g id="cross">
                                    <line class="cls-1" x1="7" x2="25" y1="7" y2="25" fill="none" stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px" />
                                    <line class="cls-1" x1="7" x2="25" y1="25" y2="7" fill="none" stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px"/>
                                </g>
                            </svg>
                        </ProductLightboxClose>
                        <ProductLightboxOuter>
                            <ProductLightboxInner>
                                <ProductLightboxMediaVideo loop={true} playsInline={true} autoPlay={show} muted={!show} controls={true}>
                                    <source src={fileURL} type={mimeType} />
                                    Your browser does not support the video tag.
                                </ProductLightboxMediaVideo>
                            </ProductLightboxInner>
                        </ProductLightboxOuter>
                    </ProductLightbox>
                )}
            </>
        );
    }

    return ('');
}