import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useMoralis } from "react-moralis";
import AuthorBanner from '../AuthorProfile/Page/Banner/Banner';
/* import AuthorInfo from '../AuthorProfile/Page/Info/Info'; */
import Breadcrumb from '../Breadcrumb/Breadcrumb';
/* import Tabs from '../Tabs/Tabs';
import NFTItem from '../Account/Item'; */
import { bannerPlaceholder, getAvatarPlaceholder } from '../../helpers/fileUpload';
import { Container, Divider} from './styles'
import NFT from '../NFT/NFT';
import AuthorInfoLoader from '../AuthorProfile/Page/Info/InfoLoader';
import AuthorInfo from '../AuthorProfile/Page/Info/Info';
import BannerLoader from '../AuthorProfile/Page/Banner/BannerLoader';
import BreadcrumbsLoader from '../Breadcrumb/BreadcrumbsLoader';
import NFTLoader from '../NFT/NFTLoader';
import TitleLeftLoader from '../Loaders/TitleLeftLoader';
import { ProfileNoItems } from '../Account/styles';
import { Link } from 'react-router-dom';

const User = () => {
    const { user, Moralis } = useMoralis();
    const params = useParams();
    const [ loading, setLoading ] = useState(true);
    const [ createdNFTs, setCreatedNFTs] = useState([]);
    const [ ownedNFTs, setOwnedNFTs] = useState([]);
    const [ profile, setProfile ] = useState();

    document.title = 'Loading | Block of Change';

    useEffect(()=> {
        const loadUser = async () => {
            const response = await Moralis.Cloud.run('getUserByURL', {slug: `${params.slug}`});
            if(response) {
                if(response.avatar === null) {
                    response.avatar = getAvatarPlaceholder();
                }

                if(response.banner === null) {
                    response.banner = bannerPlaceholder;
                }

                response.isCurrentUser = false;
                if(user && user.id === response.id) {
                    response.isCurrentUser = true;
                }

                document.body.classList = 'has-banner';

                setProfile(response);

                if(response.is_artist) {
                    await loadCreatedNFTs();
                } else {
                    await loadOwnedNFTs();
                }
            }

            setLoading(false)
        };

        const loadCreatedNFTs = async () => {
            const response = await Moralis.Cloud.run('getUserCreatedItems', {slug: `${params.slug}`});
            setCreatedNFTs(response);
        };
        
        const loadOwnedNFTs = async () => {
            const response = await Moralis.Cloud.run('getUserOwnedItems', {slug: `${params.slug}`});
            setOwnedNFTs(response);
        };

        loadUser();
    }, [Moralis]);

    if(loading) {
        return (
            <>
                <BannerLoader />
                
                <BreadcrumbsLoader />
                
                <Container className="container">
                    <AuthorInfoLoader />

                    <Divider />

                    <div className="col-12 col-md-8 col-lg-7" style={{marginTop: 48, paddingLeft: 0}}>
                        <TitleLeftLoader />
                    </div>

                    <div label="Owned">
                        <div className="row items" style={{ marginLeft: -15, marginRight: -15 }}>
                            {[0, 1, 2, 4].map((idx) => (
                                <div className="col-12 col-sm-6 col-lg-3" key={`nft-${idx}`} style={{marginTop: 30}}>
                                    <NFTLoader style={{boxShadow: '0 0 2px rgba(0, 0, 0, .15), 0 2px 2px rgba(0, 0, 0, .05)', width: '100%', height: 'auto'}} />
                                </div>
                            ))}
                        </div>
                    </div>
                </Container>
            </>
        );
    }

    document.title = `${profile.username} - User | Block of Change`;

    return (
        <>
            <AuthorBanner image={profile.banner} name={profile.username} />
            
            <Breadcrumb title="User Profile" page={profile.username} />
            
            <Container className="container">
                <>
                    <AuthorInfo artist={profile} />
                    
                    <Divider />

                    <div className="col-12 col-md-8 col-lg-7" style={{marginTop: 48, paddingLeft: 0}}>
                        <div className="intro mb-4">
                            <span>{ profile.is_artist ? 'Collectibles' : 'Owned' }</span>
                            <h3 className="mt-3 mb-0">{ profile.is_artist ? 'Artworks' : 'Collectibles' }</h3>
                        </div>
                    </div>
                    
                    {createdNFTs.length === 0 && ownedNFTs.length === 0 ? (
                        <ProfileNoItems>
                            <h4>No items to display</h4>
                            <p>Stay toned. Meanwhile why don't you explore our other masterworks.</p>
                            <Link to="/explore" className="btn btn-bordered-black">Explore</Link>
                        </ProfileNoItems>
                    ) : (
                        <div label="Owned">
                            <div className="row items" style={{ marginLeft: -15, marginRight: -15 }}>
                                {
                                    profile.is_artist 
                                    ? createdNFTs.map((item, idx) => <NFT key={`nft-c-${idx}`} gridNumber={4} item={item} author={true} /> )
                                    : ownedNFTs.map((item, idx) => <NFT key={`nft-o-${idx}`} gridNumber={4} item={item} author={false} /> )
                                }
                            </div>
                        </div>
                    )}
                </>
            </Container>
        </>
    )
};

export default User;