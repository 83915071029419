import styled from 'styled-components'

export const ProfileNoItems = styled.div`
    text-align: center;
    padding-top: 65px;
    
    h1, h2, h3, h4, h5, h6 {
        font-family: var(--secondary-font);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        color: #000;
        margin-top: 0;
        margin-bottom: 20px;
    }

    p {
        margin: 0;
        font-family: var(--secondary-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: 0;
        color: #000;
    }

    .btn {
        font-size: 14px;
        line-height: 17px;
        font-weight: normal;
        padding: 11px 59px;
        margin-top: 20px;
    }
`

export const Container = styled.div`
margin-top: 40px !important;
padding: 0 20px !important; 
height: 400px;

.bio {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */

    margin:0;
    margin-top: 16px;
    color: #3A3A3A;
}
.button-width {
    max-width: 380px;
    width: 100%;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}
.success-message {
    margin-top: 75px;
    background-color: black;
    padding: 20px;
    border-radius: 4px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;
}
@media (max-width: 991px) {
    margin-top: 30px !important;
}
@media (max-width: 800px) {
    margin-top: 62px !important;
}
@media (max-width: 700px) {
    .button-width {
        max-width: 280px;
    }
    
}
.text {
    font-family: Inter; 
    padding:0;
    margin: 0;
    font-size: 18px;
    color: #848484;
}
`