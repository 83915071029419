import { Container, Section } from "../Elements/styles";
import getWindowDimensions  from '../../helpers/getWindowDimensions';
import { Link } from "react-router-dom";

const AdminIndex = () => {
    const { width } = getWindowDimensions();

    return (
        <Section className="help-center-area" style={ width > 767 ? {} : { marginTop: 40 }}>
            <Container>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-7">
                        <div className="intro text-center">
                            <h3 className="mt-3 mb-0">Admin</h3>
                            <p style={{fontFamily: 'Inter', fontSize: 14, color: '#3A3A3A'}}>Here you can edit some of the platform info.</p>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center items">
                    <div className="col-12 col-md-6 col-lg-4 item">
                        <div className="card help-card" style={{minHeight: 218, padding: 0, paddingTop: 32, paddingBottom: 32, paddingLeft: 16, paddingRight: 16 }}>
                            <Link to="/admin/categories" className="d-block text-center">
                                <img src="/img/admin/art.svg" alt="Categories" style={{width: 'auto', height: 48}} />
                                <h4 style={{fontFamily: 'Playfair Display', fontSize: 18, color: 'black'}}>Categories</h4>
                                <p style={{fontFamily: 'Inter', fontSize: 14, color: '#3A3A3A'}}>Add or Change the Block of Change Art Categories</p>
                            </Link>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-4 item">
                        <div className="card help-card" style={{minHeight: 218, padding: 0, paddingTop: 32, paddingBottom: 32, paddingLeft: 16, paddingRight: 16 }}>
                            <Link to="/admin/artists" className="d-block text-center">
                                <img src="/img/admin/designer-artist.svg" alt="Categories" style={{width: 'auto', height: 48}} />
                                <h4 style={{fontFamily: 'Playfair Display', fontSize: 18, color: 'black'}}>Artists</h4>
                                <p style={{fontFamily: 'Inter', fontSize: 14, color: '#3A3A3A'}}>Add or Change the Block of Change Featured Artists</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </Container>
        </Section>
    )
};

export default AdminIndex;