import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { MoralisProvider } from "react-moralis";
import reportWebVitals from './reportWebVitals';
import { MoralisDappProvider } from "./providers/MoralisDappProvider/MoralisDappProvider";
import { InfoProvider } from '../src/providers/InfoContext'
import { CurrencyProvider } from './providers/currencyContext';

const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;
const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;

const Application = () => {
  const isServerInfo = APP_ID && SERVER_URL ? true : false;
  
  if (isServerInfo) {
    return (
      <InfoProvider>
        <CurrencyProvider>
          <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
            <MoralisDappProvider>
              <App />
            </MoralisDappProvider>
          </MoralisProvider>
        </CurrencyProvider>
      </InfoProvider>
    );
  } else {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        QuickStart
      </div>
    );
  }
};

ReactDOM.render(
  <React.StrictMode>
    <Application />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
