import React from 'react'
import { Link } from 'react-router-dom';
import { artistURL } from '../../helpers/entityURLs';
import { bannerPlaceholder, getAvatarPlaceholder } from '../../helpers/fileUpload';
import { ArtistCard, ArtistCardAvatar, ArtistCardBanner, ArtistCardBtn, ArtistCardDetails, ArtistCardName } from './styles';

const Card = ({ name, slug, avatar, banner, showBannerMobile = false }) => {
    let artistAvatar = avatar;
    let artistBanner = banner;

    if(artistAvatar === null) {
        artistAvatar = getAvatarPlaceholder();
    }
    
    if(artistBanner === null) {
        artistBanner = bannerPlaceholder;
    }

    return (
        <ArtistCard className={showBannerMobile ? 'mb-banner-show' : ''}>
            <ArtistCardBanner to={artistURL(name, slug)} style={{backgroundImage: `url(${artistBanner})`}} />
            
            <ArtistCardDetails>
                <ArtistCardAvatar style={{backgroundImage: `url(${artistAvatar})`}} />

                <ArtistCardName to={artistURL(name, slug)}>{name}</ArtistCardName>
                <div>
                    <ArtistCardBtn to={artistURL(name, slug)}>Discover</ArtistCardBtn>
                </div>
            </ArtistCardDetails>
        </ArtistCard>
    )
}

export default Card
