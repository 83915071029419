import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ContentLoader, Container, Section } from '../Elements/styles';
import { HelpPageIcon, HelpPageTitle, LegalText } from './style';
import { markdownText } from '../../helpers/markdownHelper';

let host = window.location.hostname;
if ( window.location.port !== "" ) {
    host+= ':' + window.location.port;
}

const BASE_URL = `${window.location.protocol}//${host}/getting-started.json`;

const GettingStarted = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    
    useEffect(() => {
        axios.get(`${BASE_URL}`)
        .then(res => {
            setData(res.data);
            setLoading(false);
        })
        .catch(err => console.log(err))
    });

    if(loading) {
        return <ContentLoader>Loading...</ContentLoader>;
    }

    document.title = 'Getting Started | Block of Change';

    return (
        <Section>
            <Container>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-7">
                        <HelpPageIcon>
                            <img src={data.icon} alt={data.title} />
                        </HelpPageIcon>
                        <HelpPageTitle>{data.title}</HelpPageTitle>
                        <LegalText>{markdownText(data.content)}</LegalText>
                    </div>
                </div>
            </Container>
        </Section>
    );
}

export default GettingStarted;