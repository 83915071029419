import React, { useEffect, useState } from 'react';
/* import axios from 'axios'; */
import { useMoralis } from "react-moralis";
import { Section, Container } from '../../Elements/styles';
import getWindowDimensions  from '../../../helpers/getWindowDimensions';
import { TableStripped, TableTopMenu, Td, TD30, ThDarkSmall, TableFooter, TableFooterInfo, TableFooterPagination, AdminBtn, AdminBtnSecondaryLink } from '../styles';
import PaginationAjax from '../../Elements/Pagination/PaginationAjax';
import { paginationInfo } from '../../Elements/Pagination/helper';
import Form from './Form';

const AdminCategories = () => {
    document.title = 'Categories - Admin | Block of Change';
    const { Moralis } = useMoralis();
    const { width } = getWindowDimensions();

    //const [page, setPage] = useState(0)
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([])
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pages, setPages] = useState(1);
    const [total, setTotal] = useState(0);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [formData, setFormData] = useState();

    const showForm = (currentName, id) => {
        setFormData({
            successCallback: formSaved,
            closeModalCallback: formCancel, 
            currentName: currentName, 
            id: id
        });
    };

    const formCancel = () => {
        setFormData(null);
    };

    const formSaved = () => {
        formCancel();
        loadData(page);
    };

    const loadData = async (newPage, resetPages) => {
        if(!resetPages) {
            resetPages = pages;
        }

        if(!isLoadingMore && newPage <= resetPages) {
            setIsLoadingMore(true);

            const response = await Moralis.Cloud.run('paginateCategories', {
                page: newPage,
                items: itemsPerPage,
            });

            setPages(response.pages);
            setIsLoadingMore(false);
            setItems(response.items);
            setTotal(response.total);
            setPage(newPage);
            localStorage.setItem('AdminCategoryTablePage', newPage);
            setLoading(false);
        }
    };

    useEffect(() => {
        if(localStorage.getItem('AdminCategoryTablePage')) {
            const newPage = parseInt(localStorage.getItem('AdminCategoryTablePage'));
            loadData(newPage, newPage);
        } else {
            loadData(1);
        }
    }, []);

    return (
        <>
            <Section style={{ paddingTop: 40 }}>
                <Container style={width > 767 ? {} : { paddingLeft: 15, paddingRight: 15 }}>
                    <TableTopMenu>
                        {width < 768 && <AdminBtnSecondaryLink to="/admin" style={{marginRight: 5}}>Back</AdminBtnSecondaryLink> }
                        <AdminBtn onClick={showForm}>Add</AdminBtn>
                    </TableTopMenu>
                    
                    <TableStripped>
                        <thead>
                            <tr>
                                <ThDarkSmall>Category</ThDarkSmall>
                                <ThDarkSmall>Updated At</ThDarkSmall>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? 
                                <tr>
                                    <Td colSpan={2}>Loading...</Td>
                                </tr>
                                : (
                                    items.length === 0 ? 
                                    <tr>
                                        <Td colSpan={2}>No data...</Td>
                                    </tr>
                                    : (
                                        items.map((category, idx) => {
                                            const date = new Date(category.updatedAt);
                                            return (
                                                <tr key={`ts_${idx}`}  onClick={() => showForm(category.name, category.id)}>
                                                    <Td>{category.name}</Td>
                                                    <TD30>{date.toDateString()}, {date.toLocaleTimeString()}</TD30>
                                                </tr>
                                            );
                                        })
                                    )
                                )
                            }
                        </tbody>
                        {!loading && (
                            <tfoot>
                                <tr>
                                    <td colSpan={2}>
                                        <TableFooter>
                                            <TableFooterInfo>{paginationInfo(page, itemsPerPage, total)}</TableFooterInfo>
                                            <TableFooterPagination>
                                                <PaginationAjax currentPage={page} pages={pages} pageLimit={6} parentMethod={loadData} />
                                            </TableFooterPagination>
                                        </TableFooter>
                                    </td>
                                </tr>
                            </tfoot>
                        )}
                    </TableStripped>
                </Container>
            </Section>

            {formData && (
                <Form {...formData} />
            )}
        </>
    );
}

export default AdminCategories;