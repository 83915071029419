import React from 'react';
import Header from '../../../components/Header/Header';
import CategoriesAdminIndex from '../../../components/Admin/Categories/Index';
import Footer from '../../../components/Footer/Footer';
import ModalSearch from '../../../components/Modal/ModalSearch';
import ModalMenu from '../../../components/Modal/ModalMenu';
import Scrollup from '../../../components/Scrollup/Scrollup';
import { StickyFooterBody, StickyFooterMain } from '../../../sharedComponents/StickyFooter';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';

const CategoriesAdmin = () => {
    document.body.className = '';
    
    return (
        <StickyFooterMain>
            <StickyFooterBody>
                <Header />
                <Breadcrumb title="Categories" subpagelink="/admin" subpage="Admin" page="Categories" />
                <CategoriesAdminIndex />
            </StickyFooterBody>
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
        </StickyFooterMain>
    );
}

export default CategoriesAdmin;