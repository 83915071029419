import { Link } from "react-router-dom";
import getWindowDimensions  from '../../helpers/getWindowDimensions';
import LoginBtn from "./LoginBtn";

const NavItems = ({ isAuthenticated, isAdmin, logout }) => {
    const { width } = getWindowDimensions();

    return (
        <ul className="navbar-nav items">
            <li className="nav-item">
                <Link className="nav-link" to="/">Home</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="/explore">Explore</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="/artists">Artists</Link>
            </li>

            {width < 1200 && isAuthenticated && <LoginBtn isAuthenticated={isAuthenticated} isAdmin={isAdmin} logoutCallback={logout} /> }
            
            {width < 1200 && !isAuthenticated && (
                <li className="nav-item">
                    <Link to="/wallet-connect" className="nav-link">Wallet</Link>
                </li>
            ) }
        </ul>
    )
};

export default NavItems;