import MailchimpSubscribe from "react-mailchimp-subscribe";
import CustomForm from "./CustomForm";

const FormContainer = ({ widgetTitle }) => {
    const mailchimpURL = process.env.REACT_APP_MAILCHIMP_URL;
    const mailchimpU= process.env.REACT_APP_MAILCHIMP_U;
    const mailchimpID = process.env.REACT_APP_MAILCHIMP_ID;

    return (
        <div className="col-12 col-sm-6 offset-lg-1 col-lg-3">
            <div className="footer-items">
                <h4 className="footer-title">{widgetTitle}</h4>
                
                <MailchimpSubscribe 
                    url={`${mailchimpURL}?u=${mailchimpU}&id=${mailchimpID}`}
                    render={({ subscribe, status, message }) => (
                        <CustomForm
                            status={status} 
                            message={message}
                            onValidated={formData => subscribe(formData)}
                        />
                    )}
                />
            </div>
        </div>
    );
};

export default FormContainer;