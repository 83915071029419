import styled, { css } from "styled-components";

export const TabsWrapper = styled.div``

const TabListStyle = css`
border-bottom: 1px solid #ccc;
padding-left: 0;
`

export const TabListOl = styled.ol`
display: -ms-flexbox;
display: flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
padding-left: 0;
margin-bottom: 0;
list-style: none;
${TabListStyle}
`

export const TabListDiv = styled.div`
${TabListStyle}
`

export const TabListContainer = styled.ol`
padding: 0;
width: 100%;
padding: 0;
margin-right: auto;
margin-left: auto;
display: -ms-flexbox;
display: flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
padding-left: 0;
margin-bottom: 0;
list-style: none;
${TabListStyle}

@media (min-width: 576px) {
    max-width: 540px;
}

@media (min-width: 768px) {
    max-width: 720px;
}

@media (min-width: 992px) {
    max-width: 960px;
}

@media (min-width: 1200px) {
    max-width: 1170px;
}
`

export const TabListItem = styled.li`
margin: 0;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;

& > span {
    position: relative;
    display: inline-block;
    margin: 0 10px;
    padding: 10px 0;
    font-family: var(--secondary-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0;
    color: #000;
    cursor: pointer;

    &:after {
        position: absolute;
        content: '';
        height: 3px;
        width: 0;
        bottom: 0;
        left: 0;
        background-color: var(--fire-red);
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease 0s;
    }
}

&:first-of-type {
    & > span {
        margin-left: 0;
    }
}

&.active {
    & > span {
        color: var(--fire-red);
    
        &:after {
            width: 100%;
            visibility: visible;
            opacity: 1;
        }
    }
}
`

export const TabContent = styled.div``