import styled from 'styled-components'

export const ArtistInfoWrapper = styled.div`
display:flex;
flex-direction: row;
.avatar {
    width: 205px;
    height: 205px;
    background-color: white;

    img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}
.date {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #848484;
    margin: 0;
}
.name {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 43px;
    /* identical to box height */

    margin: 0;
    margin-top: 5px;
    color: #000000;
}

.bio p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */
    
    margin:0;
    margin-top: 16px;
    color: #3A3A3A;
}
@media (max-width: 991px) {
    .avatar {
        width: 170px;
        height: 170px;
    }
}
@media (max-width: 550px) {
    .avatar {
        width: 115px;
        height: 115px;
    }
}
@media (max-width: 450px) {
    .avatar {
        width: 104px;
        height: 104px;
    }
    .name {
        font-size: 26px;
        line-height: 40px; 
    }
}
@media (max-width: 350px) {
    .avatar {
        width: 104px;
        height: 104px;
    }
    .name {
        font-size: 25px;
        line-height: 40px; 
    }
}
`

export const Container = styled.div`
margin-top: 40px !important;
padding-left: 20px !important;
padding-right: 20px !important;
.bio {
    margin: 0;
}
.bio p {
    font-family: Inter;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 22px;
/* or 157% */

margin:0;
margin-top: 16px;
color: #3A3A3A;
}
`
export const TextWrapper = styled.div`
display: flex;
flex-direction: column;
margin-left: 100px;

@media (min-width: 992px) {
    width: calc(100% - 305px);
}

@media (min-width: 651px) and (max-width: 991px) {
    width: calc(100% - 270px);
}

@media (max-width: 650px){
    margin-left: 50px;
}
@media (max-width: 550px){
    margin-left: 30px;
}
@media (max-width: 400px){
    margin-left: 20px;
}
`
export const SocialNetworks = styled.div`
display: flex;
flex-direction: row;
margin-top: 25px;

a {
    display: inline-flex;
}

@media (max-width: 550px) {
    margin-top: 15px;
}
`
export const Divider = styled.hr`
border-top: 0.5px solid #e4e4e4;
width: 100%;
padding: 0;
margin: 0;
margin-top: 66px;
`