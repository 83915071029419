import ContentLoader from "react-content-loader"

const WorkElLoader = (props) => {
    return (
        <ContentLoader 
            speed={2}
            width={262}
            height={209}
            viewBox="0 0 262 209"
            backgroundColor="#d4d4d4"
            foregroundColor="#b0b0b0" 
            style={{width: '100%', height: 'auto'}}
            {...props}
        >
            <rect x="0" y="0" rx="3" ry="3" width="50" height="40" />
            <rect x="0" y="73" rx="3" ry="3" width="262" height="20" />
            <rect x="0" y="97" rx="3" ry="3" width="87" height="20" />
            <rect x="0" y="136" rx="3" ry="3" width="242" height="16" />
            <rect x="0" y="162" rx="3" ry="3" width="262" height="16" />
            <rect x="0" y="187" rx="3" ry="3" width="130" height="16" />
        </ContentLoader>
    )
};

export default WorkElLoader;