import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const AdminBtnStyle = css`
display: inline-block;
font-weight: 400;
text-align: center;
vertical-align: middle;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
background-color: transparent;
border: 1px solid transparent;
padding: 0.375rem 0.75rem;
font-size: 1rem;
line-height: 1.5;
border-radius: 0.25rem;
transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

&.btn-xs-block {
    @media (max-width: 767px) {
        display: block;
    }
}
`

const AdminBtnBlackStyle = css`
color: #fff !important;
background-color: #343a40;
border-color: #343a40;

&:hover {
    color: #fff !important;
    background-color: #23272b;
    border-color: #1d2124;
}
`

const AdminBtnSecondaryStyle = css`
color: #FFF !important;
background-color: #6c757d;
border-color: #6c757d;

&:hover {
    color: #FFF !important;
    background-color: #5a6268;
    border-color: #545b62;
}
`

const AdminBtnLightStyle = css`
color: #212529 !important;
background-color: #f8f9fa;
border-color: #f8f9fa;

&:hover {
    color: #212529 !important;
    background-color: #e2e6ea;
    border-color: #dae0e5;
}
`

export const AdminBtn = styled.button`
${AdminBtnStyle}
${AdminBtnBlackStyle}
`

export const AdminBtnLink = styled(Link)`
${AdminBtnStyle}
${AdminBtnBlackStyle}
`

export const AdminBtnSecondary = styled.button`
${AdminBtnStyle}
${AdminBtnSecondaryStyle}
`

export const AdminBtnSecondaryLink = styled(Link)`
${AdminBtnStyle}
${AdminBtnSecondaryStyle}
`

export const AdminBtnLight = styled.button`
${AdminBtnStyle}
${AdminBtnLightStyle}
`

export const AdminBtnLightLink = styled(Link)`
${AdminBtnStyle}
${AdminBtnLightStyle}
`

export const TableTopMenu = styled.div`
text-align: right;
padding: 5px 0;
`

export const TableBottomMenu = styled.div`
    
`

const TableStyle = css`
width: 100%;
margin-bottom: 1rem;
color: #212529;
border-collapse: collapse;
border-spacing: 0;

@media (max-width: 767px) {
    display: block;

    thead {
        display: none;
    }

    tbody, tfoot {
        &, tr, td {
            display: block;
            width: 100%;
            max-width: 100%;
        }
    }

    tbody {
        tr {
            td:not(:first-child) {
                border-top: 0;
            }
            
            & + tr {
                margin-top: 15px;
            }
        }
    }
}
`

const TableHoverStyle = css`
tbody {
    tr {
        &:hover {
            color: #212529;
            background-color: rgba(0,0,0,.075);
            cursor: pointer;
        }
    }
}
`

const Th_Td_Style = css`
padding: 0.75rem;
vertical-align: middle;
border-top: 1px solid #dee2e6;
font-size: 14px;
line-height: 16px;
`

const Th_Td_Small_Style = css`
padding: 0.3rem;
`

const Th_Style = css`
vertical-align: bottom;
border-bottom: 2px solid #dee2e6;
`

export const Th = styled.th`
${Th_Td_Style}
${Th_Style}
`

export const ThSmall = styled.th`
${Th_Td_Style}
${Th_Td_Small_Style}
${Th_Style}
`

export const ThDark = styled.th`
${Th_Td_Style}
${Th_Style}
color: #fff;
background-color: #343a40;
border-color: #454d55;
`

export const ThDarkSmall = styled.th`
${Th_Td_Style}
${Th_Td_Small_Style}
${Th_Style}
color: #fff;
background-color: #343a40;
border-color: #454d55;
`

export const Td = styled.td`
${Th_Td_Style}
`

export const TdSmall = styled.td`
${Th_Td_Style}
padding: 0.3rem;
`

export const TableArtistPhoto = styled.img`
width: 50px;
height: 50px;
object-fit: cover;
object-position: center;
margin-right: 15px;
border-radius: 50%;
`

export const TD30 = styled.td`
${Th_Td_Style}
width: 30%;
`

export const TD70 = styled.td`
${Th_Td_Style}
width: 70%;
`

export const TD70Ellipsis = styled.td`
${Th_Td_Style}
max-width: 0;
width: 70%;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`

export const TD60Ellipsis = styled.td`
${Th_Td_Style}
max-width: 0;
width: 60%;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`

export const TD50Ellipsis = styled.td`
${Th_Td_Style}
max-width: 0;
width: 50%;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`


export const TD30Small = styled.td`
${Th_Td_Style}
${Th_Td_Small_Style}
width: 30%;
`

export const TD70Small = styled.td`
${Th_Td_Style}
${Th_Td_Small_Style}
width: 70%;
`

export const TD70EllipsisSmall = styled.td`
${Th_Td_Style}
${Th_Td_Small_Style}
max-width: 0;
width: 70%;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`

export const Table = styled.table`
${TableStyle}
${TableHoverStyle}
`

export const TableStripped = styled.table`
${TableStyle}

tbody {
    tr {
        &:nth-of-type(odd) {
            background-color: rgba(0,0,0,.05);
        }
    }
}

${TableHoverStyle}
`

export const TableStrippedBordered = styled.table`
${TableStyle}

thead {
    tr {
        th {
            &:not(:last-child) {
                border-right: 1px solid #dee2e6 !important;
            }
        }
    }
}

tbody {
    tr {
        &:nth-of-type(odd) {
            background-color: rgba(0,0,0,.05);
        }

        td {
            &:not(:last-child) {
                border-right: 1px solid #343a40;
            }
        }

        &:not(:first-child) {
            td {
                border-top: 1px solid #343a40;
            }
        }
    }
}

${TableHoverStyle}
`

export const TableFooter = styled.div`
display: flex;
align-items: center;
margin-left: -15px;
margin-right: -15px;

@media (max-width: 767px) {
    flex-wrap: wrap;
}
`

export const TableFooterInfo = styled.div`
padding-left: 15px;
padding-right: 15px;
font-size: 14px;
line-height: 16px;

@media (max-width: 767px) {
    width: 100%;
}
`

export const TableFooterPagination = styled.div`
padding-left: 15px;
padding-right: 15px;
flex: 1;

& > ul {
    justify-content: flex-end;

    @media (max-width: 767px) {
        justify-content: center;
    }
}

`;

export const AdminModalBackdrop = styled.div`
position: fixed;
z-index: 9998;
top: 0;
left: 0;
right: 0;
bottom: 0;
/* opacity: 0;
visibility: hidden; */
-webkit-transition: .6s all;
transition: .6s all;
background-color: rgba(0,0,0,0.5);

@media (min-width: 768px) {
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
}

&.show {
    visibility: visible;
    opacity: 1;
}
`

const AdminModalStyle = css`
position: fixed;
z-index: 9999;
//top: 0;
top: 50%;
left: 50%;
width: 600px;
max-width: 100%;
/* opacity: 0;
visibility: hidden; */
-webkit-transition: .6s all;
transition: .6s all;
transform: translate(-50%, -50%);
background-color: white;
border-radius: 5px;

&.show {
    top: 50%;
    visibility: visible;
    opacity: 1;
}
`

export const AdminModal = styled.div`
${AdminModalStyle}
`

export const AdminModalForm = styled.form`
${AdminModalStyle}
`

export const AdminModalHeader = styled.div`
font-size: 14px;
color: #000;
font-weight: 700;
padding: 5px 10px;
box-shadow: 0px 0px 5px rgb(0 0 0 / 15%);
background-color: #ebebeb;
border-radius: 5px 5px 0 0;
`

export const AdminModalBody = styled.div`
font-size: 14px;
color: #000;
padding: 20px 10px;
`

export const AdminModalFooter = styled.div`
font-size: 14px;
color: #000;
padding: 5px 10px;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
background-color: #ebebeb;
border-radius: 0 0 5px 5px;
text-align: right;

${AdminBtn} + ${AdminBtn}, 
${AdminBtn} + ${AdminBtnLight}, 
${AdminBtnLight} + ${AdminBtnLight},
${AdminBtnLight} + ${AdminBtn} {
    margin-left: 5px;
}
`

export const AdminText = styled.div`
color: #000;

a {
    color: var(--fire-red) !important;

    &:hover {
        text-decoration: underline;
    }
}
`