/**
 * @typedef {Object} Currency
 * @property {String} code   - The Currency Code
 * @property {String} symbol - The Currency Symbol
 * @property {String} name   - The Currency Name
 */

export const defaultCurrency = 'USD';

/**
 * Get Array of Currencies
 * 
 * @returns {Currency[]} - Array of Currency Objects with code, symbol and name properties 
 */
export const getCurrencies = () => {
    return [
        {
            code: 'BTC', 
            symbol: 'BTC', 
            name: 'Bitcoin'
        },
        {
            code: 'BNB', 
            symbol: 'BNB', 
            name: 'Binance Coin'
        },
        {
            code: 'BUSD', 
            symbol: 'BUSD', 
            name: 'BUSD'
        },
        {
            code: 'USDT', 
            symbol: 'USDT', 
            name: 'Tether'
        },
        {
            code: 'USD', 
            symbol: '$', 
            name: 'US Dollar'
        },
        {
            code: 'EUR', 
            symbol: '€', 
            name: 'Euro'
        },
        {
            code: 'GBP', 
            symbol: '£', 
            name: 'Pounds sterling'
        },
        {
            code: 'JPY', 
            symbol: '¥', 
            name: 'Japanese yen'
        },
        {
            code: 'CHF', 
            symbol: 'CHF', 
            name: 'Swiss franc'
        },
        {
            code: 'DKK', 
            symbol: 'kr', 
            name: 'Danish krone'
        }
    ];
};

/**
 * Get Currency Object for a given Currency Code
 * 
 * @param {String} code 
 * 
 * @returns {Currency}
 */
export const getCurrency = (code) => {
    const currencies = getCurrencies();

    if(!code) {
        code = defaultCurrency;
    }

    let result  = currencies.filter(function(o) {
        return o.code === code;
    });

    return result ? result[0] : null;
};