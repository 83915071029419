import styled from 'styled-components'

export const ImageWrapper = styled.div`
padding-left: 20px;
padding-right: 20px;
.image-404 {
    max-width: 100%
}
.text-404 {
    margin-top: 60px;
    font-family: Inter;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 22px;
/* identical to box height, or 157% */

text-align: center;

color: #3A3A3A;
}
@media (max-width: 1100px) {
    .image-404 {
        max-width: 400px
    }
}
@media (max-width: 900px) {
    .image-404 {
        max-width: 350px
    }
}
@media (max-width: 700px) {
    .image-404 {
        max-width: 300px
    }
}
@media (max-width: 550px) {
    .image-404 {
        max-width: 250px
    }
    .text-404 {
        margin: auto;
        margin-top: 60px;
        text-align: center;
        max-width: 350px;
    }
}
@media (max-width: 340px) {
    .image-404 {
        max-width: 200px
    }
}
@media (max-width: 250px) {
    .image-404 {
        max-width: 150px
    }
}
`
