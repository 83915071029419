import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useMoralis } from "react-moralis";
import AuthorBanner from '../AuthorProfile/Page/Banner/Banner';
/* import AuthorInfo from '../AuthorProfile/Page/Info/Info'; */
import Breadcrumb from '../Breadcrumb/Breadcrumb';
/* import Tabs from '../Tabs/Tabs';
import NFTItem from '../Account/Item'; */
import { bannerPlaceholder, getAvatarPlaceholder } from '../../helpers/fileUpload';
import { Container, Divider} from './styles'
import NFT from '../NFT/NFT';
import AuthorInfoLoader from '../AuthorProfile/Page/Info/InfoLoader';
import AuthorInfo from '../AuthorProfile/Page/Info/Info';
import BannerLoader from '../AuthorProfile/Page/Banner/BannerLoader';
import BreadcrumbsLoader from '../Breadcrumb/BreadcrumbsLoader';
import NFTLoader from '../NFT/NFTLoader';
import TitleLeftLoader from '../Loaders/TitleLeftLoader';
import { ProfileNoItems } from '../Account/styles';
import { Link } from 'react-router-dom';
/* import { Helmet } from "react-helmet";
import reactImageSize from 'react-image-size'; */

const Artist = () => {
    const { Moralis } = useMoralis();
    const params = useParams();
    const [ loading, setLoading ] = useState(true);
    const [ NFTs, setNFTs] = useState([]);
    const [ artist, setArtist ] = useState();
    const [ artistInfo, setArtistInfo ] = useState();

    document.title = 'Loading | Block of Change';

    useEffect(()=> {
        const loadArtist = async () => {
            const response = await Moralis.Cloud.run('getArtistByURL', {slug: `${params.slug}`});
            if(response) {
                if(response.avatar === null) {
                    response.avatar = getAvatarPlaceholder();
                    /* response.avatarWidth = 310;
                    response.avatarHeight = 310;
                    response.fileExt = 'image/jpeg'; */
                }/*  else {
                    response.fileExt = 'image/jpeg';
                    
                    const ext = response.avatar.substring(response.avatar.lastIndexOf(".")+1);
                    if(ext === 'png') {
                        response.fileExt = 'image/png';
                    } else if(ext === 'gif') {
                        response.fileExt = 'image/gif';
                    }

                    try {
                        const { width, height } = await reactImageSize(response.avatar);
                        
                        response.avatarWidth = width;
                        response.avatarHeight = height;
                    } catch {
                        response.avatarWidth = null;
                        response.avatarHeight = null;
                    }
                } */

                if(response.banner === null) {
                    response.banner = bannerPlaceholder;
                }

                document.body.classList = 'has-banner';

                const info = {
                    username: response.name, 
                    bio: response.bio, 
                    avatar: response.avatar, 
                    isArtist: true, 
                    socialData: []
                };

                if(response.dates) {
                    info.dates = response.dates;
                }

                await loadNFTs();

                setArtistInfo(info);
                setArtist(response);
            }

            setLoading(false)
        };

        const loadNFTs = async () => {
            const response = await Moralis.Cloud.run('getArtistItems', {slug: `${params.slug}`});
            setNFTs(response);
        };

        loadArtist();
    }, [Moralis]);

    if(loading) {
        return (
            <>
                <BannerLoader />
                
                <BreadcrumbsLoader />
                
                <Container className="container">
                    <AuthorInfoLoader />

                    <Divider />

                    <div className="col-12 col-md-8 col-lg-7" style={{marginTop: 48, paddingLeft: 0}}>
                        <TitleLeftLoader />
                    </div>

                    <div label="Owned">
                        <div className="row items" style={{ marginLeft: -15, marginRight: -15 }}>
                            {[0, 1, 2, 4].map((idx) => (
                                <div className="col-12 col-sm-6 col-lg-3" key={`nft-${idx}`} style={{marginTop: 30}}>
                                    <NFTLoader style={{boxShadow: '0 0 2px rgba(0, 0, 0, .15), 0 2px 2px rgba(0, 0, 0, .05)', width: '100%', height: 'auto'}} />
                                </div>
                            ))}
                        </div>
                    </div>
                </Container>
            </>
        );
    }

    document.title = `${artist.name} - Artists | Block of Change`;

    return (
        <>
            {/* <Helmet>
                <title>{artist.name} - Artists | Block of Change</title>
                <meta name="description" content={artist.bio ? artist.bio : 'Block of Change - Your Blockchain Art Broker'} />
                <meta name="DC.title" content={`${artist.name} - Artists | Block of Change`} />
                <meta name="DC.description" content={artist.bio ? artist.bio : 'Block of Change - Your Blockchain Art Broker'} />
                <meta name="pinterest" content="nopin" />
                <meta name="rights" content="2021 © Block of Change, All Rights Reserved." />
                <meta name="dcterms.rights" content="2021 © Block of Change, All Rights Reserved." />
                <meta name="dcterms.dateCopyrighted" content="2021" />
                <meta name="og:site_name" content="Block of Change" />
                <meta name="og:title" content={`${artist.name} - Artists | Block of Change`} />
                <meta name="og:type" content="website" />
                <meta name="og:description" content={artist.bio ? artist.bio : 'Block of Change - Your Blockchain Art Broker'} />
                <meta name="og:url" content={`${window.location.href}`} />
                <link rel="canonical" content={`${window.location.href}`} />
                <meta name="og:image" content={`${artist.avatar}`} />
                { artist.avatarWidth && <meta name="og:image:width" content={artist.avatarWidth} /> }
                { artist.avatarHeight && <meta name="og:image:height" content={artist.avatarHeight} /> }
                <meta name="og:image:type" content={artist.fileExt} />
            </Helmet> */}

            <AuthorBanner image={artist.banner} name={artist.username} />
            
            <Breadcrumb title="Artist Profile" subpagelink="/artists" subpage="Artists" page={artist.name} />
            
            <Container className="container">
                <>
                    <AuthorInfo artist={artistInfo} />

                    <Divider />
                    
                    <div className="col-12 col-md-8 col-lg-7" style={{marginTop: 48, paddingLeft: 0}}>
                        <div className="intro mb-4">
                            <span>Collectibles</span>
                            <h3 className="mt-3 mb-0">Artworks</h3>
                        </div>
                    </div>

                    {NFTs.length === 0 ? (
                        <ProfileNoItems>
                            <h4>No items to display</h4>
                            <p>Stay toned. Meanwhile why don't you explore our other masterworks.</p>
                            <Link to="/explore" className="btn btn-bordered-black">Explore</Link>
                        </ProfileNoItems>
                    ) : (
                        <div label="Owned">
                            <div className="row items" style={{ marginLeft: -15, marginRight: -15 }}>
                                {
                                    NFTs.map((item, idx) => <NFT gridNumber={4} key={`nft-${idx}`} item={item} author={false} /> )
                                }
                            </div>
                        </div>
                    )}
                </>
            </Container>
        </>
    )
};

export default Artist;