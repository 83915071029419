import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const initData = {
    pre_heading: "Help Center",
    heading: "How can we help you?",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit."
}

const data = [
    {
        id: "1",
        icon: "icon icon-plane text-effect",
        link: "/help-center/getting-started",
        title: "Getting Started",
        content: "Learn how to create an account, set up your wallet, and what you can do on Block of Change"
    },
    {
        id: "2",
        icon: "icon icon-note text-effect",
        link: "/help-center/creating",
        title: "Creating",
        content: "Learn how to create your very first NFT and how to create your NFT collections so you can begin selling and sharing"
    },
    {
        id: "3",
        icon: "icon icon-handbag text-effect",
        link: "/help-center/buying",
        title: "Buying",
        content: "Learn how to purchase your first NFT and understand gas fees and what's gas free on Block of Change"
    },
    {
        id: "4",
        icon: "icon icon-chart text-effect",
        link: "/help-center/selling",
        title: "Selling",
        content: "Learn how list your NFTs for sale and understand the different ways to list your NFTs"
    },
    {
        id: "5",
        icon: "icon icon-link text-effect",
        link: "/partners",
        title: "Partners",
        content: "Learn how you can partner with us to showcase your NFT drops"
    },
    {
        id: "6",
        icon: "icon icon-flag text-effect",
        title: "Developers",
        link: "/help-center/developers",
        content: "Learn how you can develop with Block of Change & sell them on Marketplace"
    }
]

class HelpCenter extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        document.title = 'Help Center | Block of Change';

        return (
            <section className="help-center-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            <div className="intro text-center">
                                <span>HOW CAN WE HELP YOU?</span>
                                <h3 className="mt-3 mb-0">Help Center</h3>
                                <p style={{fontFamily: 'Inter', fontSize: 14, color: '#3A3A3A'}}>Here you may find the answer to some of your questions. If you don’t find what you’re looking for, <a href="mailto:info@blockofchange.com" style={{color: '#3A3A3A', textDecoration: 'underline'}}>click here</a> and contact us for more information.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center items">
                        <div className="col-12 col-md-6 col-lg-4 item">
                            <div className="card help-card" style={{minHeight: 218, padding: 0, paddingTop: 32, paddingBottom: 32, paddingLeft: 16, paddingRight: 16 }}>
                                <Link to="/help-center/getting-started" className="d-block text-center">
                                    <img src="/img/getting-started.svg" alt="Getting Started"/>
                                    <h4 style={{fontFamily: 'Playfair Display', fontSize: 18, color: 'black'}}>Getting started</h4>
                                    <p style={{fontFamily: 'Inter', fontSize: 14, color: '#3A3A3A'}}>Discover how to setup your wallet and create your account</p>
                                </Link>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-4 item">
                            <div className="card help-card" style={{minHeight: 218, padding: 0, paddingTop: 32, paddingBottom: 32, paddingLeft: 16, paddingRight: 16 }}>
                                <Link to="/help-center/buying" className="d-block text-center">
                                    <img src="/img/bag.svg" alt="Buying"/>
                                    <h4 style={{fontFamily: 'Playfair Display', fontSize: 18, color: 'black'}}>Buying</h4>
                                    <p style={{fontFamily: 'Inter', fontSize: 14, color: '#3A3A3A'}}>Learn how to start your collection on Block of Change</p>
                                </Link>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-4 item">
                            <div className="card help-card" style={{minHeight: 218, padding: 0, paddingTop: 32, paddingBottom: 32, paddingLeft: 16, paddingRight: 16 }}>
                                <Link to="/help-center/selling" className="d-block text-center">
                                    <img src="/img/selling.svg" alt="Selling"/>
                                    <h4 style={{fontFamily: 'Playfair Display', fontSize: 18, color: 'black'}}>Selling</h4>
                                    <p style={{fontFamily: 'Inter', fontSize: 14, color: '#3A3A3A'}}>Understand how to sell the the masterworks you've collected</p>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="text-center" style={{padding: '0 15px', marginTop: '115px' }}>
                        <a href="mailto:info@blockofchange.com" style={{fontSize: '14px', lineHeight: '22px', color: '#000', textDecoration: 'underline'}}>Contact us</a>
                    </div>
                </div>
            </section>
        );
    }
}

export default HelpCenter;