import ContentLoader from "react-content-loader"

const FilterDropdownLoader = (props) => {
    return (
        <ContentLoader 
            speed={2}
            width={160}
            height={40}
            viewBox="0 0 160 40"
            backgroundColor="#d4d4d4"
            foregroundColor="#b0b0b0"
            {...props}
        >
            <rect x="0" y="0" rx="2" ry="2" width="160" height="40" /> 
        </ContentLoader>
    )
};

export default FilterDropdownLoader;