import React from "react";
import { ProcessingBackdrop, ProcessingOuter, ProcessingInner, ProcessingAnimationEl } from "./styles";

const ProcessingModal = () => {
    return (
        <ProcessingBackdrop>
            <ProcessingOuter>
                <ProcessingInner>
                    <ProcessingAnimationEl>
                        <div></div>
                        <div></div>
                        <div></div>
                    </ProcessingAnimationEl>
                </ProcessingInner>
            </ProcessingOuter>
        </ProcessingBackdrop>
    );
};

export default ProcessingModal;