import React from 'react';
import Header from '../components/Header/Header';
//import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import ArtistProfile from '../components/Artist/Artist';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import { StickyFooterBody, StickyFooterMain } from '../sharedComponents/StickyFooter';

const Artist = () => { 
    return (
        <StickyFooterMain>
            <StickyFooterBody>
                <Header hasBanner={true} />
                <ArtistProfile />
            </StickyFooterBody>
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
        </StickyFooterMain>
    );    
}

export default Artist;