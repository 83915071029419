import React, { useEffect, useState } from 'react';
/* import axios from 'axios'; */
import { useMoralis } from "react-moralis";
import { Section, Container } from '../../Elements/styles';
import getWindowDimensions  from '../../../helpers/getWindowDimensions';
import { TableStripped, TableArtistPhoto, TableTopMenu, Td, ThDarkSmall, AdminBtnLink, TableFooter, TableFooterInfo, TableFooterPagination, AdminBtnSecondaryLink, TD50Ellipsis } from '../styles';
import { useHistory } from 'react-router';
import PaginationAjax from '../../Elements/Pagination/PaginationAjax';
import { paginationInfo } from '../../Elements/Pagination/helper';

const AdminArtists = () => {
    document.title = 'Artists - Admin | Block of Change';
    const { Moralis } = useMoralis();
    const history = useHistory();
    const { width } = getWindowDimensions();

    //const [page, setPage] = useState(0)
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([])
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pages, setPages] = useState(1);
    const [total, setTotal] = useState(0);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    const editArtist = (id) => {
        history.push(`/admin/artists/edit/${id}`)
    };

    const loadArtists = async (newPage) => {
        if(!isLoadingMore && newPage <= pages) {
            setIsLoadingMore(true);

            const response = await Moralis.Cloud.run('getArtists', {
                page: newPage,
                items: itemsPerPage,
            });

            setPages(response.pages);
            setIsLoadingMore(false);
            setItems(response.items);
            setTotal(response.total);
            setPage(newPage);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadArtists(1);
    }, []);

    return (
        <Section style={{ paddingTop: 40 }}>
            <Container style={width > 767 ? {} : { paddingLeft: 15, paddingRight: 15 }}>
                <TableTopMenu>
                    {width < 768 && <AdminBtnSecondaryLink to="/admin" style={{marginRight: 5}}>Back</AdminBtnSecondaryLink> }
                    
                    <AdminBtnLink to="/admin/artists/new">Add</AdminBtnLink>
                </TableTopMenu>
                
                <TableStripped>
                    <thead>
                        <tr>
                            <ThDarkSmall>Artist</ThDarkSmall>
                            <ThDarkSmall>Bio</ThDarkSmall>
                            <ThDarkSmall>Is Featured</ThDarkSmall>
                            <ThDarkSmall>Is Active</ThDarkSmall>
                            <ThDarkSmall>Updated At</ThDarkSmall>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ? 
                            <tr>
                                <Td colSpan={5}>Loading...</Td>
                            </tr>
                            : (
                                items.length === 0 ? 
                                <tr>
                                    <Td colSpan={5}>No data...</Td>
                                </tr>
                                : (
                                    items.map((artist, idx) => {
                                        const date = new Date(artist.updatedAt);
                                        return (
                                            <tr key={`ts_${idx}`} onClick={() => { editArtist(artist.id) }}>
                                                <Td>
                                                    <TableArtistPhoto src={artist.avatar} alt={artist.name} />
                                                    {artist.name}
                                                </Td>
                                                <TD50Ellipsis>{artist.bio !== null ? artist.bio : '--'}</TD50Ellipsis>
                                                <Td>{artist.is_featured ? 'Yes' : 'No' }</Td>
                                                <Td>{artist.is_active ? 'Yes' : 'No' }</Td>
                                                <Td>{date.toDateString()}, {date.toLocaleTimeString()}</Td>
                                            </tr>
                                        );
                                    })
                                )
                            )
                        }
                    </tbody>
                    {!loading && (
                        <tfoot>
                            <tr>
                                <td colSpan={5}>
                                    <TableFooter>
                                        <TableFooterInfo>{paginationInfo(page, itemsPerPage, total)}</TableFooterInfo>
                                        <TableFooterPagination>
                                            <PaginationAjax currentPage={page} pages={pages} pageLimit={6} parentMethod={loadArtists} />
                                        </TableFooterPagination>
                                    </TableFooter>
                                </td>
                            </tr>
                        </tfoot>
                    )}
                </TableStripped>
            </Container>
        </Section>
    );
}

export default AdminArtists;