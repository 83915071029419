import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

const BtnBlock = css`
display: block;
width: 100%;
`

const BtnStyle = css`
display: inline-block;
vertical-align: middle;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
position: relative;
font-size: 1rem !important;
font-weight: 700 !important;
line-height: 1 !important;
text-align: center;
padding: 16px 34px;
border-radius: 100px;
outline: 0 none;
cursor: pointer;
z-index: 1;
transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

`

const BtnWhiteStyle = css`
background: white;
border: 1px solid #fff;
border-color: var(--black-color) !important;
color: var(--black-color) !important;

&:hover {
    background-color: var(--black-color);
    color: var(--white-color) !important;
}
`

const BtnBlackStyle = css`
border: 1px solid var(--black-color) !important;
background-color: var(--black-color);
color: var(--white-color) !important;

&:hover {
    background-color: var(--white-color);
    color: var(--black-color) !important;
}
`

export const BtnWhiteButton = styled.button`
${BtnStyle}
${BtnWhiteStyle}
`

export const BtnWhiteLink = styled(Link)`
${BtnStyle}
${BtnWhiteStyle}
`

export const BtnWhiteButtonBlock = styled.button`
${BtnStyle}
${BtnWhiteStyle}
${BtnBlock}
`

export const BtnWhiteLinkBlock = styled.a`
${BtnStyle}
${BtnWhiteStyle}
${BtnBlock}
`

export const BtnBlackButton = styled.button`
${BtnStyle}
${BtnBlackStyle}
`

export const BtnBlackLink = styled.a`
${BtnStyle}
${BtnBlackStyle}
`

export const BtnBlackButtonBlock = styled.button`
${BtnStyle}
${BtnBlackStyle}
${BtnBlock}
`

export const BtnBlackLinkBlock = styled(Link)`
${BtnStyle}
${BtnBlackStyle}
${BtnBlock}
`