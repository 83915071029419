import React, { useEffect, useState } from 'react';
import { useMoralis } from 'react-moralis';
import NFT from '../NFT/NFT';
import Slider from "react-slick";
import '../Elements/Slider.css';
import NFTLoader from '../NFT/NFTLoader';
import getWindowDimensions  from '../../helpers/getWindowDimensions';
import { BtnWhiteLink } from '../Elements/Buttons';
import { Link } from 'react-router-dom';

const ExploreOne = ({except}) => {
    const settings = {
        arrows: false,
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        swipe: true,
        draggable: true, 
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                },
            },
            {
                breakpoint: 570,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                }
            }
        ]
    };

    const { Moralis } = useMoralis();
    const { width } = getWindowDimensions();
    const [ loading, setLoading ] = useState(true);
    const [ featuredNFTs, setFeaturedNFTs ] = useState([]);

    const requestParams = () => {
        const params = { items: 6 };

        if(except) {
            params.except = except;
        }

        return params;
    }
    
    const getFeaturedNFTs = async () => {
        const result = await Moralis.Cloud.run('getFeaturedNFTs', requestParams());
        setFeaturedNFTs(result);
        setLoading(false);
    };

    useEffect(() => {
        getFeaturedNFTs();
    }, []);

    if(!loading && featuredNFTs.length === 0) {
        return ('');
    }

    return (
        <section className="explore-area" style={{padding: (except ? 0 : '64px 0 0')}}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="intro d-flex justify-content-between align-items-end m-0">
                            <div className="intro-content">
                                <span>Exclusive Assets</span>
                                <h3 className="mt-3 mb-0">Explore</h3>
                            </div>
                            {width > 767 && (
                                <div className="intro-btn">
                                    <Link className="btn content-btn custom-see-all-btn" to="/explore">View All</Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {loading ? (
                    <Slider {...settings}>
                        {[0, 1, 2].map((idx) => (
                            <div key={`featured_${idx}`} style={{boxShadow: '0px 0px 2px rgba(0, 0, 0, .15), 0px 2px 2px rgba(0, 0, 0, .05)'}}>
                                <NFTLoader />
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <Slider {...settings}>
                        {featuredNFTs.map((item, idx) => <NFT key={`featured-${idx}`} gridNumber={0} item={item} /> )}
                    </Slider>
                )}

                {width < 768 && (
                    <div style={{textAlign: 'center', marginTop: 37}}>
                        <BtnWhiteLink to="/explore">Explore art</BtnWhiteLink>
                    </div>
                )}
            </div>
        </section>
    );
}

export default ExploreOne;