import styled, { css } from 'styled-components'

const AlertStyle = css`
position: relative;
padding: 0.75rem 1.25rem;
margin-bottom: 1rem;
border: 1px solid transparent;
border-radius: 0.25rem;
word-break: break-word;
`

export const AlertSecondary = styled.div`
${AlertStyle}
color: #383d41;
background-color: #e2e3e5;
border-color: #d6d8db;
`

export const AlertPrimary = styled.div`
${AlertStyle}
color: #004085;
background-color: #cce5ff;
border-color: #b8daff;
`

export const AlertDanger = styled.div`
${AlertStyle}
color: #721c24;
background-color: #f8d7da;
border-color: #f5c6cb;
`

export const AlertSuccess = styled.div`
${AlertStyle}
color: #155724;
background-color: #d4edda;
border-color: #c3e6cb;
`

export const AlertWarning = styled.div`
${AlertStyle}
color: #856404;
background-color: #fff3cd;
border-color: #ffeeba;
`

export const AlertInfo = styled.div`
${AlertStyle}
color: #0c5460;
background-color: #d1ecf1;
border-color: #bee5eb;
`