import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TabListItem } from './style';

class Tab extends Component {
    static propTypes = {
        activeTab: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    };
  
    onClick = () => {
        const { label, onClick } = this.props;
        onClick(label);
    }
  
    render() {
        const {
            onClick,
            props: {
                activeTab,
                label,
            },
        } = this;

        let className = '';
  
        if (activeTab === label) {
            className = 'active';
        }

        return (
            <TabListItem
             className={className}
             onClick={onClick}
            >
                <span>
                    <span>{label}</span>
                </span>
            </TabListItem>
        );
    }
}
  
export default Tab;