import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';
import { TabsWrapper, TabContent, TabListDiv, TabListOl, TabListContainer } from './style';
//import './Tabs.css';

class Tabs extends Component {
    static propTypes = {
        additionalClass: PropTypes.string,
        wrapInContainer: PropTypes.bool,
        style: PropTypes.instanceOf(Object),
        children: PropTypes.instanceOf(Array).isRequired,
    }
  
    constructor(props) {
        super(props);
  
        this.state = {
            activeTab: this.props.children[0].props.label,
        };
    }
  
    onClickTabItem = (tab) => {
        this.setState({ activeTab: tab });
    }

    render() {
        const {
            onClickTabItem,
            props: {
                additionalClass,
                wrapInContainer,
                style,
                children,
            },
            state: {
                activeTab,
            }
        } = this;
    
        return (
            <TabsWrapper className={`${additionalClass}`} style={style}>
                {wrapInContainer ? (
                    <TabListDiv>
                        <TabListContainer>
                            {children.map((child) => {
                                if(typeof child !== "undefined") {
                                    console.log('Child: ', child);
                                    const { label } = child.props;

                                    return (
                                        <Tab
                                        activeTab={activeTab}
                                        key={label}
                                        label={label}
                                        onClick={onClickTabItem}
                                        />
                                    );
                                }

                                return ('');
                            })}
                        </TabListContainer>
                    </TabListDiv>
                ) : (
                    <TabListOl>
                        {children.map((child) => {
                            if(typeof child !== "undefined") {
                                const { label } = child.props;

                                return (
                                    <Tab
                                    activeTab={activeTab}
                                    key={label}
                                    label={label}
                                    onClick={onClickTabItem}
                                    />
                                );
                            }

                            return ('');
                        })}
                    </TabListOl>
                )}
                <TabContent>
                    {children.map((child) => {
                        if (typeof child === "undefined" || child.props.label !== activeTab) return undefined;
                        return child.props.children;
                    })}
                </TabContent>
            </TabsWrapper>
        );
    }
}

Tabs.defaultProps = {
    additionalClass: '', 
    wrapInContainer: false, 
    style: {}
}

export default Tabs;