/* const speeds = {
	slow: 600,
	fast: 200,
	// Default speed
	_default: 400
}; */

const fade = (type, el, speed) => {
    let isIn = type === 'in',
        opacity = isIn ? 0 : 1,
        interval = 50,
        duration = speed,
        gap = interval / duration;

    if(isIn) {
        el.style.display = 'inline';
        el.style.opacity = opacity;
    }

    function func() {
        opacity = isIn ? opacity + gap : opacity - gap;
        el.style.opacity = opacity;
    
        if(opacity <= 0) {
            el.style.display = 'none';
            el.style.opacity = 1;
        }

        if(opacity <= 0 || opacity >= 1) window.clearInterval(fading);
    }
    
    var fading = window.setInterval(func, interval);
};

export const fadeIn = (el, speed = 200) => {
    fade('in', el, speed);
};

export const fadeOut = (el, speed = 200) => {
    fade('out', el, speed);
};

export const slideUp = (el, speed = 200) => {
    el.webkitAnimationPlayState = 'paused';

    el.style.transitionProperty = 'height, margin, padding';
    el.style.transitionDuration = speed + 'ms';
    el.style.boxSizing = 'border-box';
    el.style.height = el.offsetHeight + 'px';
    el.style.overflow = 'hidden';
    el.style.height = 0;
    el.style.paddingTop = 0;
    el.style.paddingBottom = 0;
    el.style.marginTop = 0;
    el.style.marginBottom = 0;
    window.setTimeout( () => {
        el.style.display = 'none';
        el.style.removeProperty('height');
        el.style.removeProperty('padding-top');
        el.style.removeProperty('padding-bottom');
        el.style.removeProperty('margin-top');
        el.style.removeProperty('margin-bottom');
        el.style.removeProperty('overflow');
        el.style.removeProperty('transition-duration');
        el.style.removeProperty('transition-property');
    }, speed);
};

export const slideDown = (el, speed = 200) => {
    el.style.removeProperty('display');
    let display = window.getComputedStyle(el).display;

    if (display === 'none') {
        display = 'block';
    }

    let height = el.offsetHeight;
    el.style.display = display;
    el.style.overflow = 'hidden';
    el.style.height = 0;
    el.style.paddingTop = 0;
    el.style.paddingBottom = 0;
    el.style.marginTop = 0;
    el.style.marginBottom = 0;
    el.style.boxSizing = 'border-box';
    el.style.transitionProperty = "height, margin, padding";
    el.style.transitionDuration = speed + 'ms';
    el.style.height = height + 'px';
    el.style.removeProperty('padding-top');
    el.style.removeProperty('padding-bottom');
    el.style.removeProperty('margin-top');
    el.style.removeProperty('margin-bottom');
    window.setTimeout( () => {
        el.style.removeProperty('height');
        el.style.removeProperty('overflow');
        el.style.removeProperty('transition-duration');
        el.style.removeProperty('transition-property');
    }, speed);
};