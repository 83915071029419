import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import Balance from '../components/Account/Balance';
import { StickyFooterBody, StickyFooterMain } from '../sharedComponents/StickyFooter';

const BalanceTheme = () => {
    document.body.className = '';

    return (
        <StickyFooterMain>
            <StickyFooterBody>
                <Header hasBanner={true} />    
                <Balance />
            </StickyFooterBody>
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
        </StickyFooterMain>
    );
}

export default BalanceTheme;