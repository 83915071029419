import React from 'react';
import Header from '../../../components/Header/Header';
import ArtistsAdminForm from '../../../components/Admin/Artists/Form';
import Footer from '../../../components/Footer/Footer';
import ModalSearch from '../../../components/Modal/ModalSearch';
import ModalMenu from '../../../components/Modal/ModalMenu';
import Scrollup from '../../../components/Scrollup/Scrollup';
import { StickyFooterBody, StickyFooterMain } from '../../../sharedComponents/StickyFooter';

const ArtistsAdmin = () => {
    document.body.className = '';
    
    return (
        <StickyFooterMain>
            <StickyFooterBody>
                <Header />
                <ArtistsAdminForm />
            </StickyFooterBody>
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
        </StickyFooterMain>
    );
}

export default ArtistsAdmin;