import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FormContainer from '../Mailchimp/FormContainer';
import { Link } from 'react-router-dom';

let host = window.location.hostname;
if ( window.location.port !== "" ) {
    host+= ':' + window.location.port;
}

const BASE_URL = `${window.location.protocol}//${host}/footer.json`;

const Footer = () => {
    const [ data, setData ] = useState({});
    const [ socialData, setSocialData ] = useState([]);
    const [ widgetData_1, setWidgetData1 ] = useState([]);
    const [ widgetData_2, setWidgetData2 ] = useState([]);
    const [ copyright, setCopyright ] = useState({
        left: ''
    });

    useEffect(() => {
        axios.get(`${BASE_URL}`)
            .then(res => {
                setData(res.data);
                setSocialData(res.data.socialData);
                setWidgetData1(res.data.widgetData_1);
                setWidgetData2(res.data.widgetData_2);
                setCopyright({
                    left: res.data.copyright.left
                });
            })
        .catch(err => console.log(err))
    }, [])

    return (
        <footer className="footer-area">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-3 res-margin">
                            <div className="footer-items">
                                <Link className="navbar-brand" to="/">
                                    <img src={data.img} alt="Block of Change Logo" />
                                </Link>
                                
                                <div className="social-icons d-flex">
                                    {socialData.map((item, idx) => {
                                        return (
                                            <a key={`sd_${idx}`} className={item.link} href={item.url} target="_blank" rel="noreferrer">
                                                <i className={item.icon} />
                                                <i className={item.icon} />
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 offset-lg-1 col-lg-2 res-margin">
                            <div className="footer-items">
                                <h4 className="footer-title">{data.widget_1}</h4>
                                <ul>
                                    {widgetData_1.map((item, idx) => {
                                        return (
                                            <li key={`wdo_${idx}`}>
                                                {item?.target === "_blank" 
                                                ? <a href={item.url} target="_blank" rel="nofollow noreferrer">{item.text}</a>
                                                : <Link to={item.url}>{item.text}</Link>}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-2 res-margin">
                            <div className="footer-items">
                                <h4 className="footer-title">{data.widget_2}</h4>
                                <ul>
                                    {widgetData_2.map((item, idx) => {
                                        return (
                                            <li key={`wdo_${idx}`}>
                                                {item?.target === "_blank" 
                                                ? <a href={item.url} target="_blank" rel="nofollow noreferrer">{item.text}</a>
                                                : <Link to={item.url}>{item.text}</Link>}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>

                        <FormContainer widgetTitle={data.widget_3} />
                    </div>
                </div>
            </div>
            
            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center">
                                <div className="copyright">{copyright.left}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;