import ContentLoader from "react-content-loader"

const TitleCenterLoader = (props) => {
    
    if(props.showphrase && props.showphrase === "true") {
        return (
            <ContentLoader 
                speed={2}
                width={625}
                height={155}
                viewBox="0 0 625 155"
                backgroundColor="#d4d4d4"
                foregroundColor="#b0b0b0" 
                style={{width: '100%', height: 'auto'}}
                {...props}
            >
                <rect x="269" y="0" rx="3" ry="3" width="87" height="10" />
                <rect x="122" y="50" rx="3" ry="3" width="381" height="40" />
                <rect x="37" y="130" rx="3" ry="3" width="551" height="15" />
            </ContentLoader>
        )
    }

    return (
        <ContentLoader 
            speed={2}
            width={625}
            height={100}
            viewBox="0 0 625 100"
            backgroundColor="#d4d4d4"
            foregroundColor="#b0b0b0" 
            style={{width: '100%', height: 'auto'}}
            {...props}
        >
            <rect x="269" y="0" rx="3" ry="3" width="87" height="10" />
            <rect x="212" y="50" rx="3" ry="3" width="201" height="40" />
        </ContentLoader>
    )
};

export default TitleCenterLoader;