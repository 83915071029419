import React, { useEffect, useState } from 'react';
/* import axios from 'axios'; */
import { useMoralis } from "react-moralis";
import { getAvatarPlaceholder } from '../../helpers/fileUpload';
import { Container, Intro, IntroRed, IntroSubTitle, IntroTitle } from '../Elements/styles';
import CardLoader from '../TopSeller/CardLoader';
import NoRoute from '../NoRoute/NoRoute';
import Card from './Card';
import BreadcrumbsLoader from '../Breadcrumb/BreadcrumbsLoader';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import TitleCenterLoader from '../Loaders/TitleCenterLoader';
import getWindowDimensions  from '../../helpers/getWindowDimensions';

const Artists = () => {
    document.title = 'Artists | Block of Change';
    const { Moralis } = useMoralis();
    const { width } = getWindowDimensions();
    const [data, setData] = useState({
        "preHeading": "Artists",
        "heading": "Our Artists",
        "content": "Here you can view our full list of artists in the BOC platform. Each artist was carefully chosen to provide the best selection for our community.",
    });

    
    //const [page, setPage] = useState(0)
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([])
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(1);
    const [buttonMoreVisible, setButtonMoreVisible] = useState(true)
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [lastScrollPosition, setLastScrollPosition] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true })
    
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const handleScroll = () => {
        const position = window.pageYOffset
        setScrollPosition(position)
    }

    useEffect(() => {
        if((scrollPosition > (lastScrollPosition + 300)) && !isLoadingMore) {
            setLastScrollPosition(scrollPosition);
            loadArtists();
        }
    }, [scrollPosition, isLoadingMore])

    useEffect(() => {
        if(page > 0 && (window.innerHeight >= document.body.scrollHeight || (document.body.scrollHeight - window.innerHeight) <= 300) && page < pages) {
            loadArtists();
        }
    }, [page]);

    /* useEffect(() => {
        console.log(scrollPosition);
        if (items.length === 4 && scrollPosition > 300) {
            loadAuthors(300);
        } else if (scrollPosition > 800) {
            loadAuthors(800);
        }
    }, [scrollPosition]) */

    const loadArtists = async (overridePage) => {
        const newPage = overridePage ? overridePage + 1 : page + 1;

        if(buttonMoreVisible && !isLoadingMore && newPage <= pages) {
            setButtonMoreVisible(false);
            setIsLoadingMore(true);

            const authorsInfo = await Moralis.Cloud.run('getArtists', {
                page: newPage,
                items: 4,
            });

            const curItems = items;
            for(let i=0; i < authorsInfo.items.length; ++i) {
                if(authorsInfo.items[i].avatar == null) {
                    authorsInfo.items[i].avatar = getAvatarPlaceholder()
                }

                curItems.push(authorsInfo.items[i]);
            }

            setPages(authorsInfo.total);
            setIsLoadingMore(false);
            setButtonMoreVisible(newPage < authorsInfo.pages)
            setItems(curItems);
            setPage(newPage);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadArtists();
    }, []);

    if(!loading && !items) {
        return <NoRoute />
    }

    return (
        <>
            {loading ? <BreadcrumbsLoader /> : <Breadcrumb title="Artists" subpage="Pages" page="Artists" />}

            <section className="popular-collections-area" style={{ paddingTop: (width < 768 ? 40 : 10) }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            {loading ? <TitleCenterLoader showphrase="true" /> : (
                                <Intro>
                                    <IntroRed>{data.preHeading}</IntroRed>
                                    <IntroTitle>{data.heading}</IntroTitle>
                                    <IntroSubTitle>{data.content}</IntroSubTitle>
                                </Intro>
                            )}
                        </div>
                    </div>
                    <div className="row items">
                        {!loading && items.map((artist, idx) => {
                            return (
                                <div key={`ts_${idx}`} className="col-12 col-sm-6 col-lg-3 item">
                                    <Card {...{...artist, showBannerMobile: true}} />
                                </div>
                            );
                        })}
                    </div>

                    {isLoadingMore && (
                        <Container>
                            <div className="row">
                                {[0, 1, 2, 4].map((idx) => (
                                    <div className="col-12 col-sm-6 col-lg-3" key={`nft-${idx}`} style={{marginTop: 30}}>
                                        <CardLoader style={{boxShadow: '0px 0px 2px rgb(0 0 0 / 15%), 0px 2px 2px rgb(0 0 0 / 5%)', width: '100%', height: 'auto'}} />
                                    </div>
                                )) }
                            </div>
                        </Container>
                    )}
                </div>
            </section>
        </>
    );
}

export default Artists;