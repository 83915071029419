import { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { AdminBtn, AdminText, TableStripped, TableStrippedBordered, TD30, TD70, TdSmall, ThDarkSmall } from "../Admin/styles";
import { Container, Intro, IntroTitle, Section } from "../Elements/styles";
import NoRoute from "../NoRoute/NoRoute";

const Setup = () => {
    const { user, Moralis } = useMoralis();
    const setupHasRun = true;
    const { error404, setError404 } = useState(false);
    const [ showingHelp, showHelp ] = useState(true);
    const [ setupRunning, setSetupRunning ] = useState(false);
    const [ setupRunned, setSetupRunned ] = useState(false);
    const [ adminRoleSet, setAdminRoleSet ] = useState(true);
    const [ adminRoleRunning, setAdminRoleSetRunning ] = useState(false);
    const [ categoryObjSet, setCategoryObjSet ] = useState(false);
    const [ categoryObjRunning, setCategoryObjRunning ] = useState(false);
    const [ artistObjSet, setArtistObjSet ] = useState(false);
    const [ artistObjRunning, setArtistObjRunning ] = useState(false);

    const setupAdminRole = async () => {
        setAdminRoleSetRunning(true);
        const roleACL = new Moralis.ACL();
        roleACL.setPublicReadAccess(true);
        const role = new Moralis.Role("Administrator", roleACL);
        await role.save();
        setAdminRoleSet(true);
        setAdminRoleSetRunning(false);
    };

    const setupCategoryObj = async () => {
        setCategoryObjRunning(true);
        
        const category = new Moralis.Object("Category");
        const categoryACL = new Moralis.ACL();
        categoryACL.setRoleWriteAccess("Administrator", true);
        category.setACL(categoryACL);
        category.save();
        
        setCategoryObjSet(true);
        setCategoryObjRunning(false);
    };

    const setupArtistObj = async () => {
        setArtistObjRunning(true);
        
        const artist = new Moralis.Object("Artist");
        const artistACL = new Moralis.ACL();
        artistACL.setRoleWriteAccess("Administrator", true);
        artist.setACL(artistACL);
        artist.save();
        
        setArtistObjSet(true);
        setArtistObjRunning(false);
    };

    const runSetup = async () => {
        setSetupRunning(true);
        await setupAdminRole();
        await setupCategoryObj();
        await setupArtistObj();

        setSetupRunned(true);
    }

    useEffect(() => {
        if(!user || !user.attributes.is_admin) {
            setError404(true);
        }
    }, [user]);

    if(error404) {
        return <NoRoute />
    }

    return (
        <Section style={{ paddingTop: 40 }}>
            <Container>
                <Intro>
                    <IntroTitle>Setup</IntroTitle>
                </Intro>
                
                <div className="text-right">
                    <AdminBtn onClick={() => showHelp(!showingHelp) }>{ showingHelp ? 'Hide Help' : 'Show Help' }</AdminBtn>
                    { !setupRunning && !setupRunned && !setupHasRun && <AdminBtn onClick={() => {
                        if(window.confirm('Are you sure you wan\'t to run the setup?')) {
                            runSetup();
                        }
                    }} style={{ marginLeft: 5 }}>Run setup</AdminBtn> }
                </div>

                {showingHelp && (
                    <AdminText>
                        <ol>
                            <li>Run the <strong>Setup</strong> if you haven't</li>
                            <li>In <a href="https://admin.moralis.io/servers" target="_blank" rel="noreferrer nofollow">Moralis Servers</a>, go to the desired Server and click on <strong>View Details</strong> &gt; <strong>Settings</strong> and <u>Disable</u> <strong>Client Class Creation</strong> and <strong>Historical User Sync (Transactions, NFTs and Tokens)</strong></li>
                            <li><strong>Category</strong> and <strong>Artist</strong> Class Level Permissions [already setted]
                                <TableStrippedBordered>
                                    <thead>
                                        <tr>
                                            <ThDarkSmall></ThDarkSmall>
                                            <ThDarkSmall colSpan={3} style={{ textAlign: 'center' }}>Read</ThDarkSmall>
                                            <ThDarkSmall colSpan={3} style={{ textAlign: 'center' }}>Write</ThDarkSmall>
                                            <ThDarkSmall style={{ textAlign: 'center' }}>Add</ThDarkSmall>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <TdSmall><strong>Public</strong></TdSmall>
                                            <TdSmall><strong>Get:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Find:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Count:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Create:</strong> No</TdSmall>
                                            <TdSmall><strong>Update:</strong> No</TdSmall>
                                            <TdSmall><strong>Delete:</strong> No</TdSmall>
                                            <TdSmall><strong>Add:</strong> No</TdSmall>
                                        </tr>

                                        <tr>
                                            <TdSmall><strong>Authenticated</strong></TdSmall>
                                            <TdSmall><strong>Get:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Find:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Count:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Create:</strong> No</TdSmall>
                                            <TdSmall><strong>Update:</strong> No</TdSmall>
                                            <TdSmall><strong>Delete:</strong> No</TdSmall>
                                            <TdSmall><strong>Add:</strong> No</TdSmall>
                                        </tr>

                                        <tr>
                                            <TdSmall><strong>Administrator</strong></TdSmall>
                                            <TdSmall><strong>Get:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Find:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Count:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Create:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Update:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Delete:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Add:</strong> Yes</TdSmall>
                                        </tr>
                                    </tbody>
                                </TableStrippedBordered>
                            </li>
                            <li><strong>Eth*</strong> Class Level Permissions [already setted]
                                <TableStrippedBordered>
                                    <thead>
                                        <tr>
                                            <ThDarkSmall></ThDarkSmall>
                                            <ThDarkSmall colSpan={3} style={{ textAlign: 'center' }}>Read</ThDarkSmall>
                                            <ThDarkSmall colSpan={3} style={{ textAlign: 'center' }}>Write</ThDarkSmall>
                                            <ThDarkSmall style={{ textAlign: 'center' }}>Add</ThDarkSmall>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <TdSmall><strong>Public</strong></TdSmall>
                                            <TdSmall><strong>Get:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Find:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Count:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Create:</strong> No</TdSmall>
                                            <TdSmall><strong>Update:</strong> No</TdSmall>
                                            <TdSmall><strong>Delete:</strong> No</TdSmall>
                                            <TdSmall><strong>Add:</strong> No</TdSmall>
                                        </tr>

                                        <tr>
                                            <TdSmall><strong>Authenticated</strong></TdSmall>
                                            <TdSmall><strong>Get:</strong> No</TdSmall>
                                            <TdSmall><strong>Find:</strong> No</TdSmall>
                                            <TdSmall><strong>Count:</strong> No</TdSmall>
                                            <TdSmall><strong>Create:</strong> No</TdSmall>
                                            <TdSmall><strong>Update:</strong> No</TdSmall>
                                            <TdSmall><strong>Delete:</strong> No</TdSmall>
                                            <TdSmall><strong>Add:</strong> No</TdSmall>
                                        </tr>

                                        <tr>
                                            <TdSmall><strong>CoreServices</strong></TdSmall>
                                            <TdSmall><strong>Get:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Find:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Count:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Create:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Update:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Delete:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Add:</strong> Yes</TdSmall>
                                        </tr>
                                    </tbody>
                                </TableStrippedBordered>
                            </li>

                            <li><strong>_EthAddress</strong> Class Level Permissions (records are only created when user signs the first time) [already setted]
                                <TableStrippedBordered>
                                    <thead>
                                        <tr>
                                            <ThDarkSmall></ThDarkSmall>
                                            <ThDarkSmall colSpan={3} style={{ textAlign: 'center' }}>Read</ThDarkSmall>
                                            <ThDarkSmall colSpan={3} style={{ textAlign: 'center' }}>Write</ThDarkSmall>
                                            <ThDarkSmall style={{ textAlign: 'center' }}>Add</ThDarkSmall>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <TdSmall><strong>Public</strong></TdSmall>
                                            <TdSmall><strong>Get:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Find:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Count:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Create:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Update:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Delete:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Add:</strong> Yes</TdSmall>
                                        </tr>

                                        <tr>
                                            <TdSmall><strong>Authenticated</strong></TdSmall>
                                            <TdSmall><strong>Get:</strong> No</TdSmall>
                                            <TdSmall><strong>Find:</strong> No</TdSmall>
                                            <TdSmall><strong>Count:</strong> No</TdSmall>
                                            <TdSmall><strong>Create:</strong> No</TdSmall>
                                            <TdSmall><strong>Update:</strong> No</TdSmall>
                                            <TdSmall><strong>Delete:</strong> No</TdSmall>
                                            <TdSmall><strong>Add:</strong> No</TdSmall>
                                        </tr>
                                    </tbody>
                                </TableStrippedBordered>
                            </li>

                            <li><strong>RateLimits</strong> Class Level Permissions (check docs at https://docs.moralis.io/moralis-server/web3-sdk/rate-limit) [already setted]
                                <TableStrippedBordered>
                                    <thead>
                                        <tr>
                                            <ThDarkSmall></ThDarkSmall>
                                            <ThDarkSmall colSpan={3} style={{ textAlign: 'center' }}>Read</ThDarkSmall>
                                            <ThDarkSmall colSpan={3} style={{ textAlign: 'center' }}>Write</ThDarkSmall>
                                            <ThDarkSmall style={{ textAlign: 'center' }}>Add</ThDarkSmall>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <TdSmall><strong>Public</strong></TdSmall>
                                            <TdSmall><strong>Get:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Find:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Count:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Create:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Update:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Delete:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Add:</strong> Yes</TdSmall>
                                        </tr>

                                        <tr>
                                            <TdSmall><strong>Authenticated</strong></TdSmall>
                                            <TdSmall><strong>Get:</strong> No</TdSmall>
                                            <TdSmall><strong>Find:</strong> No</TdSmall>
                                            <TdSmall><strong>Count:</strong> No</TdSmall>
                                            <TdSmall><strong>Create:</strong> No</TdSmall>
                                            <TdSmall><strong>Update:</strong> No</TdSmall>
                                            <TdSmall><strong>Delete:</strong> No</TdSmall>
                                            <TdSmall><strong>Add:</strong> No</TdSmall>
                                        </tr>
                                    </tbody>
                                </TableStrippedBordered>
                            </li>

                            <li><strong>Role, EventSync, NFTCancelSale, NFTMinted, NFTSold, NFTForSale, Session, Artist</strong> Class Level Permissions [already setted]
                                <TableStrippedBordered>
                                    <thead>
                                        <tr>
                                            <ThDarkSmall></ThDarkSmall>
                                            <ThDarkSmall colSpan={3} style={{ textAlign: 'center' }}>Read</ThDarkSmall>
                                            <ThDarkSmall colSpan={3} style={{ textAlign: 'center' }}>Write</ThDarkSmall>
                                            <ThDarkSmall style={{ textAlign: 'center' }}>Add</ThDarkSmall>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                            <TdSmall><strong>Public</strong></TdSmall>
                                            <TdSmall><strong>Get:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Find:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Count:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Create:</strong> No</TdSmall>
                                            <TdSmall><strong>Update:</strong> No</TdSmall>
                                            <TdSmall><strong>Delete:</strong> No</TdSmall>
                                            <TdSmall><strong>Add:</strong> No</TdSmall>
                                        </tr>
                                        <tr>
                                            <TdSmall><strong>Authenticated</strong></TdSmall>
                                            <TdSmall><strong>Get:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Find:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Count:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Create:</strong> No</TdSmall>
                                            <TdSmall><strong>Update:</strong> No</TdSmall>
                                            <TdSmall><strong>Delete:</strong> No</TdSmall>
                                            <TdSmall><strong>Add:</strong> No</TdSmall>
                                        </tr>
                                        <tr>
                                            <TdSmall><strong>CoreServices</strong></TdSmall>
                                            <TdSmall><strong>Get:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Find:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Count:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Create:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Update:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Delete:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Add:</strong> Yes</TdSmall>
                                        </tr>
                                        <tr>
                                            <TdSmall><strong>Administrator</strong></TdSmall>
                                            <TdSmall><strong>Get:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Find:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Count:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Create:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Update:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Delete:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Add:</strong> Yes</TdSmall>
                                        </tr>
                                    </tbody>
                                </TableStrippedBordered>
                            </li>

                            <li><strong>User</strong>
                                <TableStrippedBordered>
                                    <thead>
                                        <tr>
                                            <ThDarkSmall></ThDarkSmall>
                                            <ThDarkSmall colSpan={3} style={{ textAlign: 'center' }}>Read</ThDarkSmall>
                                            <ThDarkSmall colSpan={3} style={{ textAlign: 'center' }}>Write</ThDarkSmall>
                                            <ThDarkSmall style={{ textAlign: 'center' }}>Add</ThDarkSmall>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <TdSmall><strong>Public</strong></TdSmall>
                                            <TdSmall><strong>Get:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Find:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Count:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Create:</strong> No</TdSmall>
                                            <TdSmall><strong>Update:</strong> No</TdSmall>
                                            <TdSmall><strong>Delete:</strong> No</TdSmall>
                                            <TdSmall><strong>Add:</strong> No</TdSmall>
                                        </tr>
                                        *** TODO: Rest of users                                       
                                    </tbody>
                                </TableStrippedBordered>
                            </li>

                            <li><strong>NFTs</strong>
                                <TableStrippedBordered>
                                    <thead>
                                        <tr>
                                            <ThDarkSmall></ThDarkSmall>
                                            <ThDarkSmall colSpan={3} style={{ textAlign: 'center' }}>Read</ThDarkSmall>
                                            <ThDarkSmall colSpan={3} style={{ textAlign: 'center' }}>Write</ThDarkSmall>
                                            <ThDarkSmall style={{ textAlign: 'center' }}>Add</ThDarkSmall>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <TdSmall><strong>Public</strong></TdSmall>
                                            <TdSmall><strong>Get:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Find:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Count:</strong> Yes</TdSmall>
                                            <TdSmall><strong>Create:</strong> No</TdSmall>
                                            <TdSmall><strong>Update:</strong> No</TdSmall>
                                            <TdSmall><strong>Delete:</strong> No</TdSmall>
                                            <TdSmall><strong>Add:</strong> No</TdSmall>
                                        </tr>
                                        *** TODO: Rest of users                                       
                                    </tbody>
                                </TableStrippedBordered>
                            </li>

                            <li>Add user(s) to <strong>Administrator</strong> Role</li>
                            <li>Add all the <a href="/admin/categories" target="_blank"><strong>Categories</strong></a> and <a href="/admin/artists"target="_blank"><strong>Artists</strong></a> necessary
                                <br /><strong>Suggested Categories:</strong>
                                <ul>
                                    <li>Cubism</li>
                                    <li>Expressionism</li>
                                    <li>Impressionism</li>
                                    <li>Neoclassicism</li>
                                    <li>Romanticism</li>
                                    <li>Surrealism</li>
                                </ul>
                            </li>
                            <li>...</li>
                        </ol>
                    </AdminText>
                )}

                { ( setupRunning || setupRunned) && (
                    <TableStripped>
                        <thead>
                            <tr>
                                <ThDarkSmall>Process</ThDarkSmall>
                                <ThDarkSmall>Status</ThDarkSmall>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <TD70>Admin Role</TD70>
                                <TD30>{ !adminRoleSet ? (adminRoleRunning ? 'Running...' : 'Pending') : 'Finished' }</TD30>
                            </tr>
                            <tr>
                                <TD70>Category Object</TD70>
                                <TD30>{ !categoryObjSet ? (categoryObjRunning ? 'Running...' : 'Pending') : 'Finished' }</TD30>
                            </tr>
                            <tr>
                                <TD70>Artist Object</TD70>
                                <TD30>{ !artistObjSet ? (artistObjRunning ? 'Running...' : 'Pending') : 'Finished' }</TD30>
                            </tr>
                        </tbody>
                    </TableStripped>
                ) }
            </Container>
        </Section>
    );
};

export default Setup;