import React, { useEffect, useState } from 'react';
import { useMoralis } from "react-moralis";
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { Container, Section } from '../Elements/styles';
import { 
    Intro, 
    IntroRed, 
    IntroSubTitle, 
    IntroTitle, 
    RowJustifyContentCenter, 
    WalletItemCardDescription, 
    WalletItemCardDiv, 
    WalletItemCardInner, 
    WalletItemCardLink, 
    WalletItemCardLogo, 
    WalletItemCardName, 
    WalletItemCol
} from '../Elements/styles';
import { Link } from 'react-router-dom';

function Activity() {
    const params = queryString.parse(window.location.search);
    const history = useHistory();
    const { authenticate, enableWeb3 } = useMoralis();
    const [hasMetamask, setHasMetamask] = useState(false);
    
    useEffect(() => {
        if (typeof window.ethereum !== 'undefined'
        && window.ethereum.isMetaMask) {
            setHasMetamask(true);
        }
    }, []);

    const login = async (provider) => {
        await authenticate({
            provider: provider, 
            signingMessage: "Login to Block of Change!"
        });

        /* if(provider === 'walletconnect') {
            await Moralis.enable(provider);
        } */
        
        document.cookie = `provider=${provider};max-age=604800;domain=${document.location.host}`;
        sessionStorage.setItem('provider', provider);

        const options = {};
        if(provider === "walletconnect") {
            options.provider = provider;
        }
        enableWeb3(options);

        if(Object.hasOwnProperty.bind(params)('ref')) {
            //history.push(atob(params.ref));
            history.push(params.ref);
        } else {
            history.push(`/`);
        }
    };
    
    return (
        <Section style={{paddingTop: 80}}>
            <Container>
                <RowJustifyContentCenter>
                    <div className="col-12 col-md-8 col-lg-6">
                        <Intro>
                            <IntroRed>Wallet Connect</IntroRed>
                            <IntroTitle className="mt-3 mb-0">Connect your Wallet</IntroTitle>
                            <IntroSubTitle>Link your wallet to buy NFTs on the BOC platform. To learn how to setup your wallet, <Link to="/help-center/getting-started">click here</Link>.</IntroSubTitle>
                        </Intro>
                    </div>
                </RowJustifyContentCenter>

                <RowJustifyContentCenter>
                    <WalletItemCol>
                        {hasMetamask ? (
                            <WalletItemCardDiv onClick={() => login("metamask")}>
                                <WalletItemCardInner>
                                    <WalletItemCardLogo src="/img/metamask.png" alt="MetaMask" />
                                    <WalletItemCardName>MetaMask</WalletItemCardName>
                                    <WalletItemCardDescription>A browser extension with great flexibility. The web's most popular wallet</WalletItemCardDescription>
                                </WalletItemCardInner>
                            </WalletItemCardDiv>
                        ) : (
                            <WalletItemCardLink href="https://metamask.io/download.html" target="_blank" rel="noreferrer">
                                <WalletItemCardInner>
                                    <WalletItemCardLogo src="/img/metamask.png" alt="MetaMask" />
                                    <WalletItemCardName>MetaMask</WalletItemCardName>
                                    <WalletItemCardDescription>A browser extension with great flexibility. The web's most popular wallet</WalletItemCardDescription>
                                </WalletItemCardInner>
                            </WalletItemCardLink>
                        )}
                    </WalletItemCol>

                    <WalletItemCol>
                        <WalletItemCardDiv onClick={() => login("walletconnect")}>
                            <WalletItemCardInner>
                                <WalletItemCardLogo src="/img/walletconnect.png" alt="MetaMask" />
                                <WalletItemCardName>WalletConnect</WalletItemCardName>
                                <WalletItemCardDescription>Pair with Trust, Argent, MetaMask &amp; more. Works from any browser, without an extension</WalletItemCardDescription>
                            </WalletItemCardInner>
                        </WalletItemCardDiv>
                    </WalletItemCol>
                </RowJustifyContentCenter>
            </Container>
        </Section>
    );
}

export default Activity;