import styled, { keyframes } from "styled-components";

export const ProcessingAnimation = keyframes`
0% {
    top: 8px;
    height: 64px;
}

50%, 100% {
    top: 24px;
    height: 32px;
}
`

export const ProcessingBackdrop = styled.div`
position: fixed;
z-index: 10000;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: rgba(0,0,0,.6);
`

export const ProcessingOuter = styled.div`
display: table;
width: 100%;
height: 100%;
`

export const ProcessingInner = styled.div`
display: table-cell;
vertical-align: middle;
text-align: center;
`

export const ProcessingAnimationEl = styled.div`
display: inline-block;
position: relative;
width: 80px;
height: 80px;

div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    animation: ${ProcessingAnimation} 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

    &:nth-child(1) {
        left: 8px;
        animation-delay: -0.24s;
    }

    &:nth-child(2) {
        left: 32px;
        animation-delay: -0.12s;
    }

    &:nth-child(3) {
        left: 56px;
        animation-delay: 0;
    }
}
`