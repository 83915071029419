import React, { useEffect, useState, useRef } from 'react';
import { useMoralis } from "react-moralis";
import NFT from '../NFT/NFT';
import NoRoute from '../NoRoute/NoRoute';
import { Divider, FilterButton, Option, FilterWrapper, OptionsWrapper, FiltersWrapper, OptionWrapper} from './styles'
import { AiOutlineCheck } from 'react-icons/ai';
import NFTLoader from '../NFT/NFTLoader';
import { Container, Intro, IntroRed, IntroSubTitle, IntroTitle, Section } from '../Elements/styles';
import FilterDropdownLoader from './Loaders/FilterDropdownLoader';
import TitleCenterLoader from '../Loaders/TitleCenterLoader';
import BreadcrumbsLoader from '../Breadcrumb/BreadcrumbsLoader';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import getWindowDimensions  from '../../helpers/getWindowDimensions';

const data = {
    pre_heading: "Explore",
    heading: "Exclusive Digital Assets",
    content: "Take a tour on our Digital Gallery with masterworks from our world-renowned artists.",
}

const ExploreThree = () => {
    const { Moralis } = useMoralis();
    const { width } = getWindowDimensions();
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([])
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(1);
    const [buttonMoreVisible, setButtonMoreVisible] = useState(true)
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [lastScrollPosition, setLastScrollPosition] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0)
    const [categories, setCategories] = useState()
    const [selectedCategory, setSelectedCategory] = useState('all')
    const [selectedOrderBy, setSelectedOrderBy] = useState('Recently added')
    const [openCategory, setOpenCategory] = useState(false)
    const [openOrderBy, setOpenOrderBy] = useState(false)
    const categoryRef = useRef();
    const orderByRef = useRef();

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true })
    
        return () => {
          window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const handleScroll = () => {
        const position = window.pageYOffset
        setScrollPosition(position)
    };

    useEffect(() => {
        if((scrollPosition > (lastScrollPosition + 300)) && !isLoadingMore) {
            setLastScrollPosition(scrollPosition);
            getNFTInfoWithPagination();
        }
    }, [scrollPosition, isLoadingMore]);

    useEffect(() => {
        if(page > 0 && (window.innerHeight >= document.body.scrollHeight || (document.body.scrollHeight - window.innerHeight) <= 300) && page < pages) {
            getNFTInfoWithPagination();
        }
    }, [page]);

    useEffect(() => {
        const loadCategories = async () => {
            try {
                const categoriesInfo = await Moralis.Cloud.run('getNFTCategories');
                setCategories(categoriesInfo);
            } catch (error) {
                console.log('error', error)
            }
        };

        getNFTInfoWithPagination();
        loadCategories();
    }, []);

    useEffect(() => {
        if (items) {
            if(selectedOrderBy === 'Recently added') {
                const newItems = items.sort((a, b) => {
                    const aDate = new Date(a.sale.created_at)
                    const bDate = new Date(b.sale.created_at)
                    return bDate - aDate
                })
                setItems(newItems)
            } else if (selectedOrderBy === 'High to Low') {
                const newItems = items.sort((a, b) => b.sale.price - a.sale.price)
                setItems(newItems)
            } else if (selectedOrderBy === 'Low to High') {
                const newItems = items.sort((a, b) => a.sale.price - b.sale.price)
                setItems(newItems)
            }
        }
    }, [selectedOrderBy])

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);
        function handleClick(e) {
            if(categoryRef && categoryRef.current) {
                const ref = categoryRef.current
                if(!ref.contains(e.target)) {
                    setOpenCategory(false)
                }
            } 
            if(orderByRef && orderByRef.current){
                const ref = orderByRef.current
                if(!ref.contains(e.target)){
                    setOpenOrderBy(false)
                }
            }
        }
    }, []);

    useEffect(() => {
        if (openCategory) {
            setOpenOrderBy(false)
        }
    }, [openCategory])

    useEffect(() => {
        if (openOrderBy) {
            setOpenCategory(false)
        }
    }, [openOrderBy])
    
    const getNFTInfoWithPagination = async (overridePage) => {
        const newPage = overridePage ? overridePage + 1 : page + 1;

        if(buttonMoreVisible && !isLoadingMore && newPage <= pages) {
            setButtonMoreVisible(false);
            setIsLoadingMore(true);
            
            const itemsInfo = await Moralis.Cloud.run('getItems', {
                page: newPage,
                items: 4,
            });

            const curItems = items;
            for(let i=0; i < itemsInfo.items.length; ++i) {
                curItems.push(itemsInfo.items[i]);
            }

            const newItems = curItems.sort((a, b) => {
                const aDate = new Date(a.sale.created_at)
                const bDate = new Date(b.sale.created_at)
                return bDate - aDate
            })

            setPages(itemsInfo.total);
            setIsLoadingMore(false);
            setButtonMoreVisible(newPage < itemsInfo.pages)
            setItems(newItems);
            setPage(newPage);
            setLoading(false);
        }
    }

    if(!loading && !items) {
        return <NoRoute />
    }

    document.title = `Explore | Block of Change`;

    return (
        <>
            { loading ? <BreadcrumbsLoader /> : <Breadcrumb page="Explore" /> }

            <Section className="explore-area" style={{ paddingTop: (width < 768 ? 40 : 10) }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            {loading ? <TitleCenterLoader showphrase="true" /> : (
                                <Intro>
                                    <IntroRed>{data.pre_heading}</IntroRed>
                                    <IntroTitle>{data.heading}</IntroTitle>
                                    <IntroSubTitle>{data.content}</IntroSubTitle>
                                </Intro>
                            )}
                        </div>
                    </div>
                    
                    <div className="row filters justify-content-center text-center">
                        <div className="col-12" style={{display: 'flex', flexDirection: 'row'}}>
                            <FiltersWrapper>
                                {loading ? (
                                    <>
                                        <FilterDropdownLoader />
                                        <FilterDropdownLoader style={{marginRight: 0, marginLeft: 'auto'}} />
                                    </>
                                ) : (
                                    <>
                                        <FilterWrapper>
                                            <FilterButton ref={categoryRef} onClick={() => setOpenCategory(!openCategory)}>Category</FilterButton>
                                            {openCategory && (
                                                <OptionsWrapper>
                                                    <OptionWrapper onClick={() => setSelectedCategory('all')}>
                                                        <Option>All</Option>
                                                        <AiOutlineCheck style={{marginRight: 0, marginLeft: 'auto', display: selectedCategory === 'all' ? 'block' : 'none'}} />
                                                    </OptionWrapper>
                                                    <Divider />
                                                    {categories.map((category, index) => {
                                                        return (
                                                            <>
                                                                <OptionWrapper key={`cat-${index}`} onClick={() => setSelectedCategory(category.slug)}>
                                                                    <Option>{category.name}</Option>
                                                                    <AiOutlineCheck style={{marginRight: 0, marginLeft: 'auto', display: selectedCategory === category.slug ? 'block' : 'none'}} />
                                                                </OptionWrapper>
                                                                {index + 1 !== categories.length && <Divider /> }
                                                            </>
                                                        )
                                                    })}
                                                </OptionsWrapper>
                                            )}
                                        </FilterWrapper>
                                    
                                        {/* <FilterWrapper>
                                            <FilterButton style={{width: 120}}>Collections</FilterButton>
                                        </FilterWrapper> */}
                                        
                                        {/* <FilterWrapper>
                                            <FilterButton style={{width: 120}}>Sale type</FilterButton>
                                        </FilterWrapper> */}

                                        <FilterWrapper style={{marginRight: 0, marginLeft: 'auto'}}>
                                            <FilterButton ref={orderByRef} onClick={() => setOpenOrderBy(!openOrderBy)}>Order by</FilterButton>
                                            {openOrderBy && (
                                                <OptionsWrapper style={{right: 16}}>
                                                    <OptionWrapper onClick={() => setSelectedOrderBy('Recently added')}>
                                                        <Option>Recently added</Option>
                                                        <AiOutlineCheck style={{marginRight: 0, marginLeft: 'auto', display: selectedOrderBy === 'Recently added' ? 'block' : 'none'}} />
                                                    </OptionWrapper>
                                                    <Divider />
                                                    <OptionWrapper onClick={() => setSelectedOrderBy('Low to High')}>
                                                        <Option>Price: Low to High</Option>
                                                        <AiOutlineCheck style={{marginRight: 0, marginLeft: 'auto', display: selectedOrderBy === 'Low to High' ? 'block' : 'none'}} />
                                                    </OptionWrapper>
                                                    <Divider />
                                                    <OptionWrapper onClick={() => setSelectedOrderBy('High to Low')}>
                                                        <Option>Price: High to Low</Option>
                                                        <AiOutlineCheck style={{marginRight: 0, marginLeft: 'auto', display: selectedOrderBy === 'High to Low' ? 'block' : 'none'}} />
                                                    </OptionWrapper>
                                                </OptionsWrapper>
                                            )}
                                        </FilterWrapper>
                                    </>
                                )}
                            </FiltersWrapper>
                        </div>
                    </div>

                    <div className="row items explore-items">
                        {!loading && items.map((item) => {
                            if (selectedCategory === item.category.slug || selectedCategory === 'all') {
                                return (
                                    <NFT key={`nft-${item.id}`} gridNumber={4} item={item} />   
                                )
                            }
        
                            return ('');
                        })}
                    </div>
                </div>
                {isLoadingMore && (
                    <Container>
                        <div className="row">
                            {[0, 1, 2, 4].map((idx) => (
                                <div className="col-12 col-sm-6 col-lg-3" key={`nft-${idx}`} style={{marginTop: 30}}>
                                    <NFTLoader style={{boxShadow: '0px 0px 2px rgb(0 0 0 / 15%), 0px 2px 2px rgb(0 0 0 / 5%)', width: '100%', height: 'auto'}} />
                                </div>
                            )) }
                        </div>
                    </Container>
                )}
            </Section>
        </>
    );
}

export default ExploreThree;