
import ContentLoader from "react-content-loader"

const TabsLoader = (props) => {
    return (
        <ContentLoader 
            speed={2}
            width={457}
            height={184}
            viewBox="0 0 457 184"
            backgroundColor="#d4d4d4"
            foregroundColor="#b0b0b0" 
            style={{width: '100%', height: 'auto'}}
            {...props}
        >
            <rect x="0" y="0" rx="2" ry="2" width="54" height="20" /> 
            <rect x="74" y="0" rx="2" ry="2" width="54" height="20" /> 
            <rect x="0" y="26" rx="0" ry="0" width="54" height="4" /> 
            <rect x="0" y="29" rx="0" ry="0" width="457" height="1" /> 
            <circle cx="25" cy="75" r="25" />
            <rect x="65" y="60" rx="2" ry="2" width="190" height="10" />
            <rect x="65" y="75" rx="2" ry="2" width="300" height="10" />
            <circle cx="25" cy="150" r="25" />
            <rect x="65" y="136" rx="2" ry="2" width="190" height="10" />
            <rect x="65" y="152" rx="2" ry="2" width="300" height="10" />
        </ContentLoader>
    )
};

export default TabsLoader;