import { useMoralis } from "react-moralis";
import { Link } from "react-router-dom";
import { etherscanTxURL, shortenEthAddress, userURL } from "../../../../helpers/entityURLs";
import { getAvatarPlaceholder } from "../../../../helpers/fileUpload";
import { formatWeiToEth } from "../../../../helpers/nftSettings";
import { ListUnstyled } from "../../../Elements/styles";
import { HistoryItem, HistoryItemAvatar, HistoryItemText } from "../../styles";

const History = ({ history }) => {
    const { Moralis } = useMoralis();

    return (
        <ListUnstyled>
            { history.length > 0 && history.map((tx, idx) => {
                if(tx.type && tx.type === "created") {
                    return (
                        <HistoryItem key={`tx-${idx}`}>
                            <HistoryItemAvatar src={tx.receiver.avatar} alt={tx.receiver.username} />
                            <HistoryItemText>Created by <Link to={userURL(tx.receiver.username, tx.receiver.slug)}>@{tx.receiver.username}</Link><br /><small>{new Intl.DateTimeFormat('en-GB', { 
                                month: '2-digit', 
                                day: '2-digit',
                                year: 'numeric', 
                                hour: '2-digit', 
                                minute: '2-digit'
                            }).format(new Date(tx.createdAt))}</small></HistoryItemText>
                        </HistoryItem>
                    )
                }

                const txURL = etherscanTxURL(tx.transaction_hash);

                if(tx.from_address === '0x0000000000000000000000000000000000000000') {
                    const receiverAvatar = tx.receiver.avatar ? tx.receiver.avatar : getAvatarPlaceholder();
                    return (
                        <HistoryItem key={`tx-${idx}`}>
                            <HistoryItemAvatar src={receiverAvatar} alt={tx.receiver.username} />
                            <HistoryItemText>Minted by <Link to={userURL(tx.receiver.username, tx.receiver.slug)}>@{tx.receiver.username}</Link><br /><small>{new Intl.DateTimeFormat('en-GB', { 
                                month: '2-digit', 
                                day: '2-digit',
                                year: 'numeric', 
                                hour: '2-digit', 
                                minute: '2-digit'
                            }).format(new Date(tx.createdAt))}</small> <a href={txURL} target="_blank" rel="noreferrer">
                                <i className="fas fa-external-link-alt"></i>
                            </a></HistoryItemText>
                        </HistoryItem>
                    )
                } else if(tx.to_address === '0x0000000000000000000000000000000000000000') {
                    const ownerAvatar = tx?.sender?.avatar ? tx.sender.avatar : getAvatarPlaceholder();
                    return (
                        <HistoryItem key={`tx-${idx}`}>
                            <HistoryItemAvatar  src={ownerAvatar} alt={tx.sender.username} />
                            <HistoryItemText>Burned by <Link to={userURL(tx.sender.username, tx.sender.slug)}>@{tx.sender.username}</Link><br /><small>{new Intl.DateTimeFormat('en-GB', { 
                                month: '2-digit', 
                                day: '2-digit',
                                year: 'numeric', 
                                hour: '2-digit', 
                                minute: '2-digit'
                            }).format(new Date(tx.createdAt))}</small> <a href={txURL} target="_blank" rel="noreferrer">
                                <i className="fas fa-external-link-alt"></i>
                            </a></HistoryItemText>
                        </HistoryItem>
                    )
                } else if(tx.value > 0) {
                    const receiverAvatar = tx.receiver.avatar ? tx.receiver.avatar : getAvatarPlaceholder();
                    return (
                        <HistoryItem key={`tx-${idx}`}>
                            <HistoryItemAvatar src={receiverAvatar} alt={tx.receiver.username} />
                            <HistoryItemText>Purchased for {formatWeiToEth(Moralis.Units.FromWei(tx.value))}<br /><small>by <Link to={userURL(tx.receiver.username, tx.receiver.slug)}>@{tx.receiver.username}</Link> {new Intl.DateTimeFormat('en-GB', { 
                                month: '2-digit', 
                                day: '2-digit',
                                year: 'numeric', 
                                hour: '2-digit', 
                                minute: '2-digit'
                            }).format(new Date(tx.createdAt))}</small> <a href={txURL} target="_blank" rel="noreferrer">
                                <i className="fas fa-external-link-alt"></i>
                            </a></HistoryItemText>
                        </HistoryItem>
                    )
                } else {
                    let txAvatar = tx?.sender?.avatar ? tx.sender.avatar : getAvatarPlaceholder();
                    let txAvatarName = tx.sender.username;
                    let receiverURL = undefined;
                    let receiverName = shortenEthAddress(tx.to_address);
                    if(tx.receiver) {
                        txAvatar = tx.receiver.avatar ? tx.receiver.avatar : getAvatarPlaceholder();
                        txAvatarName = tx.receiver.username;
                        receiverURL = userURL(tx.receiver.username, tx.receiver.slug);
                        receiverName = tx.receiver.username;
                    }
                    return (
                        <HistoryItem key={`tx-${idx}`}>
                            <HistoryItemAvatar src={txAvatar} alt={txAvatarName} />
                            <HistoryItemText>Transfered to {typeof receiverURL === undefined ? (
                                    `@${receiverName}`
                                ) : (
                                    <Link to={receiverURL}>@{receiverName}</Link>
                                )
                            }<br /><small>{new Intl.DateTimeFormat('en-GB', { 
                                month: '2-digit', 
                                day: '2-digit',
                                year: 'numeric', 
                                hour: '2-digit', 
                                minute: '2-digit'
                            }).format(new Date(tx.createdAt))}</small> <a href={txURL} target="_blank" rel="noreferrer">
                                <i className="fas fa-external-link-alt"></i>
                            </a></HistoryItemText>
                        </HistoryItem>
                    )
                }
            })}
            { history.length == 0 &&     
                <HistoryItem>Not minted yet (LazyMint)</HistoryItem>
            }
        </ListUnstyled>
    )
};

export default History;