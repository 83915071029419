import React from 'react';
import Header from '../../components/Header/Header';
import AdminIndex from '../../components/Admin/Index';
import Footer from '../../components/Footer/Footer';
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';
import { StickyFooterBody, StickyFooterMain } from '../../sharedComponents/StickyFooter';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

const IndexAdmin = () => {
    document.body.className = '';
    
    return (
        <StickyFooterMain>
            <StickyFooterBody>
                <Header />
                <Breadcrumb title="Admin" page="Admin" />
                <AdminIndex />
            </StickyFooterBody>
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
        </StickyFooterMain>
    );
}

export default IndexAdmin;