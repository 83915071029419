import React from 'react';
import Header from '../components/Header/Header';
/* import Breadcrumb from '../components/Breadcrumb/Breadcrumb'; */
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import Profile from '../components/Account/Profile';
import { StickyFooterBody, StickyFooterMain } from '../sharedComponents/StickyFooter';

const ProfileTheme = () => {
    document.body.className = '';

    return (
        <StickyFooterMain>
            <StickyFooterBody>
                <Header hasBanner={true} />
                <Profile />
            </StickyFooterBody>
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
        </StickyFooterMain>
    );
}

export default ProfileTheme;