import React, { } from 'react';
import { Link } from 'react-router-dom';
import getWindowDimensions  from '../../helpers/getWindowDimensions';

const Breadcrumb = (props) => {
    const { width } = getWindowDimensions();

    if(width < 768) {
        return ('');
    }

    return (
        <section className="breadcrumb-area d-flex align-items-center">
            <div className="container" style={{paddingTop: props.page ? 0 : ''}}>
                <div className="row">
                    <div className="col-12">
                        
                        <div className="breadcrumb-content text-center">

                            <ol className="breadcrumb d-flex justify-content-start">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>

                                { props.subpage && props.subpagelink && (
                                    <li className="breadcrumb-item">
                                        <Link to={props.subpagelink}>{props.subpage}</Link>
                                    </li>
                                ) }
                                
                                <li className={`breadcrumb-item active${props.subpage && props.subpagelink ? ' txt-ellipsis' : ''}`}>{props.page}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Breadcrumb;