import styled from 'styled-components'

export const LegalTitle = styled.h1`
    font-family: var(--primary-font);
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 53px;
    letter-spacing: 0;
    text-align: center;
    color: #000;
    margin: 0;
`

export const LegalSubTitle = styled.p`
    font-family: var(--secondary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: center;
    color: #3A3A3AB2;
    margin-top: 12px;
`

export const LegalDescription = styled.div`
    font-family: var(--secondary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: center;
    color: #3A3A3A;
    margin-top: 24px;
`

export const LegalText = styled.div`
    margin-top: 50px;
    font-family: var(--secondary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    color: #3A3A3A;

    h1, h2, h3, h4, h5, h6 {
        font-family: var(--secondary-font);
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0;
        color: #000;
        margin-top: 0;
        margin-bottom: 16px;
    }

    h1 {
        font-size: 18px;
    }

    h2 {
        font-size: 16px;
    }

    h3, h4, h5, h6 {
        font-size: 14px;
    }

    p, ul {
        margin-top: 0;
        margin-bottom: 16px;
    }

    a {
        color: #3A3A3A !important;
        text-decoration: underline !important;
    }

    a:hover {
        color: var(--fire-red) !important;
    }

    p + h1, p + h2, p + h3, p + h4, p + h5, p + h6, 
    ul + h1, ul + h2, ul + h3, ul + h4, ul + h5, ul + h6 {
        margin-top: 30px;
    }
`