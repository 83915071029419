import React, { useEffect, useState } from 'react';
import { useMoralis } from 'react-moralis';
import Card from './Card'
import Slider from "react-slick";
import getWindowDimensions  from '../../helpers/getWindowDimensions'
import CardLoader from '../TopSeller/CardLoader';
import TitleLeftLoader from '../Loaders/TitleLeftLoader';
import { Link } from 'react-router-dom';

const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    swipe: true,
    draggable: true,
    responsive: [
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2.2,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
            },
        }
    ]
};

const Featured = () => {
    const { Moralis } = useMoralis();
    const [loading, setLoading] = useState(true);
    const [artists, setArtists] = useState([]);
    const {width} = getWindowDimensions()

    const getFeaturedArtists = async () => {
        const result = await Moralis.Cloud.run('getFeaturedArtists');
        setArtists(result);
        setLoading(false);
    };

    useEffect(() => {
        getFeaturedArtists();
    }, []);

    if(!loading && artists.length === 0) {
        return ('');
    }
    
    return (
        <div className="top-seller-area" style={{ paddingTop: 64 }}>
            <div className="container">
                <div className="row" style={{marginBottom: 16}}>
                    <div className="col-12">
                        {loading ? <TitleLeftLoader /> : (
                            <div className="intro d-flex justify-content-between align-items-end m-0">
                                <div className="intro-content">
                                    <span>Creative Artist</span>
                                    <h3 className="mt-3 mb-0" style={{fontSize: width <= 550 ? 34 : ''}}>Our Artists</h3>
                                </div>

                                {width > 767 && (
                                    <div className="intro-btn">
                                        <Link className="btn content-btn custom-see-all-btn" to="/artists">View All</Link>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <Slider {...settings}>
                    {loading ? 
                        [0, 1, 2, 3].map((idx) => 
                            <div key={`fa_loader_${idx}`} style={{boxShadow: '0px 0px 2px rgba(0, 0, 0, .15), 0px 2px 2px rgba(0, 0, 0, .05)'}}>
                                <CardLoader />
                            </div>
                        )
                     : 
                        artists.map((artist, idx) => {
                            return (
                                <div key={`fa_${idx}`} className="item">
                                    <Card {...artist} />
                                </div>
                            );
                        })
                    }
                </Slider>
                {width <= 700 && (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 52}}>
                        <Link className="btn custom-btn-white clear-bgi button-explore" to="/explore">Explore artists</Link>
                    </div>
                )}       
                </div>
        </div>
    );
}

export default Featured;