import React from 'react';
import Header from '../components/Header/Header';
import SetupSection from '../components/Setup/Setup';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import { StickyFooterBody, StickyFooterMain } from '../sharedComponents/StickyFooter';

const Login = () => {
    document.body.className = '';
    
    return (
        <StickyFooterMain>
            <StickyFooterBody>
                <Header />
                <SetupSection />
            </StickyFooterBody>
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
        </StickyFooterMain>
    );
}

export default Login;