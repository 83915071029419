
import ContentLoader from "react-content-loader"

const MediaLoader = (props) => {
    return (
        <ContentLoader 
            speed={2}
            width={457}
            height={457}
            viewBox="0 0 457 457"
            backgroundColor="#d4d4d4"
            foregroundColor="#b0b0b0" 
            style={{width: '100%', height: 'auto'}}
            {...props}
        >
            <rect x="0" y="0" rx="2" ry="2" width="457" height="457" /> 
        </ContentLoader>
    )
};

export default MediaLoader;