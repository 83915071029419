import React from 'react';
import Header from '../components/Header/Header';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import ProfileEdit from '../components/Account/ProfileEdit';
import { StickyFooterBody, StickyFooterMain } from '../sharedComponents/StickyFooter';

const ProfileEditTheme = () => {
    document.body.className = '';

    return (
        <StickyFooterMain>
            <StickyFooterBody>
                <Header />
                <Breadcrumb title="Profile" subpagelink="/profile" subpage="Profile" page="Edit Profile" />
                <ProfileEdit />
            </StickyFooterBody>
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
        </StickyFooterMain>
    );
}

export default ProfileEditTheme;