import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { BtnWhiteLink } from '../Elements/Buttons'

const CardStyle = css`
position: relative;
display: -ms-flexbox;
display: flex;
-ms-flex-direction: column;
flex-direction: column;
min-width: 0;
word-wrap: break-word;
background-clip: border-box;
border-radius: 0.25rem;
border: none;
background-color: var(--white-color);
transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
transition-duration: 0.3s;
transition-property: opacity, transform;
overflow: hidden;
box-shadow: 0px 0px 2px rgba(0 0 0 / 15%), 0px 2px 2px rgb(0 0 0 / 5%);
padding: 0;

&:hover {
    transform: translateY(-5px);
}
`

export const ImageHover = styled.div`
overflow: hidden;
border-radius: 0;
`

export const Card = styled.div`
${CardStyle}
`

const NFTCardStyle = css`
${CardStyle}
height: 100%;
`
export const CardCaption = styled.div`
flex: 1;
padding: 16px 24px 24px;
`

export const NFTCard = styled.div`
${NFTCardStyle}

${ImageHover} {
    & > a {
        position: relative;
        display: block;
        padding-top: 320px;

        & > img, & > video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}
`
export const NFTCardExplore = styled.div`
${NFTCardStyle}

${ImageHover} {
    & > a {
        position: relative;
        display: block;
        padding-top: 234px;

        & > img, & > video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}
`

export const NFTCardBody = styled.div`
flex: 1 1 auto;
min-height: 1px;
height: 100%;
display: flex;
flex-direction: column;
`

export const NFTCardOwner = styled.h6`
padding: 0;
border: 0;
vertical-align: baseline;
margin: 0 0 1.5rem;
-ms-word-wrap: break-word;
word-wrap: break-word;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
font-family: var(--secondary-font);
font-style: normal;
font-weight: var(--normal);
font-size: 11px;
line-height: 15px;
margin-bottom: 3px;
color: #444444;
`

export const NFTCardNameLink = styled(Link)`
flex: 1;
display: block;
`

export const NFTCardName = styled.h5`
margin: 0;
-ms-word-wrap: break-word;
word-wrap: break-word;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 26px;
color: var(--black-color);
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
`

export const NFTCardBottom = styled.div`
margin: 1.5rem 0 0;
padding: 0;
border: 0;
font-size: 100%;
vertical-align: baseline;
display: -ms-flexbox!important;
display: flex!important;
-ms-flex-pack: justify!important;
justify-content: space-between!important;
`

export const NFTCardBottomCol = styled.div`
font-family: var(--secondary-font);
font-style: normal;
font-weight: var(--normal);
font-size: 12px !important;
line-height: 13px !important;
color: #848484;
display: flex;
flex-direction: column;
max-width: calc(50% - 10px);

& > a, & > span {
    font-family: var(--secondary-font) !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: var(--black-color) !important;
    margin-top: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
`

export const NFTCardBtnWhiteLink = styled(BtnWhiteLink)`
font-size: 1rem !important;
font-weight: 400 !important;
line-height: 1.5!important;
padding: 12px 40px;
display: block;
margin-top: 32px;
`

export const NFTCardProcessing = styled.div`
margin: 0;
padding: 0;
border: 0;
position: absolute;
z-index: 1;
inset: 0;
background: rgba(0, 0, 0, 0.25);
backdrop-filter: blur(7px);
border-radius: 4px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-family: var(--secondary-font);
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 26px;
color: #FFF;
font-size: 13px;

& > img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    margin-bottom: 19px;
}
`