import ContentLoader from "react-content-loader"

const NFTLoader = (props) => {
    return (
        <ContentLoader 
            speed={2}
            width={360}
            height={541}
            viewBox="0 0 360 541"
            backgroundColor="#d4d4d4"
            foregroundColor="#b0b0b0" 
            style={{width: '100%', height: 'auto'}}
            {...props}
        >
            <rect x="0" y="0" rx="4" ry="4" width="360" height="320" /> 
            <rect x="24" y="336" rx="4" ry="4" width="144" height="13" /> 
            <rect x="24" y="356" rx="4" ry="4" width="312" height="24" /> 
            <rect x="24" y="404" rx="4" ry="4" width="55" height="13" /> 
            <rect x="250" y="404" rx="4" ry="4" width="45" height="13" /> 
            <rect x="24" y="420" rx="4" ry="4" width="144" height="16" /> 
            <rect x="250" y="420" rx="4" ry="4" width="85" height="16" /> 
            <rect x="24" y="468" rx="20" ry="20" width="312" height="50" />
        </ContentLoader>
    )
};

export default NFTLoader;