import Tabs from "../../Tabs/Tabs";
import Details from "./Details/Details";
import History from "./History/History";

const ItemTabs = ({ item, marginTop = 0 }) => {
    return (
        <Tabs style={{ marginTop: marginTop }}>
            <div label="History">
                <History history={item.history} />
            </div>

            <div label="Details">
                <Details item={item} />
            </div>
        </Tabs>
    )
};

export default ItemTabs;