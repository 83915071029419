export const userURL = (username, slug) => {
    if(slug) {
        return `/user/${slug}`;
    }

    return `/user/${encodeURI(username)}`;
}

export const artistURL = (name, slug) => {
    if(slug) {
        return `/artists/${slug}`;
    }

    return `/artists/${encodeURI(name)}`;
}

export const itemURL = (id, token_address, token_id) => {
    if(token_address && token_id) {
        return `/item/${token_address}/${token_id}`;
    }
    return `/item/${id}`;
}

export const etherscanTxURL = (transaction_hash) => {
    const etherscanTxURL = process.env.REACT_APP_ETHERSCAN_TX_URL;

    return `${etherscanTxURL}tx/${transaction_hash}`
}

export const shortenEthAddress = (address) => {
    return `${address.substring(0, 4)}...${address.substring(address.length - 4)}`
}