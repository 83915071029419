import React from 'react';
import { Section } from './styles'
import getWindowDimensions  from '../../helpers/getWindowDimensions'
import { Link } from 'react-router-dom';

const Hero = () => {
    const { width } = getWindowDimensions()

    return (
        <Section className="hero-section">
            <div className="container img-background">
                <div className="row">
                    <div className={`${width > 780 ? 'col-12' : width <= 700 && width > 500 ? 'col-10' : width <= 500 ? 'col-12' : 'col-8'} ${width > 841 ? 'col-md-6' : 'col-md-8'} col-lg-7`}>
                        {/* <span>{this.state.data.pre_heading}</span> */}
                        <h1 className="mt-4 title">Enter the vault of NFT Fine Art</h1>
                        <p className="subtitle">Dwell into the most exclusive selection of NFTs</p>
                        <div className="button-group">
                            <Link className="btn custom-btn-white clear-bgi button-text" to="/explore">Explore Art</Link>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    );
}

export default Hero;