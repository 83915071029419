import React, { useCallback, useState } from 'react';
import { useForm } from "react-hook-form";
import { useMoralis } from "react-moralis";
import urlSlug from 'url-slug'
import ProcessingModal from '../../Loaders/ProcessingModal/ProcessingModal';
import { AdminBtn, AdminBtnLight, AdminModalBackdrop, AdminModalBody, AdminModalFooter, AdminModalForm, AdminModalHeader } from '../styles';
import { AlertDanger } from '../../Elements/Alerts';

const Form = ({ successCallback, closeModalCallback, currentName = '', id }) => {
    const { Moralis } = useMoralis();

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        mode: "onBlur", 
        defaultValues: {
            name: currentName, 
        }
    });

    const [ formError, setFormError ] = useState(false);
    const [ formErrorMsg, setFormErrorMsg ] = useState('Unknown error, please try again later');
    const [ formSaving, setFormSaving ] = useState(false);

    const hideModal = () => {
        reset({name: ''});
        hideModalInternal()
    };

    const hideModalInternal = useCallback(() => {
        closeModalCallback();
    }, [closeModalCallback]);

    const afterSave = useCallback((index, name, slug) => {
        successCallback(index, name, slug);
    }, [successCallback]);

    const onSubmit = async(data) => {
        setFormError(false);
        setFormErrorMsg('Unknown error, please try again later');
        setFormSaving(true);

        const catObj = Moralis.Object.extend("NFTCategory");
        const newRecord = id ? false : true;
        const cat = id 
            ? catObj.createWithoutData(id) 
            : new catObj();

        const slug = urlSlug(data.name);
        cat.set('name', data.name);
        cat.set('slug', slug);

        if(newRecord) {
            cat.set('active', true);
        }

        try {
            await cat.save();
            setFormSaving(false);
            afterSave();
        } catch(error) {
            setFormSaving(false); 
            setFormError(true);
            setFormErrorMsg(error.message);
        }
    };

    return (
        <>
            <AdminModalBackdrop />
            <AdminModalForm onSubmit={handleSubmit(onSubmit)}>
                <AdminModalHeader>{id ? 'Edit' : 'Add' } Category</AdminModalHeader>
                <AdminModalBody>
                    {formError && <AlertDanger role="alert">{formErrorMsg}</AlertDanger> }
                        
                    <div className="form-group mt-3">
                        <label className="form-label" htmlFor="name">Name</label>
                        <input type="text" className={errors.name ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Name" maxLength={256} {...register("name", { required: true })} />
                        {errors.name ? (
                            <div className="invalid-feedback">Required Field.</div>
                        ) : ('')}
                    </div>
                </AdminModalBody>
                <AdminModalFooter>
                    <AdminBtn type="submit"{...formSaving ? ' disabled' : ''}>{
                        formSaving 
                        ? 'Saving...' 
                        : 'Save'
                    }</AdminBtn>
                    <AdminBtnLight type="button" onClick={hideModal}>Cancel</AdminBtnLight>
                </AdminModalFooter>
            </AdminModalForm>
  
            {formSaving && <ProcessingModal /> }
        </>
    );
}

export default Form;