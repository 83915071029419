import ContentLoader from "react-content-loader"
import { UserBannerOverlay } from "./style";

const BannerLoader = (props) => {
    return (
        <UserBannerOverlay>
            <ContentLoader 
                speed={2}
                width={1920}
                height={300}
                viewBox="0 0 1920 300"
                backgroundColor="#d4d4d4"
                foregroundColor="#b0b0b0" 
                {...props}
            >
                <rect x="0" y="0" rx="4" ry="4" width="1920" height="300" />
            </ContentLoader>
        </UserBannerOverlay>
    )
};

export default BannerLoader;