import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const ProductColLeft = styled.div`
position: relative;
width: 100%;
padding-right: 15px;
padding-left: 15px;
-ms-flex: 0 0 100%;
flex: 0 0 100%;
max-width: 100%;

@media (min-width: 768px) {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
}

@media (min-width: 992px) {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

@media (max-width: 767px) {
    padding-top: 40px;
}
`

export const ProductColRight = styled.div`
position: relative;
width: 100%;
padding-right: 15px;
padding-left: 15px;
-ms-flex: 0 0 100%;
flex: 0 0 100%;
max-width: 100%;

@media (min-width: 768px) {
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
}

@media (min-width: 992px) {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
`

export const ProductThumb = styled.div`
position: relative;
text-align: center;

& > img, & > video {
    width: 100%;
    height: auto;
    border-radius: 4px;
}
`

export const ProductLightbox = styled.div`
position: absolute;
z-index: 10000;
top: 0;
left: 0;
right: 0;
bottom: 0;
opacity: 0;
visibility: hidden;
transition: .6s all;
background-color: rgba(0,0,0,0.5);
backdrop-filter: blur(15px);

&.show {
    visibility: visible;
    position: fixed;
    opacity: 1;
}
`

export const ProductLightboxClose = styled.div`
position: absolute;
right: 10px;
top: 10px;
cursor: pointer;

svg {
    width: 40px;
    height: 40px;
}
`

export const ProductLightboxOuter = styled.div`
display: table;
width: 100%;
height: 100%;
`

export const ProductLightboxInner = styled.div`
display: table-cell;
vertical-align: middle;
max-width: calc(100% - 30px);
max-height: calc(100% - 30px);    
`

const ProductLightboxMedia = css`
border-radius: 0;
width: auto;
height: auto;
max-width: 100%;
max-height: 100%;
`

export const ProductLightboxMediaImg = styled.img`
${ProductLightboxMedia}
`

export const ProductLightboxMediaVideo = styled.video`
${ProductLightboxMedia}
`

export const ProductPaddingH = styled.div`
@media (min-width: 992px) {
    padding-left: 0.5rem!important;
    padding-right: 0.5rem!important;
}
`

export const ProductContent = styled.div`
padding-top: 24px;

@media (min-width: 768px) {
    padding-top: 0;
}

@media (min-width: 992px) {
    padding-top: 44px;
}
`

export const ProductName = styled.h1`
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: 48px;
letter-spacing: 0;
text-align: left;
color: black;
margin: 0!important;

@media (min-width: 992px) {
    padding-left: 0.5rem!important;
    padding-right: 0.5rem!important;
}

@media (max-width: 767px) {
    font-size: 26px;
    line-height: 32px;
}
`

export const ProductArtist = styled(Link)`
font-size: 16px !important;
font-style: normal;
font-weight: 400;
line-height: 20px !important;
letter-spacing: 0;
text-align: left;
color: black !important;
margin: 0!important;

&:hover {
    strong {
        color: var(--fire-red) !important;
    }
}

@media (min-width: 992px) {
    padding-left: 0.5rem!important;
    padding-right: 0.5rem!important;
}

@media (max-width: 767px) {
    font-size: 26px;
    line-height: 32px;
}
`

export const ProductDescription = styled.div`
margin-top: 27px;
font-family: var(--secondary-font);
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px;
letter-spacing: 0;
text-align: left;
color: #3A3A3A;

@media (min-width: 992px) {
    padding-left: 0.5rem!important;
    padding-right: 0.5rem!important;
}

@media (max-width: 767px) {
    margin-top: 24px;
}
`

export const ProductNFTSellers = styled.div`
display: -ms-flexbox;
display: flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
margin-left: 0;
margin-right: 0;
margin-top: 32px;
`

export const ProductNFTSellersSplitter = styled.div`
position: relative;
width: 100%;
-ms-flex: 0 0 100%;
flex: 0 0 100%;
max-width: 100%;

@media (min-width: 992px) {
    padding-left: 0.5rem!important;
    padding-right: 0.5rem!important;
}
`

export const ProductNFTSellersSplitterLine = styled.div`
border-top: 1px solid rgba(0,0,0,0.1);
`

export const ProductNFTSellersCol = styled.div`
position: relative;
-ms-flex: 0 0 50%;
flex: 0 0 50%;
max-width: 50%;

@media (min-width: 768px) {
    margin-top: 30px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

@media (max-width: 767px) {
    margin-bottom: 24px;

    &:not(.fsm) {
        padding-left: 0.5rem;
    }

    &:not(.lsm) {
        padding-right: 0.5rem;
    }
}
`

export const ProductNFTSellersCard = styled.div`
position: relative;
display: -ms-flexbox;
display: flex;
-ms-flex-direction: column;
flex-direction: column;
min-width: 0;
word-wrap: break-word;
background-color: var(--white-color);
transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
transition-duration: 0.3s;
transition-property: opacity, transform;
overflow: hidden;
`

export const ProductNFTSeller = styled.div`
display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
`

export const ProductNFTSellerAvatar = styled(Link)`
display: block;
width: 3rem;
height: 3rem;
`

export const ProductNFTSellerAvatarImg = styled.img`
max-width: 100%;
vertical-align: middle;
border-radius: 50%;
-o-object-fit: cover;
object-fit: cover;
-o-object-position: center;
object-position: center;
width: 3rem;
height: 3rem;
`

export const ProductNFTSellerInfo = styled.div`
display: flex;
flex-direction: column;
max-width: calc(100% - 4rem);
margin-left: 1rem;
`

export const ProductNFTSellerInfoTitle = styled.span`
font-family: var(--secondary-font);
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 15px;
letter-spacing: 0;
text-align: left;
color: #848484;
`

export const ProductNFTSellerInfoName = styled(Link)`
font-family: var(--secondary-font);
font-size: 14px !important;
font-style: normal;
font-weight: 500 !important;
line-height: 17px !important;
letter-spacing: 0;
text-align: left;
color: #000 !important;
transition: 0.3s;

&:hover {
    color: var(--fire-red) !important;
}
`

export const ProductInfoList = styled.div`
margin-top: 1.5rem;

@media (min-width: 992px) {
    padding-left: 0.5rem!important;
    padding-right: 0.5rem!important;
}

@media (max-width: 767px) {
    margin-top: 15px;
}
`

export const ListItemPriceAndRoyalties = styled.li`
margin: 0.5rem 0;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
display: -ms-flexbox;
display: flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
margin-left: 0;
margin-right: 0;
`

export const ListItemPriceAndRoyaltiesCol = styled.div`
position: relative;
-ms-flex: 0 0 50%;
flex: 0 0 50%;
max-width: 50%;

@media (min-width: 768px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

@media (max-width: 767px) {
    &:not(:first-of-type) {
        padding-left: 0.5rem;
    }

    &:not(:last-of-type) {
        padding-right: 0.5rem;
    }
}
`

export const ListItemLabel = styled.div`
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 17px;
letter-spacing: 0;
text-align: left;
color: #848484;
`

export const ListItemValue = styled.div`
font-family: var(--secondary-font);
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: 27px;
letter-spacing: -0.06em;
text-align: left;
color: black;
margin-top: 2px;

@media (max-width: 767px) {
    margin-top: 9px;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0;
}
`

export const ListItemPrice = styled.div`
margin-top: 12px;
font-family: var(--secondary-font);
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 15px;
letter-spacing: 0;
text-align: left;
color: #7A7A7A;
`

export const ProductNFTCerticate = styled.div`
text-align: center;
padding-top: 30px;

a {
    display: inline-block;

    img {
        margin-right: 12px;
    }

    span {
        display: inline-block;
        font-family: var(--secondary-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: #000;
        text-decoration: underline;
    }
}
`

export const PutOnSaleForm = styled.form`
    width: 100%;
    margin-top: 1rem;
`

export const PutOnSaleFormBox = styled.div`
background: #FFFFFF;
box-shadow: 0px 0px 2px rgb(0 0 0 / 15%), 0px 2px 2px rgb(0 0 0 / 5%);
border-radius: 4px;
padding: 30px 34px 20px
`

const PutOnSaleControlLabelStyle = css`
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 22px;
color: #000;
`

export const PutOnSaleControlLabel = styled.label`
${PutOnSaleControlLabelStyle}
`

export const PutOnSaleControlLabelRequired = styled.label`
${PutOnSaleControlLabelStyle}

&:after {
    content: "*";
    color: var(--invalid-color);
}
`

export const HistoryItem = styled.li`
margin: 1.5rem 0;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
`

export const HistoryItemAvatar = styled.img`
max-width: 100%;
vertical-align: middle;
border-radius: 50%;
margin-right: 1rem;
height: 3rem;
width: 3rem;
-o-object-fit: cover;
object-fit: cover;
-o-object-position: center;
object-position: center;
`

export const HistoryItemText = styled.p`
-ms-word-wrap: break-word;
word-wrap: break-word;
margin: 0 !important;
font-family: var(--secondary-font);
font-weight: 400;
font-style: normal;
font-size: 14px;
line-height: 16.94px;
color: #3A3A3A;

a {
    transition: 0.3s;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 16.94px !important;
    color: #3A3A3A !important;

    &:hover {
        color: var(--fire-red) !important;
    }
}
`

export const OwnerMeta = styled.div`
display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
margin-top: 1rem;

& > span {
    font-family: var(--secondary-font);
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 16.94px;
    color: #3A3A3A;
}

& > a {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 0.5rem;

    & > img {
        max-width: 100%;
        vertical-align: middle;
        border-radius: 50%;
        height: 3rem;
        width: 3rem;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center;
        object-position: center;
    }

    & > h6 {
        margin-left: 0.5rem;
        transition: 0.3s;
        font-family: var(--secondary-font);
        font-style: normal;
        font-weight: 700 !important;
        font-size: 14px !important;
        line-height: 16.94px !important;
        color: #3A3A3A !important;

        &:hover {
            color: var(--fire-red) !important;
        }
    }
}
`

export const DateMeta = styled.p`
margin: 0.5rem 0 0 !important;
font-family: var(--secondary-font);
font-weight: 400;
font-style: normal;
font-size: 14px;
line-height: 16.94px;
color: #3A3A3A;

& > span {
    font-weight: 700;
}
`